import { Label, SelectField } from "@otta/design";
import { RoleType } from "@toolbox/schema";

const OPTIONS = [
  { label: "All roles", value: null },
  { label: "Candidates", value: RoleType.Candidate },
  { label: "Recruiters", value: RoleType.CompanyRecruiter },
  { label: "Admins", value: RoleType.Admin },
  { label: "Operators", value: RoleType.InternalOperator },
];

interface RoleTypeProps {
  value: RoleType | null;
  onChange(v: RoleType | null): void;
}

export function RoleSelect({ value, onChange }: RoleTypeProps) {
  return (
    <div style={{ minWidth: 200 }}>
      <Label>Role</Label>
      <SelectField
        options={OPTIONS}
        value={OPTIONS.find(o => o.value === value)}
        onChange={v => onChange(v?.value ?? null)}
      />
    </div>
  );
}
