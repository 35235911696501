export type OttaIconsId =
  | "all-matches"
  | "apply"
  | "arrow-left"
  | "arrow-right-arrow-left"
  | "arrow-right"
  | "article"
  | "ban"
  | "bin"
  | "broadcast"
  | "calendar-clock"
  | "certified"
  | "chart-up"
  | "checkmark-double"
  | "checkmark"
  | "chevron-down-filled"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-up-filled"
  | "chevron-up"
  | "circle-close"
  | "circle-exclamation-filled"
  | "circle-exclamation"
  | "circle-info"
  | "circle-plus"
  | "circle-question"
  | "circle-up"
  | "circle-user-filled"
  | "circle-user"
  | "clock"
  | "close"
  | "company"
  | "delete"
  | "display"
  | "document"
  | "download"
  | "dribbble"
  | "edit"
  | "education"
  | "expand"
  | "external"
  | "facebook"
  | "female"
  | "filter-icon"
  | "flag"
  | "github"
  | "grip-lines"
  | "hand-wave"
  | "headquarters"
  | "heart-filled"
  | "heart"
  | "house"
  | "industry"
  | "instagram"
  | "interviewing"
  | "jobs"
  | "link"
  | "linkedin"
  | "location"
  | "locked"
  | "merge"
  | "message-check"
  | "message"
  | "mobile"
  | "money-bill"
  | "new-to-otta"
  | "notification-off"
  | "notification-on"
  | "phone"
  | "play-pause"
  | "plus"
  | "remote"
  | "responds-quickly"
  | "response-rate"
  | "rocketlist"
  | "salary-off"
  | "salary"
  | "search"
  | "send"
  | "settings"
  | "share"
  | "shuffle"
  | "snowflake"
  | "sourcing"
  | "star-filled"
  | "star"
  | "steps"
  | "tag"
  | "tech-for-good"
  | "technology"
  | "template"
  | "triangle-exclamation"
  | "twitter"
  | "user"
  | "users"
  | "visible-off"
  | "visible-on";

export type OttaIconsKey =
  | "AllMatches"
  | "Apply"
  | "ArrowLeft"
  | "ArrowRightArrowLeft"
  | "ArrowRight"
  | "Article"
  | "Ban"
  | "Bin"
  | "Broadcast"
  | "CalendarClock"
  | "Certified"
  | "ChartUp"
  | "CheckmarkDouble"
  | "Checkmark"
  | "ChevronDownFilled"
  | "ChevronDown"
  | "ChevronLeft"
  | "ChevronRight"
  | "ChevronUpFilled"
  | "ChevronUp"
  | "CircleClose"
  | "CircleExclamationFilled"
  | "CircleExclamation"
  | "CircleInfo"
  | "CirclePlus"
  | "CircleQuestion"
  | "CircleUp"
  | "CircleUserFilled"
  | "CircleUser"
  | "Clock"
  | "Close"
  | "Company"
  | "Delete"
  | "Display"
  | "Document"
  | "Download"
  | "Dribbble"
  | "Edit"
  | "Education"
  | "Expand"
  | "External"
  | "Facebook"
  | "Female"
  | "FilterIcon"
  | "Flag"
  | "Github"
  | "GripLines"
  | "HandWave"
  | "Headquarters"
  | "HeartFilled"
  | "Heart"
  | "House"
  | "Industry"
  | "Instagram"
  | "Interviewing"
  | "Jobs"
  | "Link"
  | "Linkedin"
  | "Location"
  | "Locked"
  | "Merge"
  | "MessageCheck"
  | "Message"
  | "Mobile"
  | "MoneyBill"
  | "NewToOtta"
  | "NotificationOff"
  | "NotificationOn"
  | "Phone"
  | "PlayPause"
  | "Plus"
  | "Remote"
  | "RespondsQuickly"
  | "ResponseRate"
  | "Rocketlist"
  | "SalaryOff"
  | "Salary"
  | "Search"
  | "Send"
  | "Settings"
  | "Share"
  | "Shuffle"
  | "Snowflake"
  | "Sourcing"
  | "StarFilled"
  | "Star"
  | "Steps"
  | "Tag"
  | "TechForGood"
  | "Technology"
  | "Template"
  | "TriangleExclamation"
  | "Twitter"
  | "User"
  | "Users"
  | "VisibleOff"
  | "VisibleOn";

export enum OttaIcons {
  AllMatches = "all-matches",
  Apply = "apply",
  ArrowLeft = "arrow-left",
  ArrowRightArrowLeft = "arrow-right-arrow-left",
  ArrowRight = "arrow-right",
  Article = "article",
  Ban = "ban",
  Bin = "bin",
  Broadcast = "broadcast",
  CalendarClock = "calendar-clock",
  Certified = "certified",
  ChartUp = "chart-up",
  CheckmarkDouble = "checkmark-double",
  Checkmark = "checkmark",
  ChevronDownFilled = "chevron-down-filled",
  ChevronDown = "chevron-down",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  ChevronUpFilled = "chevron-up-filled",
  ChevronUp = "chevron-up",
  CircleClose = "circle-close",
  CircleExclamationFilled = "circle-exclamation-filled",
  CircleExclamation = "circle-exclamation",
  CircleInfo = "circle-info",
  CirclePlus = "circle-plus",
  CircleQuestion = "circle-question",
  CircleUp = "circle-up",
  CircleUserFilled = "circle-user-filled",
  CircleUser = "circle-user",
  Clock = "clock",
  Close = "close",
  Company = "company",
  Delete = "delete",
  Display = "display",
  Document = "document",
  Download = "download",
  Dribbble = "dribbble",
  Edit = "edit",
  Education = "education",
  Expand = "expand",
  External = "external",
  Facebook = "facebook",
  Female = "female",
  FilterIcon = "filter-icon",
  Flag = "flag",
  Github = "github",
  GripLines = "grip-lines",
  HandWave = "hand-wave",
  Headquarters = "headquarters",
  HeartFilled = "heart-filled",
  Heart = "heart",
  House = "house",
  Industry = "industry",
  Instagram = "instagram",
  Interviewing = "interviewing",
  Jobs = "jobs",
  Link = "link",
  Linkedin = "linkedin",
  Location = "location",
  Locked = "locked",
  Merge = "merge",
  MessageCheck = "message-check",
  Message = "message",
  Mobile = "mobile",
  MoneyBill = "money-bill",
  NewToOtta = "new-to-otta",
  NotificationOff = "notification-off",
  NotificationOn = "notification-on",
  Phone = "phone",
  PlayPause = "play-pause",
  Plus = "plus",
  Remote = "remote",
  RespondsQuickly = "responds-quickly",
  ResponseRate = "response-rate",
  Rocketlist = "rocketlist",
  SalaryOff = "salary-off",
  Salary = "salary",
  Search = "search",
  Send = "send",
  Settings = "settings",
  Share = "share",
  Shuffle = "shuffle",
  Snowflake = "snowflake",
  Sourcing = "sourcing",
  StarFilled = "star-filled",
  Star = "star",
  Steps = "steps",
  Tag = "tag",
  TechForGood = "tech-for-good",
  Technology = "technology",
  Template = "template",
  TriangleExclamation = "triangle-exclamation",
  Twitter = "twitter",
  User = "user",
  Users = "users",
  VisibleOff = "visible-off",
  VisibleOn = "visible-on",
}

export const OTTA_ICONS_CODEPOINTS: { [key in OttaIcons]: string } = {
  [OttaIcons.AllMatches]: "61697",
  [OttaIcons.Apply]: "61698",
  [OttaIcons.ArrowLeft]: "61699",
  [OttaIcons.ArrowRightArrowLeft]: "61700",
  [OttaIcons.ArrowRight]: "61701",
  [OttaIcons.Article]: "61702",
  [OttaIcons.Ban]: "61703",
  [OttaIcons.Bin]: "61704",
  [OttaIcons.Broadcast]: "61705",
  [OttaIcons.CalendarClock]: "61706",
  [OttaIcons.Certified]: "61707",
  [OttaIcons.ChartUp]: "61708",
  [OttaIcons.CheckmarkDouble]: "61709",
  [OttaIcons.Checkmark]: "61710",
  [OttaIcons.ChevronDownFilled]: "61711",
  [OttaIcons.ChevronDown]: "61712",
  [OttaIcons.ChevronLeft]: "61713",
  [OttaIcons.ChevronRight]: "61714",
  [OttaIcons.ChevronUpFilled]: "61715",
  [OttaIcons.ChevronUp]: "61716",
  [OttaIcons.CircleClose]: "61717",
  [OttaIcons.CircleExclamationFilled]: "61718",
  [OttaIcons.CircleExclamation]: "61719",
  [OttaIcons.CircleInfo]: "61720",
  [OttaIcons.CirclePlus]: "61721",
  [OttaIcons.CircleQuestion]: "61722",
  [OttaIcons.CircleUp]: "61723",
  [OttaIcons.CircleUserFilled]: "61724",
  [OttaIcons.CircleUser]: "61725",
  [OttaIcons.Clock]: "61726",
  [OttaIcons.Close]: "61727",
  [OttaIcons.Company]: "61728",
  [OttaIcons.Delete]: "61729",
  [OttaIcons.Display]: "61730",
  [OttaIcons.Document]: "61731",
  [OttaIcons.Download]: "61732",
  [OttaIcons.Dribbble]: "61733",
  [OttaIcons.Edit]: "61734",
  [OttaIcons.Education]: "61735",
  [OttaIcons.Expand]: "61736",
  [OttaIcons.External]: "61737",
  [OttaIcons.Facebook]: "61738",
  [OttaIcons.Female]: "61739",
  [OttaIcons.FilterIcon]: "61740",
  [OttaIcons.Flag]: "61741",
  [OttaIcons.Github]: "61742",
  [OttaIcons.GripLines]: "61743",
  [OttaIcons.HandWave]: "61744",
  [OttaIcons.Headquarters]: "61745",
  [OttaIcons.HeartFilled]: "61746",
  [OttaIcons.Heart]: "61747",
  [OttaIcons.House]: "61748",
  [OttaIcons.Industry]: "61749",
  [OttaIcons.Instagram]: "61750",
  [OttaIcons.Interviewing]: "61751",
  [OttaIcons.Jobs]: "61752",
  [OttaIcons.Link]: "61753",
  [OttaIcons.Linkedin]: "61754",
  [OttaIcons.Location]: "61755",
  [OttaIcons.Locked]: "61756",
  [OttaIcons.Merge]: "61757",
  [OttaIcons.MessageCheck]: "61758",
  [OttaIcons.Message]: "61759",
  [OttaIcons.Mobile]: "61760",
  [OttaIcons.MoneyBill]: "61761",
  [OttaIcons.NewToOtta]: "61762",
  [OttaIcons.NotificationOff]: "61763",
  [OttaIcons.NotificationOn]: "61764",
  [OttaIcons.Phone]: "61765",
  [OttaIcons.PlayPause]: "61766",
  [OttaIcons.Plus]: "61767",
  [OttaIcons.Remote]: "61768",
  [OttaIcons.RespondsQuickly]: "61769",
  [OttaIcons.ResponseRate]: "61770",
  [OttaIcons.Rocketlist]: "61771",
  [OttaIcons.SalaryOff]: "61772",
  [OttaIcons.Salary]: "61773",
  [OttaIcons.Search]: "61774",
  [OttaIcons.Send]: "61775",
  [OttaIcons.Settings]: "61776",
  [OttaIcons.Share]: "61777",
  [OttaIcons.Shuffle]: "61778",
  [OttaIcons.Snowflake]: "61779",
  [OttaIcons.Sourcing]: "61780",
  [OttaIcons.StarFilled]: "61781",
  [OttaIcons.Star]: "61782",
  [OttaIcons.Steps]: "61783",
  [OttaIcons.Tag]: "61784",
  [OttaIcons.TechForGood]: "61785",
  [OttaIcons.Technology]: "61786",
  [OttaIcons.Template]: "61787",
  [OttaIcons.TriangleExclamation]: "61788",
  [OttaIcons.Twitter]: "61789",
  [OttaIcons.User]: "61790",
  [OttaIcons.Users]: "61791",
  [OttaIcons.VisibleOff]: "61792",
  [OttaIcons.VisibleOn]: "61793",
};
