const palette = {
  white: {
    shade0: "#FFFFFF",
  },
  blue: {
    medium: "#545FEC",
  },
  green: {
    shade200: "#BADE94",
    shade400: "#9FC873",
    shade500: "#83AD57",
    shade600: "#5A8034",
  },
  grey: {
    shade100: "#EEEEEE",
    shade400: "#A5A5A5",
    shade1000: "#212121",
  },
  red: {
    shade500: "#FF6165",
    shade600: "#E1003A",
  },
};

export const theme = {
  palette,
  section: {
    background: {
      primary: palette.blue.medium,
      blue: palette.blue.medium,
      grey: palette.grey.shade1000,
      red: palette.red.shade500,
    },
  },
  text: {
    colour: palette.grey.shade1000,
    inverted: {
      colour: palette.white.shade0,
    },
  },
  headings: {
    margin: "24px 0",
    tablet: {
      maxWidth: "690px",
    },
    desktop: {
      maxWidth: "790px",
    },
  },
  logo: {
    inverted: {
      colour: palette.grey.shade1000,
    },
    colour: palette.white.shade0,
  },
  hr: {
    colour: palette.grey.shade1000,
  },
  button: {
    primary: {
      colour: palette.white.shade0,
      background: palette.green.shade600,
    },
    secondary: {
      colour: palette.green.shade500,
      background: palette.grey.shade1000,
    },
    tertiary: {
      colour: palette.blue.medium,
      background: palette.white.shade0,
    },
    danger: {
      colour: palette.white.shade0,
      background: palette.red.shade600,
    },
  },
  shadow: "rgba(33, 33, 33, 0.1) 0px 4px 20px",
  card: {
    background: "#ffffff",
    border: palette.grey.shade100,
    borderRadius: "4px",
    positive: {
      background: palette.green.shade400,
      borderRadius: "4px 0 0 4px",
    },
    negative: {
      background: palette.grey.shade100,
      borderRadius: "0 4px 4px 0",
    },
  },
  footer: {
    background: palette.grey.shade1000,
  },
  input: {
    borderColour: palette.grey.shade400,
    valid: {
      borderColour: palette.green.shade500,
    },
    focus: {
      borderColour: palette.grey.shade1000,
    },
    error: {
      colour: palette.red.shade600,
      borderColour: palette.red.shade500,
    },
  },
};
