import styled from "@xstyled/styled-components";

import {} from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { Location } from "@toolbox/schema";

export const Badge = styled.span<{ $colour: string }>`
  font-size: ${modularScale(-1)};
  background-color: ${props => props.$colour};
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 2px;
`;

export const locationLabel = (location: Location): string | undefined => {
  switch (location) {
    case Location.Amsterdam:
      return "Amsterdam";
    case Location.AustinUs:
      return "Austin";
    case Location.Barcelona:
      return "Barcelona";
    case Location.Berlin:
      return "Berlin";
    case Location.BostonUs:
      return "Boston";
    case Location.ChicagoUs:
      return "Chicago";
    case Location.DenverUs:
      return "Denver";
    case Location.Dublin:
      return "Dublin";
    case Location.London:
      return "London";
    case Location.LosAngelesUs:
      return "LA";
    case Location.MiamiUs:
      return "Miami";
    case Location.NewYorkUs:
      return "New York";
    case Location.Paris:
      return "Paris";
    case Location.SanFranciscoBayAreaUs:
      return "San Fran";
    case Location.Toronto:
      return "Toronto";
    case Location.Vancouver:
      return "Vancouver";
    case Location.RemoteCanada:
      return "Remote (Canada)";
    case Location.RemoteFrance:
      return "Remote (France)";
    case Location.RemoteGermany:
      return "Remote (Germany)";
    case Location.RemoteIreland:
      return "Remote (Ireland)";
    case Location.RemoteNetherlands:
      return "Remote (Netherlands)";
    case Location.RemoteSpain:
      return "Remote (Spain)";
    case Location.RemoteUk:
      return "Remote (UK)";
    case Location.RemoteUs:
      return "Remote (US)";
    case Location.RemoteEurope:
      return "Remote (EU)";
    default:
      return undefined;
  }
};
