const bulletRegex = /[·•●]/g;

const stringTransforms = (strings: string[]): string[] =>
  strings
    .map(s => s.trim())
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .map(s => s.replace(/^-/, ""))
    .map(s => s.replace(/\.$/, ""))
    .map(s => s.replace(/;$/, ""))
    .map(s => s.trim()); // trim again, since sentence-initial removals can expose space.

type UnflattenedArray<T> = T | T[];

function flatten<T>(arr: UnflattenedArray<T>[]): T[] {
  return arr.reduce<T[]>((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

export const splitBullets = (pastedData: string): string[] => {
  const match = pastedData.match(bulletRegex);

  if (match) {
    const bulletChar = match[0];
    return stringTransforms(
      pastedData.split(bulletChar).filter(item => item.trim().length > 0)
    );
  } else if (pastedData.includes("\n")) {
    const paragraphs = pastedData
      .split("\n")
      .filter(item => item.trim().length > 0);
    return flatten(paragraphs.map(splitBullets));
  }
  return stringTransforms([pastedData]);
};
