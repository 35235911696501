export function PlusIcon(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      {...props}
    >
      <path
        d="M10.0833 5.49978C10.0833 5.79285 9.84755 6.02863 9.55448 6.02863H6.02884V9.55427C6.02884 9.84624 5.79196 10.0833 5.49999 10.0833C5.20802 10.0833 4.97114 9.84734 4.97114 9.55427V6.02863H1.4455C1.15354 6.02863 0.916656 5.79197 0.916656 5.5C0.916656 5.20892 1.15354 4.97094 1.4455 4.97094H4.97114V1.4453C4.97114 1.15333 5.20802 0.916672 5.49999 0.916672C5.79196 0.916672 6.02884 1.15333 6.02884 1.4453V4.97094H9.55448C9.84755 4.97094 10.0833 5.20892 10.0833 5.49978Z"
        fill="black"
      />
    </svg>
  );
}
