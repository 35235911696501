import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "@xstyled/styled-components";

import { Overline, Text } from "@otta/design";
import { ProductBubbles } from "@toolbox/components/ProductBubbles";

const JobWrapper = styled.div`
  margin: 12px 12px 12px 0;
`;

const Sidebar = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.grey.shade1000};
  overflow-y: auto;
  width: 15%;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  ul {
    list-style-type: none;
  }
  ul > li {
    display: inline-block;
    margin: 0 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  li:hover {
    text-decoration: underline;
  }
`;

interface IPaginatedJobProps {
  title: string;
  company: {
    name: string;
    isPro: boolean;
    isCurrentPayingCustomer: boolean;
    jobSlotData: {
      free: number;
      paid: number;
    } | null;
  };
  subtitle?: string | null;
  savePercentage?: number;
}

const savePercentColor = (percent: number) => {
  if (percent < 10) {
    return "red";
  }

  return undefined;
};

export function Job({
  title,
  company,
  subtitle,
  savePercentage,
}: IPaginatedJobProps): React.ReactElement {
  return (
    <JobWrapper data-testid="job">
      <Text as="h3" data-testid="approved-job-link" bold size={1}>
        {title}
      </Text>
      <Text as="h4" bold size={-1}>
        {subtitle}
        {savePercentage != null && (
          <>
            {" "}
            <span style={{ color: savePercentColor(savePercentage) }}>
              {savePercentage}%
            </span>
          </>
        )}
      </Text>
      <Overline as="h6" size={-1}>
        {company.name}
        <ProductBubbles company={company} />
      </Overline>
    </JobWrapper>
  );
}

interface IPaginatedSidebarProps {
  total: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  children: React.ReactNode;
}

export function PaginatedSidebar({
  total,
  totalPages,
  onPageChange,
  children,
}: IPaginatedSidebarProps): React.ReactElement {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  return (
    <Sidebar>
      <Overline align="center" size={-1}>
        Total: {total}
      </Overline>
      {children}
      {totalPages > 1 && (
        <PaginationWrapper>
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            pageCount={totalPages}
            forcePage={currentPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={0}
            onPageChange={handlePageClick}
          />
        </PaginationWrapper>
      )}
    </Sidebar>
  );
}
