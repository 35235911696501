import { validSalary } from "@otta/shared-components";

export function numberToCommas(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function commasToNumber(str: string) {
  return str ? parseInt(str.replace(/,/g, "")) : 0;
}

export function validOTESalary({
  oteMin,
  oteMax,
  baseMin,
  baseMax,
}: {
  oteMin: number | null;
  oteMax: number | null;
  baseMin: number | null;
  baseMax: number | null;
}): string | undefined {
  const salaryCheck = validSalary(oteMin, oteMax);
  if (salaryCheck === undefined) {
    if (oteMin && oteMax) {
      if (baseMin && baseMin >= oteMin) {
        return "OTE minimum must be greater than the minimum base salary value";
      }
      if (baseMax && baseMax >= oteMax) {
        return "OTE maximum must be greater than the maximum base salary value";
      }
    }
  }
  return salaryCheck;
}
