import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useApolloClient } from "@apollo/client";
import { parse as parseCookie } from "cookie";

import { Spinner } from "@otta/shared-components";

export default function Logout(): React.ReactElement {
  const csrf = useMemo(() => {
    const cookies = parseCookie(document.cookie);
    return cookies[import.meta.env.VITE_CSRF_COOKIE];
  }, []);

  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_HOST}/auth/logout`, {
      method: "POST",
      credentials: "include",
      headers: { ...(csrf ? { "X-CSRF-Token": csrf } : {}) },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        return client.resetStore();
      })
      .then(() => {
        navigate("/login", { replace: true });
      })
      .catch(error => {
        Sentry.captureException(error);
        navigate("/", { replace: true });
      });
  }, [client, navigate, csrf]);

  return <Spinner />;
}
