import styled from "@xstyled/styled-components";
import { useCallback } from "react";
import { TypedDocumentNode, useMutation } from "@apollo/client";

import { FieldWrapper } from "./FieldWrapper";

import { Input, Label } from "@toolbox/components/Input";
import { Delete } from "@toolbox/components/Icons/Delete";

const ValuesWrapper = styled.div`
  padding-top: 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const Value = styled.div`
  padding: 4px;
  position: relative;
`;

const DeleteButton = styled(Delete)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 8px;
  height: 8px;
  cursor: pointer;
  fill: #fff;
`;

interface IMultiUploadFieldProps<V> {
  label: string;
  values: V[];
  uploadMutation: TypedDocumentNode<unknown, { id: string; file: Blob }>;
  deleteMutation: TypedDocumentNode<unknown, { id: string }>;
  id: string;
  accept: string[];
  display: (v: V) => React.ReactNode;
}

export function MultiUploadField<V extends { id: string }>({
  id,
  label,
  values,
  accept,
  display,
  uploadMutation,
  deleteMutation,
}: IMultiUploadFieldProps<V>): React.ReactElement {
  const [upload] = useMutation(uploadMutation, {
    onError(e) {
      window.alert(e);
    },
  });

  const [deleteUpload] = useMutation(deleteMutation);

  const handleUpload = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async e => {
      if (e.target.files && e.target.files.length === 1) {
        const file = e.target.files[0];
        await upload({ variables: { file, id } });
      }
    },
    [id, upload]
  );

  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <ValuesWrapper>
        {values.map(value => (
          <Value key={value.id}>
            <DeleteButton
              onClick={() => deleteUpload({ variables: { id: value.id } })}
            />
            {display(value)}
          </Value>
        ))}
      </ValuesWrapper>
      <Input type="file" accept={accept.join(", ")} onChange={handleUpload} />
    </FieldWrapper>
  );
}
