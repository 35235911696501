import styled from "@xstyled/styled-components";

import {
  CompanyFoundersDocument,
  CreateCompanyFounderDocument,
  DeleteCompanyFounderDocument,
  UpdateCompanyFounderDocument,
} from "@toolbox/schema";
import { MultiplePeopleField } from "@toolbox/components/Field/MultiplePeopleField";

export const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  input:first-child {
    margin-right: 8px;
  }
`;

export function Founders({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  return (
    <MultiplePeopleField
      heading="Leadership"
      fieldName="founders"
      parentName="company"
      CREATE_MUTATION={CreateCompanyFounderDocument}
      UPDATE_MUTATION={UpdateCompanyFounderDocument}
      DELETE_MUTATION={DeleteCompanyFounderDocument}
      QUERY={CompanyFoundersDocument}
      parentId={companyId}
      showTitle
      showGender
    />
  );
}
