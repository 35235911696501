import styled, { keyframes } from "@xstyled/styled-components";

const dotOneMove = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(16px, 12px) scale(.45)}
  60% {transform: translate(80px, 60px) scale(.45)}
  80% {transform: translate(80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const dotTwoMove = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(-16px, 12px) scale(.45)}
  60% {transform: translate(-80px, 60px) scale(.45)}
  80% {transform: translate(-80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const dotThreeMove = keyframes`
  20% {transform: scale(1)}
  45% {transform: translateY(-18px) scale(.45)}
  60% {transform: translateY(-90px) scale(.45)}
  80% {transform: translateY(-90px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const rotateMove = keyframes`
  55% {transform: translate(-50%, -50%) rotate(0deg)}
  80% {transform: translate(-50%, -50%) rotate(360deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
`;

const index = keyframes`
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
`;

const Container = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url("#goosvg");
  animation: ${rotateMove} 2s ease-in-out infinite;
`;

const Dot = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DotOne = styled(Dot)`
  background-color: #f696c8;
  animation: ${dotOneMove} 2s ease infinite, ${index} 6s ease infinite;
`;
const DotTwo = styled(Dot)`
  background-color: #ffcd00;
  animation: ${dotTwoMove} 2s ease infinite, ${index} 6s -4s ease infinite;
`;
const DotThree = styled(Dot)`
  background-color: #55c3e9;
  animation: ${dotThreeMove} 2s ease infinite, ${index} 6s -2s ease infinite;
`;

export const Spinner = (): JSX.Element => (
  <div style={{ position: "relative" }}>
    <Container>
      <DotOne />
      <DotTwo />
      <DotThree />
    </Container>

    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goosvg">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
          />
        </filter>
      </defs>
    </svg>
  </div>
);
