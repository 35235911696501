import styled from "@xstyled/styled-components";
import { Field, Form } from "react-final-form";

import { Button, InputField, SelectField } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@toolbox/apollo";
import {
  AcquisitionChannel,
  AllAcquisitionChannelsDocument,
} from "@toolbox/schema";
const FiltersContainer = styled.div`
  display: flex;
`;

const FieldContainer = styled.div`
  margin-right: lg;
`;

const SelectFieldContainer = styled(FieldContainer)`
  min-width: ${pxToRem(200)};
`;

const SearchFieldsContainer = styled.div`
  display: flex;
`;

export const Filters = ({
  handleGroupChange,
  handleIsProChange,
  handleSearchFieldsChange,
  handleClear,
}: {
  handleGroupChange: (
    option: { value: string | undefined; label: string } | null
  ) => void;
  handleIsProChange: (
    option: { value: boolean | undefined; label: string } | null
  ) => void;
  handleSearchFieldsChange: ({
    companyName,
    scrapedLocation,
  }: {
    companyName?: string;
    scrapedLocation?: string;
  }) => void;
  handleClear: () => void;
}) => {
  const { data, loading } = useQuery(AllAcquisitionChannelsDocument);

  const acquisitionChannels = data?.companyAcquisitionChannels ?? [];

  const optionsFromAcquisitionChannels = (
    acquisitionChannels: AcquisitionChannel[]
  ) =>
    acquisitionChannels.map(acquisitionChannel => ({
      label: acquisitionChannel.value,
      value: acquisitionChannel.id,
    }));

  return (
    <FiltersContainer>
      <SelectFieldContainer>
        <SelectField
          instanceId="pre-approval-pro"
          aria-label="company type"
          defaultValue={{
            label: "Paid and basic",
            value: undefined,
          }}
          options={[
            { label: "Paid and basic", value: undefined },
            { label: "Paid", value: true },
            { label: "Basic", value: false },
          ]}
          onChange={handleIsProChange}
        />
      </SelectFieldContainer>
      <SelectFieldContainer>
        <SelectField
          aria-label="acquisition channel"
          isLoading={loading}
          instanceId="pre-approval-acquisition-channel"
          defaultValue={{
            label: "All acquisition channels",
            value: undefined,
          }}
          options={[
            { label: "All acquisition channels", value: undefined },
            ...optionsFromAcquisitionChannels(acquisitionChannels),
          ]}
          onChange={handleGroupChange}
        />
      </SelectFieldContainer>
      <Form onSubmit={handleSearchFieldsChange}>
        {({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <SearchFieldsContainer>
              <FieldContainer>
                <Field name="companyName">
                  {({ input }) => (
                    <InputField
                      {...input}
                      type="text"
                      autoFocus
                      placeholder="Company name"
                      data-testid="company-name-search"
                    />
                  )}
                </Field>
              </FieldContainer>
              <FieldContainer>
                <Field name="scrapedLocation">
                  {({ input }) => (
                    <InputField
                      {...input}
                      type="text"
                      autoFocus
                      placeholder="Scraped location"
                      data-testid="scraped-location-search"
                    />
                  )}
                </Field>
              </FieldContainer>

              <FieldContainer>
                <Button level="secondary" type="submit">
                  Search
                </Button>
              </FieldContainer>

              {(values?.companyName || values?.scrapedLocation) && (
                <Button
                  level="secondary"
                  type="button"
                  onClick={() => {
                    form.reset();
                    handleClear();
                  }}
                >
                  Clear
                </Button>
              )}
            </SearchFieldsContainer>
          </form>
        )}
      </Form>
    </FiltersContainer>
  );
};
