import styled, { css } from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

export interface VerticalSpacingProps {
  size?: number;
}

export const verticalSpacing = css<VerticalSpacingProps>`
  & > * + * {
    margin-top: ${({ size }) => modularScale(size)};
  }
`;

/**
 * This component spaces out its `children` evenly.
 *
 * ## Extending
 *
 * Unlike `Spacing`, `VerticalSpacing` can easily be extended using styled-components.
 *
 * ```ts
 *  const MyCustomContainer = styled(VerticalSpacing)`
 *    background-color: red;
 *  `;
 * ```
 *
 * ## CSS
 *
 * `verticalSpacing` CSS also exist which can be embedded into your components.
 *
 * ```ts
 *  export const Container = styled.div<VerticalSpacingProps>`
 *    background-color: red;
 *    ${verticalSpacing}
 *  `;
 * ```
 */

export const VerticalSpacing = styled.div<VerticalSpacingProps>`
  ${verticalSpacing}
`;
