import { Link as RouterLink } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { isExternalUrl } from "@otta/shared-components";

interface StyledProps {
  $underline?: boolean;
}

const A = styled.a<StyledProps>`
  color: black;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: ${({ $underline }) => ($underline ? "underline" : "none")};
  }
`;

const StyledRouterLink = styled(RouterLink)<StyledProps>`
  color: black;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: ${({ $underline }) => ($underline ? "underline" : "none")};
  }
`;

interface ILinkProps {
  to: string;
  children: React.ReactNode;
  underline?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function Link({
  to,
  children,
  underline = true,
  ...props
}: ILinkProps): React.ReactElement {
  if (isExternalUrl(to)) {
    return (
      <A href={to} $underline={underline} target="_blank" {...props}>
        {children}
      </A>
    );
  }
  return (
    <StyledRouterLink to={to} $underline={underline} {...props}>
      {children}
    </StyledRouterLink>
  );
}
