import { Routes, Route } from "react-router-dom";
import { createGlobalStyle } from "@xstyled/styled-components";

import { Home } from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Salaries from "./pages/Salaries";
import Surveys from "./pages/Surveys";
import Companies from "./pages/Companies";
import Users from "./pages/Users";
import Jobs from "./pages/Jobs";
import Misc from "./pages/Misc";
import Scraper from "./pages/Scraper";
import { CurrentRoleType } from "./utils/user";
import { SignedOutRoute } from "./utils/routing/SignedOutRoute";
import { ScrollToTop } from "./utils/routing/ScrollToTop";
import { LoggedInRoute } from "./utils/routing/LoggedInRoute";
import { Redirect } from "./router";
import { Permission } from "./schema";
import { Offers } from "./pages/Offers";
import { CompanyJobOffers } from "./pages/Offers/CompanyJobOffers";
import { AccessCheck } from "./pages/AccessCheck";
import { AddToStarterBulk } from "./pages/Starter/AddToStarterBulk";
import { AddCompanyToStarter } from "./pages/Starter/AddToStarterBulk/AddCompanyToStarter";

import { GlobalStyles } from "@otta/design";

const ToolboxStyles = createGlobalStyle`
  * {
    font-family: inherit;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

export function App(): React.ReactElement {
  return (
    <>
      <GlobalStyles />
      <ToolboxStyles />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <LoggedInRoute
              allowedRoles={[
                CurrentRoleType.Admin,
                CurrentRoleType.ExternalResearcher,
                CurrentRoleType.InternalOperator,
              ]}
            >
              <Home />
            </LoggedInRoute>
          }
        >
          <Route
            path="salaries/*"
            element={
              <LoggedInRoute
                allowedRoles={[CurrentRoleType.Admin]}
                allowedPermissions={[Permission.ManageJobSalaries]}
              >
                <Salaries />
              </LoggedInRoute>
            }
          />
          <Route
            path="endorsements/*"
            element={
              <LoggedInRoute
                allowedRoles={[CurrentRoleType.Admin]}
                allowedPermissions={[Permission.ManageCompanyEndorsements]}
              >
                <Surveys />
              </LoggedInRoute>
            }
          />
          <Route
            path="companies/*"
            element={
              <LoggedInRoute
                allowedRoles={[
                  CurrentRoleType.Admin,
                  CurrentRoleType.ExternalResearcher,
                  CurrentRoleType.InternalOperator,
                ]}
              >
                <Companies />
              </LoggedInRoute>
            }
          />
          <Route
            path="users/*"
            element={
              <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                <Users />
              </LoggedInRoute>
            }
          />
          <Route path="offers">
            <Route
              index
              element={
                <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                  <Offers />
                </LoggedInRoute>
              }
            />
            <Route
              path=":companyId"
              element={
                <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                  <CompanyJobOffers />
                </LoggedInRoute>
              }
            />
          </Route>
          <Route path="access-check">
            <Route
              index
              element={
                <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                  <AccessCheck />
                </LoggedInRoute>
              }
            />
          </Route>
          <Route path="starter">
            <Route
              path=""
              element={
                <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                  <AddToStarterBulk />
                </LoggedInRoute>
              }
            >
              <Route path="add" element={<AddCompanyToStarter />} />
            </Route>
          </Route>
          <Route
            path="jobs/*"
            element={
              <LoggedInRoute
                allowedRoles={[
                  CurrentRoleType.Admin,
                  CurrentRoleType.InternalOperator,
                ]}
              >
                <Jobs />
              </LoggedInRoute>
            }
          />
          <Route
            path="misc"
            element={
              <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                <Misc />
              </LoggedInRoute>
            }
          />
          <Route
            path="scraper"
            element={
              <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                <Scraper />
              </LoggedInRoute>
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <SignedOutRoute>
              <Login />
            </SignedOutRoute>
          }
        />
        <Route
          path="logout"
          element={
            <LoggedInRoute>
              <Logout />
            </LoggedInRoute>
          }
        />
        <Route path="*" element={<Redirect to="/" />} />
      </Routes>
    </>
  );
}
