export function isExternalUrl(url: string): boolean {
  if (
    url &&
    (url.startsWith("http") ||
      url.startsWith("mailto:") ||
      // eslint-disable-next-line
      url.startsWith("javascript:") ||
      url.startsWith("tel:"))
  ) {
    return true;
  }
  return false;
}

export const removeAfterLastSlash = (s: string): string =>
  s.substr(0, s.lastIndexOf("/"));
