import styled from "@xstyled/styled-components";

export const P = styled.p<{ inverted?: boolean; small?: boolean }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.text.inverted.colour : theme.text.colour};
  line-height: 1.8;
  max-width: 540px;
  margin: 16px auto;
  ${({ small }) => (small ? "font-size: 12px" : null)};
  text-align: center;
`;

export const B = styled.span`
  font-weight: 600;
`;
