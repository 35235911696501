import { NumericFormat } from "react-number-format";
import styled from "@xstyled/styled-components";

import { SubmittedSalarySelect } from "./SubmittedSalarySelect";
import { ApproveButtons } from "./ApproveButtons";

import { ErrorText } from "@otta/design";
import { Loading, Query } from "@otta/shared-components";
import { currencySymbol } from "@toolbox/utils/currency";
import { useQuery } from "@toolbox/apollo";
import {
  CompanyByNameDocument,
  SalaryStatisticsDocument,
  SubmittedSalariesDocument,
  SubmittedSalaries as SubmittedSalariesTypes,
  ExperienceLevel,
} from "@toolbox/schema";
import { B } from "@toolbox/components/Text";
import { Card } from "@toolbox/components/Card";

const SalaryWrapper = styled.div`
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  > div {
    margin: 8px 0;
  }
`;

const experienceLevels = {
  [ExperienceLevel.Internship]: "Internship",
  [ExperienceLevel.EntryLevel]: "Entry level",
  [ExperienceLevel.OneToTwoYears]: "1-2 years",
  [ExperienceLevel.ThreeToFourYears]: "3-4 years",
  [ExperienceLevel.FiveToEightYears]: "5-8 years",
  [ExperienceLevel.NinePlusYears]: "9+ years",
} as const;

const yearMapping = {
  [ExperienceLevel.Internship]: ["0", "0"],
  [ExperienceLevel.EntryLevel]: ["0", "0"],
  [ExperienceLevel.OneToTwoYears]: ["1", "2"],
  [ExperienceLevel.ThreeToFourYears]: ["3", "4"],
  [ExperienceLevel.FiveToEightYears]: ["5", "8"],
  [ExperienceLevel.NinePlusYears]: ["9", "10"],
} as const;

function Salary({
  id,
  currentJobSalary,
  currentSubFunction,
  currentExperienceLevel,
  currentCompany,
  currentJobTitle,
  location,
}: SubmittedSalariesTypes.SubmittedSalaries): React.ReactElement {
  const currentSubFunctionId = currentSubFunction?.id;
  return (
    <Card data-testid="salary">
      <SalaryWrapper>
        <ContentWrapper>
          <div>
            <SubmittedSalarySelect
              fieldValue={currentSubFunctionId}
              fieldName="currentSubFunctionId"
              id={id}
              options={null}
            />
            <SubmittedSalarySelect
              fieldValue={currentExperienceLevel}
              fieldName="currentExperienceLevel"
              id={id}
              options={Object.entries(experienceLevels).map(
                ([value, label]) => ({
                  value,
                  label,
                })
              )}
            />
            {currentSubFunctionId && location && (
              <Query
                query={SalaryStatisticsDocument}
                variables={{
                  subFunctionId: currentSubFunctionId,
                  location: location,
                }}
              >
                {({ data }) => {
                  const [lower, upper] = currentExperienceLevel
                    ? yearMapping[currentExperienceLevel]
                    : [null, null];
                  const results =
                    data?.salaryStatistics?.averageSalary?.datapoints ?? [];
                  const lowerSalary =
                    results.find(({ label }) => label === lower)?.value ??
                    undefined;
                  const upperSalary =
                    results.find(({ label }) => label === upper)?.value ??
                    undefined;
                  return (
                    <span>
                      Median range in the region:{" "}
                      <NumericFormat
                        value={lowerSalary}
                        decimalScale={2}
                        thousandSeparator=","
                        allowNegative={false}
                        displayType="text"
                      />{" "}
                      <NumericFormat
                        value={upperSalary}
                        decimalScale={2}
                        thousandSeparator=","
                        prefix="- "
                        allowNegative={false}
                        displayType="text"
                      />
                      <span> ({location})</span>
                    </span>
                  );
                }}
              </Query>
            )}
          </div>
          <B>
            <NumericFormat
              value={currentJobSalary?.amount}
              decimalScale={2}
              thousandSeparator=","
              prefix={currencySymbol(currentJobSalary?.currency ?? null)}
              allowNegative={false}
              displayType="text"
            />
          </B>
          {currentJobTitle && currentCompany && (
            <div>
              {currentJobTitle} - {currentCompany}
              <Query
                query={CompanyByNameDocument}
                variables={{ name: currentCompany }}
              >
                {({ data }) => (data?.companyByName ? <B> (on Otta)</B> : null)}
              </Query>
            </div>
          )}
        </ContentWrapper>
        <div>
          <ApproveButtons id={id} />
        </div>
      </SalaryWrapper>
    </Card>
  );
}

export function SubmittedSalaries(): React.ReactElement {
  const { data, loading, error } = useQuery(SubmittedSalariesDocument, {
    variables: {
      limit: 50,
      offset: 0,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorText>Something went wrong!</ErrorText>;
  }

  return (
    <div>
      <hr />
      {(data?.submittedSalaries ?? []).map(salary => (
        <Salary key={salary.id} {...salary} />
      ))}
    </div>
  );
}
