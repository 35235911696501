import { createContext, useCallback, useContext } from "react";

type RedirectContext = {
  statusCode: 301 | 302;
  location: string;
};

export type StatusContext = RedirectContext | { statusCode?: number };

interface IContext {
  ssr: boolean;
  redirect(to: string, status?: number): null;
}

const Context = createContext<IContext>({
  ssr: false,
  redirect() {
    return null;
  },
});

export function StatusProvider({
  ssr = false,
  context = {},
  children,
}: {
  ssr?: boolean;
  context?: StatusContext;
  children: React.ReactNode;
}) {
  const redirect = useCallback(
    (to: string, status = 302) => {
      context.statusCode = status;
      (context as RedirectContext).location = to;

      return null;
    },
    [context]
  );

  return (
    <Context.Provider value={{ ssr, redirect }}>{children}</Context.Provider>
  );
}

export function useStatus() {
  return useContext(Context);
}
