import { useMemo } from "react";
import { TypedDocumentNode } from "@apollo/client";

import { PasteableMultiSelectField } from "./PasteableMultiSelectField";

import { useQuery } from "@toolbox/apollo";

interface Option {
  id: string;
  value?: string | null;
  name?: string | null;
}

interface IMultiSelectFieldProps {
  label?: string;
  disabled?: boolean;
  fieldName: string;
  optionsQuery: TypedDocumentNode<
    { [key in string]: Option[] },
    Record<string, never>
  >;
  data: { id: string; value: string }[];
  handleUpdate: (field: { value: string }[]) => Promise<void>;
  doNotIncludeIds?: string[];
  "aria-label"?: string;
}

function toOption({ id, value, name }: Option) {
  return {
    label: value || name,
    value: id,
  };
}

function filterValidOption(v: {
  label: string | null | undefined;
  value: string;
}): v is { label: string; value: string } {
  return !!v.label;
}

export function MultiSelectField({
  label,
  disabled,
  optionsQuery,
  fieldName,
  data,
  handleUpdate,
  ...props
}: IMultiSelectFieldProps): React.ReactElement {
  const { data: optionsData, loading: optionsLoading } = useQuery(optionsQuery);

  const updateData = async (values: { id: string; value: string }[]) => {
    await handleUpdate(values.map(({ id, value }) => ({ id, value })));
  };
  const onPaste = async (values: { id: string; value: string }[]) => {
    await updateData([...data, ...values]);
  };

  const onCreate = async ({ id, value }: { id: string; value: string }) => {
    await updateData([...data, { id, value }]);
  };

  const onRemove = async (deleteId: string) => {
    await updateData(data.filter(({ id }: { id: string }) => id !== deleteId));
  };

  const onRemoveAll = async () => {
    await updateData([]);
  };

  const options = useMemo(
    () =>
      optionsData?.[fieldName].map(toOption).filter(filterValidOption) ?? [],
    [optionsData, fieldName]
  );

  const values = useMemo(
    () => data.map(toOption).filter(filterValidOption) ?? [],
    [data, fieldName]
  );

  return (
    <PasteableMultiSelectField
      label={label}
      disabled={disabled}
      options={options}
      onCreate={onCreate}
      onRemove={onRemove}
      onRemoveAll={onRemoveAll}
      onPaste={onPaste}
      loading={optionsLoading}
      values={values}
      {...props}
    />
  );
}
