import { useMemo } from "react";

import { Text } from "../Typography";
import { Spacing } from "../Spacing";

import { FilterOption } from "./FilterOption";

import { palette } from "@otta/design-tokens";

interface IFilterProps {
  title: string;
  options: Option[];
  selectedOptions: Selected;
  className?: string;
  onChange: (selectedOptions: Selected) => void;
  color?: string;
}

export interface Selected {
  [value: string]: string[];
}

export interface SubOption {
  value: string;
  label: string;
}
export interface Option extends SubOption {
  subOptions?: SubOption[];
}

/**
 * A filter component with a `selectedOptions` and `onChange` state. Check the console for the schema returned and options selected.
 *
 * ```ts
 *
 * import { Filter } from '@otta/design'
 *
 * ```
 */

export function Filter({
  title,
  options,
  selectedOptions,
  onChange,
}: IFilterProps): React.ReactElement {
  const clonedSelected = useMemo(
    () => ({ ...selectedOptions }),
    [selectedOptions]
  );

  const handleOptionSelect = (value: string) => {
    const option = options.find(i => value == i.value);
    if (!option) {
      return;
    }
    if (value in selectedOptions) {
      if ((option.subOptions ?? []).length === clonedSelected[value].length) {
        // this is the case where we are removing an option
        delete clonedSelected[value];
      } else {
        // this is the case where we are selecting all sub options
        clonedSelected[value] = (option.subOptions ?? []).map(
          ({ value }) => value
        );
      }
    } else {
      // this is the case where we are adding an option
      clonedSelected[value] = (option.subOptions ?? []).map(
        ({ value }) => value
      );
    }
    onChange(clonedSelected);
  };

  const handleSubOptionSelect = (value: string, parentValue: string) => {
    if (!selectedOptions[parentValue]) {
      // this is the case where we are adding a sub option to a parent option that doesn't exist
      clonedSelected[parentValue] = [value];
    } else if (
      selectedOptions[parentValue].length === 1 &&
      selectedOptions[parentValue].find(val => val === value)
    ) {
      // this is the case where we are removing the last sub option
      delete clonedSelected[parentValue];
    } else if (selectedOptions[parentValue].find(val => val === value)) {
      // this is the case where we are removing a sub option
      clonedSelected[parentValue] = clonedSelected[parentValue].filter(
        val => val !== value
      );
    } else {
      // this is the case where we are adding an option
      clonedSelected[parentValue] = [...clonedSelected[parentValue], value];
    }
    onChange(clonedSelected);
  };

  return (
    <Spacing>
      <Text bold color={palette.brand.black}>
        {title}
      </Text>

      {options.map(f => (
        <FilterOption
          key={f.value}
          selectedOptions={selectedOptions}
          parentValue={f.value}
          label={f.label}
          subOptions={f.subOptions}
          handleOptionSelect={handleOptionSelect}
          handleSubOptionSelect={handleSubOptionSelect}
        />
      ))}
    </Spacing>
  );
}
