import styled from "@xstyled/styled-components";

import { Spacing } from "../Spacing";
import { Text } from "../Typography";

import { modularScale, palette } from "@otta/design-tokens";

const AdviceWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
`;

const StyledFieldset = styled.fieldset`
  flex-direction: column;
  display: flex;
  border: none;
`;

const StyledLegend = styled.legend`
  font-size: ${modularScale(1)};
  font-weight: 600;
  float: left;
  display: flex;
  gap: 0.5rem;
`;

function Legend({
  children,
  advice,
  ...props
}: React.PropsWithChildren<
  {
    advice?: string | React.ReactNode;
  } & React.HTMLAttributes<HTMLLegendElement>
>): React.ReactElement {
  return advice ? (
    <Spacing size={0}>
      <AdviceWrapper>
        <StyledLegend {...props}>{children}</StyledLegend>
        <Text color={palette.grayscale.shade600}>{advice}</Text>
      </AdviceWrapper>
    </Spacing>
  ) : (
    <StyledLegend {...props}>{children}</StyledLegend>
  );
}

export function Fieldset({
  spacing = 2,
  legend,
  tag,
  advice,
  children,
  ...props
}: React.PropsWithChildren<
  {
    spacing?: number | null;
    legend?: React.ReactNode;
    tag?: React.ReactNode;
    advice?: React.ReactNode;
  } & React.HTMLAttributes<HTMLFieldSetElement>
>): React.ReactElement {
  return spacing !== null ? (
    <StyledFieldset {...props}>
      <Spacing size={spacing}>
        {legend && (
          <Legend advice={advice}>
            {legend}
            {tag}
          </Legend>
        )}
        {children}
      </Spacing>
    </StyledFieldset>
  ) : (
    <StyledFieldset {...props}>
      {legend && <Legend advice={advice}>{legend}</Legend>}
      {children}
    </StyledFieldset>
  );
}
