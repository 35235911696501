import { useCallback, useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, MultiValue } from "react-select";
import { useMutation } from "@apollo/client";

import { SelectField, Text, VerticalSpacing } from "@otta/design";
import { useUser, CurrentRoleType } from "@toolbox/utils/user";
import {
  AllAcquisitionChannelsDocument,
  UpdateCompanyDocument,
  CompanyDocument,
} from "@toolbox/schema";

interface IAcquisitionChannels {
  selectedAcquisitionChannels: {
    id: string;
    value: string;
  }[];
  allOptions: {
    label: string;
    value: string;
  }[];
  companyId: string;
}

export function AcquisitionChannels({
  selectedAcquisitionChannels,
  companyId,
  allOptions,
}: IAcquisitionChannels): React.ReactElement {
  const { role } = useUser();
  const currentSelectedIds = useMemo(
    () =>
      (selectedAcquisitionChannels ?? []).map(
        (option: { id: string; value: string }) => option.id
      ),
    [selectedAcquisitionChannels]
  );

  const selectedOptions = useMemo(
    () =>
      allOptions?.filter(option => currentSelectedIds?.includes(option.value)),
    [currentSelectedIds, allOptions]
  );

  const [updateCompany] = useMutation(UpdateCompanyDocument, {
    refetchQueries: [
      { query: CompanyDocument, variables: { id: companyId } },
      AllAcquisitionChannelsDocument,
    ],
  });

  const handleAdd = useCallback(
    (value: string) => {
      const updatedChannels = [
        ...selectedAcquisitionChannels.map(ac => ({
          value: ac.value,
        })),
        { value },
      ];
      updateCompany({
        variables: {
          id: companyId,
          input: {
            acquisitionChannels: updatedChannels,
          },
        },
      });
    },
    [updateCompany, companyId, selectedAcquisitionChannels]
  );

  const handleRemove = useCallback(
    (value: string) => {
      const updatedChannels = [
        ...selectedAcquisitionChannels
          .filter(ac => ac.value !== value)
          .map(ac => ({
            value: ac.value,
          })),
      ];

      updateCompany({
        variables: {
          id: companyId,
          input: {
            acquisitionChannels: updatedChannels,
          },
        },
      });
    },
    [updateCompany, companyId, selectedAcquisitionChannels]
  );

  const handleSelectChange = useCallback(
    (
      newValues: MultiValue<{ label: string; value: string }>,
      actionMeta: ActionMeta<{ label: string; value: string }>
    ) => {
      switch (actionMeta.action) {
        case "select-option": {
          if (actionMeta.option) {
            handleAdd(actionMeta.option.label);
          }
          break;
        }

        case "create-option": {
          const [option] = newValues.slice(-1);
          handleAdd(option.label);
          break;
        }

        case "deselect-option": {
          if (actionMeta.option) {
            handleRemove(actionMeta.option.label);
          }
          break;
        }

        case "remove-value":
        case "pop-value": {
          if (actionMeta.removedValue) {
            handleRemove(actionMeta.removedValue.label);
          }
          break;
        }

        case "clear": {
          actionMeta.removedValues.map(option => handleRemove(option.label));
          break;
        }
      }
    },
    [handleAdd, handleRemove]
  );

  return (
    <VerticalSpacing size={-6} data-testid="company-source-select">
      <Text size={-1} bold>
        Company Source (How was this company acquired?)
      </Text>
      {role === CurrentRoleType.Admin ? (
        <CreatableSelect
          isMulti
          options={allOptions}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          placeholder="Type..."
          value={selectedOptions}
          onChange={handleSelectChange}
          menuPlacement="auto"
        />
      ) : (
        <SelectField
          isClearable
          isMulti
          onChange={handleSelectChange as any}
          options={allOptions}
          value={selectedOptions}
        />
      )}
    </VerticalSpacing>
  );
}
