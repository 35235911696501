import * as Sentry from "@sentry/browser";
import { Component } from "react";

interface IErrorBoundaryState {
  hasError: boolean;
  eventId?: string;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  IErrorBoundaryState
> {
  public static getDerivedStateFromError(): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, eventId: undefined };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope(scope => {
      scope.setExtras({
        componentStack: errorInfo.componentStack,
      });

      const eventId = Sentry.captureException(error);

      this.setState({ eventId });
    });
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <div>Something went wrong.</div>;
    }

    return this.props.children;
  }
}
