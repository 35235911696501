import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const AssetContainer = styled.div`
  background-color: ${palette.brand.white};
  flex: 0 0 30%;
  padding: 1rem;
`;

export const FormContainer = styled.div`
  flex: 0 0 60%;
  padding: 0 2rem;
  max-width: 100%;
`;

export const Placeholder = styled.div`
  background-color: blue-200;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 1rem;
  width: 250px;
`;

export const Images = styled.div`
  gap: sm;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const AssetButton = styled.button<{ current?: boolean }>`
  all: unset;
  border: none;
  background: transparent;
  width: 250;
  cursor: pointer;
  word-break: break-all;
  white-space: normal;

  ${p => (p.current ? `border: 0.25rem solid ${palette.brand.yellow}` : "")};
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
