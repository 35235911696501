import { Label, SelectField } from "@otta/design";
import { CompanyWorkflowStatus } from "@toolbox/schema";

const options: {
  label: string;
  value: CompanyWorkflowStatus | "NONE" | null;
}[] = [
  { label: "No company", value: "NONE" },
  { label: "Completed", value: null },
  { label: "Approved", value: CompanyWorkflowStatus.Approved },
  { label: "Pre approval", value: CompanyWorkflowStatus.PreApproval },
  { label: "Pushed to pro", value: CompanyWorkflowStatus.PushedToPro },
  { label: "QA", value: CompanyWorkflowStatus.QaInternal },
  { label: "Queued", value: CompanyWorkflowStatus.Queued },
  { label: "Archived", value: CompanyWorkflowStatus.Archived },
];

interface CompanyWorkflowSelectProps {
  value: CompanyWorkflowStatus | "NONE" | null;
  onChange(value: CompanyWorkflowStatus | "NONE" | null): void;
}

export function CompanyWorkflowSelect({
  value,
  onChange,
}: CompanyWorkflowSelectProps) {
  return (
    <div style={{ minWidth: 200 }}>
      <Label>Workflow status</Label>
      <SelectField
        options={options}
        value={options.find(o => o.value === value)}
        onChange={v => onChange(v?.value ?? null)}
      />
    </div>
  );
}
