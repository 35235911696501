import styled from "@xstyled/styled-components";
import * as React from "react";

import { modularScale } from "@otta/design-tokens";

export interface SpacingProps {
  size?: number;
  children: React.ReactNode;
}

const Spacer = styled.div<SpacingProps>`
  margin-top: ${({ size }) => modularScale(size)};
`;

export function Spacing({ size, children }: SpacingProps): React.ReactElement {
  let hasSeenValidElement = false;

  return (
    <>
      {React.Children.map(children, child => {
        const isValid = React.isValidElement(child);

        if (isValid && hasSeenValidElement) {
          return <Spacer size={size}>{child}</Spacer>;
        }

        if (isValid && !hasSeenValidElement) {
          hasSeenValidElement = true;
        }

        return child;
      })}
    </>
  );
}
