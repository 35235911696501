import Select from "react-select";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { ErrorText } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { handleMutationError } from "@toolbox/utils/error";
import { useQuery } from "@toolbox/apollo";
import {
  JobSubFunctionsDocument,
  UpdateSubmittedSalaryDocument,
} from "@toolbox/schema";
import { mapQueryResultToCreatableStructure } from "@toolbox/components/Field/SelectField";

const SelectWrapper = styled.div`
  margin: 8px 0;
  width: 300px;
`;

interface SubmittedSalarySelectProps {
  id: string;
  options: { label: string; value: string }[] | null;
  fieldValue?: string | null;
  fieldName: "currentSubFunctionId" | "currentExperienceLevel";
}

export function SubmittedSalarySelect({
  fieldValue,
  fieldName,
  id,
  options,
}: SubmittedSalarySelectProps): React.ReactElement {
  const { data, error, loading } = useQuery(JobSubFunctionsDocument);

  const [update] = useMutation(UpdateSubmittedSalaryDocument, {
    onError: handleMutationError,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorText>Something went wrong fetching the sub functions</ErrorText>
    );
  }

  const selectOptions = options
    ? options
    : data?.jobSubFunctions?.map(mapQueryResultToCreatableStructure) ?? [];

  const currentValue = selectOptions.find(({ value }) => value === fieldValue);
  return (
    <SelectWrapper>
      <Select
        onChange={(newValue, actionMeta) => {
          if (
            newValue &&
            fieldName &&
            (actionMeta.action === "create-option" ||
              actionMeta.action === "select-option")
          ) {
            update({
              variables: {
                id,
                input: {
                  [fieldName]: newValue.value,
                },
              },
            });
          }
        }}
        options={selectOptions}
        value={currentValue}
      />
    </SelectWrapper>
  );
}
