import { ReactNode, useId, useMemo } from "react";
import styled from "@xstyled/styled-components";

import { Text } from "../Typography";

import { FieldHeading } from "./FieldHeading";
import { ErrorText } from "./ErrorText";
import { ErrorContainer } from "./ErrorContainer";

import { palette } from "@otta/design-tokens";

export type FieldProps = {
  "aria-describedby"?: string;
  "aria-invalid": boolean;
  id?: string;
};

type ErrorMap = Record<`${string}Error`, string | string[] | undefined>;
type Error = { id: string; value: string };
type ErrorTuples = [string, Error[]][];

/**
 * Convert the above `ErrorMap` into a list of tuples,
 * where the key is the element ID and the value is always a list of errors
 */
function formatErrors(fieldId: string, errors: ErrorMap): ErrorTuples {
  return Object.entries(errors).map<[string, Error[]]>(([name, value]) => {
    const fieldName = `${name.substring(0, name.length - 5)}`;
    const errorId = `${fieldId}:${fieldName}:error`;
    if (value === undefined) {
      return [fieldName, []];
    } else if (typeof value === "string") {
      return [fieldName, [{ id: errorId, value }]];
    } else {
      return [
        fieldName,
        value.map((value, index) => ({
          id: `${errorId}${index}`,
          value,
        })),
      ];
    }
  });
}

type Props = ErrorMap & {
  children?: (a: Record<string, FieldProps>) => ReactNode;
  advice?: string | ReactNode;
  required?: boolean;
  className?: string;
  label?: string;
  disabled?: boolean;
};

const FieldContainer = styled.div<{ hasError?: boolean }>`
  border-left-color: ${p => (p.hasError ? palette.brand.red : "transparent")};
  border-left-width: 0.25rem;
  border-left-style: solid;
  flex-direction: column;
  padding-left: 0.75rem;
  margin-left: -1rem;
  display: flex;
  gap: 0.5rem;
`;

/**
 * Sorry this is pretty complex looking
 * But the idea is this handles displaying errors and a red surround etc.
 * You might need to show errors for multiple fields together, and so
 * you can pass along as many {something}Error props as you'd like
 * and in return you'll get an object of attributes per element
 *
 * I tried to make TypeScript understand this but failed, so it is
 * an exercise for the reader
 */
export function FieldWrapper({
  required,
  children,
  className,
  advice,
  label,
  disabled,
  ...errorMap
}: Props): React.ReactElement {
  const id = useId();
  const errors = useMemo(() => formatErrors(id, errorMap), [id, errorMap]);
  const hasErrors = Object.values(errorMap).reduce((a, b) => a || !!b, false);

  /**
   * If we have multiple child elements we can't really link the label to them
   * so we just don't link it to any of them :(
   */
  const labelId = useMemo(() => {
    return errors.length === 1 ? `${id}:${errors[0][0]}` : undefined;
  }, [errors, id]);

  /**
   * Now build a map of information to pass for child elements,
   * so if e.g. a prop called `myElementError` was passed we'll send back
   * {myElement: {id, aria-labelledby, aria-invalid}}
   */
  const childInfo: Record<string, FieldProps> = useMemo(
    () =>
      Object.fromEntries(
        errors.map(([fieldName, errors]) => [
          fieldName,
          {
            ...(labelId ? { id: labelId } : {}),
            "aria-invalid": errors.length > 0,
            ...(errors
              ? { "aria-describedby": errors.map(({ id }) => id).join(" ") }
              : {}),
          },
        ])
      ),
    [errors, labelId]
  );

  return (
    <FieldContainer hasError={hasErrors} className={className}>
      {!!label && (
        <FieldHeading
          label={label}
          htmlFor={labelId}
          required={required}
          optional={!required}
          disabled={disabled}
        />
      )}
      {!!advice &&
        (typeof advice === "string" ? (
          <Text color={palette.grayscale.shade600}>{advice}</Text>
        ) : (
          advice
        ))}
      {hasErrors && (
        <ErrorContainer>
          {errors.flatMap(([, errors]) =>
            errors.map(({ id, value }) => (
              <ErrorText key={id} id={id}>
                {value}
              </ErrorText>
            ))
          )}
        </ErrorContainer>
      )}
      {children && children(childInfo)}
    </FieldContainer>
  );
}
