import styled from "@xstyled/styled-components";
import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import { Article } from "./Article";

import { handleMutationError } from "@toolbox/utils/error";
import { useQuery } from "@toolbox/apollo";
import {
  CreateCompanyArticleDocument,
  CompanyArticlesDocument,
} from "@toolbox/schema";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { Input } from "@toolbox/components/Input";
import { H4 } from "@toolbox/components/Headings";
import { FieldWrapper } from "@toolbox/components/Field/FieldWrapper";
import { Card } from "@toolbox/components/Card";

interface IArticlesProps {
  companyId: string;
}

const StyledInput = styled(Input)`
  margin: 4px 0;
`;

function ArticleCreator({
  companyId,
  onCreate,
}: {
  companyId: string;
  onCreate: () => void;
}) {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const [createMutation] = useMutation(CreateCompanyArticleDocument, {
    onError: handleMutationError,
  });

  const handleCreate = useCallback(async () => {
    const allFilledIn = title.length > 0 && url.length > 0;
    if (allFilledIn) {
      setTitle("");
      setUrl("");

      await createMutation({
        variables: { input: { title, url }, id: companyId },
      });
      onCreate();
    }
  }, [companyId, createMutation, onCreate, title, url]);

  return (
    <Card>
      <FieldWrapper>
        <Textarea
          margin={false}
          placeholder="Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
          onBlur={handleCreate}
        />
        <StyledInput
          placeholder="URL"
          value={url}
          onChange={e => setUrl(e.target.value)}
          onBlur={handleCreate}
        />
      </FieldWrapper>
    </Card>
  );
}

export function Articles({ companyId }: IArticlesProps): React.ReactElement {
  const { data, refetch } = useQuery(CompanyArticlesDocument, {
    variables: { id: companyId },
  });

  return (
    <div>
      <H4>Articles</H4>

      {(data?.company?.articles ?? []).map(({ id, title, url }) => (
        <Article
          key={id}
          id={id}
          title={title}
          url={url}
          refetchAll={refetch}
        />
      ))}
      <ArticleCreator companyId={companyId} onCreate={refetch} />
    </div>
  );
}
