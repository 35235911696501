import styled, { css } from "@xstyled/styled-components";

import { media } from "@otta/shared-components";

interface IHeadingProps {
  inverted?: boolean;
}

const sharedHeadingStyles = css<IHeadingProps>`
  margin: ${({ theme }) => theme.headings.margin};
  color: ${({ theme, inverted }) =>
    inverted ? theme.text.inverted.colour : theme.text.colour};
  max-width: ${({ theme }) => theme.headings.tablet.maxWidth};
  ${media.desktop} {
    max-width: ${({ theme }) => theme.headings.desktop.maxWidth};
  }
  text-align: left;
`;

export const H2 = styled.h2`
  ${sharedHeadingStyles};
  font-size: 32px;
  font-weight: 500;
`;

export const H3 = styled.h3`
  ${sharedHeadingStyles};
`;

export const H4 = styled.h4`
  ${sharedHeadingStyles};
  margin-top: 12px;
  margin-bottom: 12px;
`;
