import { useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { useQuery } from "@toolbox/apollo";
import { Feature, JobFeature, UserHasAccessDocument } from "@toolbox/schema";
import { Input, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

const AccessWrapper = styled.section`
  padding: 40;
  max-width: ${pxToRem(640)};
  margin: 0 auto;
  h1,
  p {
    margin-bottom: 16px;
  }
`;

const AccessList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 24px;
  h3 {
    margin-bottom: 16px;
    text-align: center;
  }
`;
const AccessItem = styled.li`
  margin: 0;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${palette.brand.black};
  &:first-child {
    margin-top: 16px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  input:first-child {
    margin-right: 16px;
  }
`;

const JobFeatureItem = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${palette.brand.black};
`;

const AccessListWrapper = styled.div``;

export function AccessCheck(): React.ReactElement {
  const features = Object.values(Feature);
  const jobFeatures = Object.values(JobFeature);
  const [userId, setUserId] = useState<string>("");

  const { data: accessData } = useQuery(UserHasAccessDocument, {
    variables: {
      userId: userId,
      features: features,
      jobFeatures: jobFeatures,
    },
  });

  const userAccess = useMemo(() => {
    return accessData;
  }, [accessData]);

  const jobAccess = useMemo(() => {
    return accessData?.user?.currentCompany?.listJobs;
  }, [accessData]);

  return (
    <AccessWrapper>
      <h1>User Access</h1>
      <p>Enter a User ID below to check for the access they're granted</p>
      <InputWrapper>
        <Input
          data-testid="access-check-input"
          placeholder="Enter User ID"
          onChange={e => {
            setUserId(e.target.value);
          }}
          value={userId}
        />
      </InputWrapper>
      {userAccess?.user && (
        <AccessListWrapper>
          <AccessList>
            <h3>
              User: {userAccess?.user?.firstName} {userAccess?.user?.lastName}
            </h3>
            <h3>Access Features</h3>
            {userAccess?.user?.hasAccess.map(item => (
              <AccessItem>
                <div>
                  <Text bold as="span">
                    Feature:
                  </Text>{" "}
                  <Text as="span">{item.feature}</Text>
                </div>
                <div>
                  <Text as="span" size={0}>
                    <Text bold as="span">
                      Granted:
                    </Text>{" "}
                    {item.granted ? "True" : "False"}
                  </Text>
                </div>

                {item.message && (
                  <div>
                    <Text as="span" size={0}>
                      <Text bold as="span">
                        Message:
                      </Text>{" "}
                      {item.message}
                    </Text>
                  </div>
                )}
              </AccessItem>
            ))}
          </AccessList>
          <AccessList>
            <h3>Job Features</h3>
            {jobAccess?.map(job => (
              <AccessItem>
                <div>
                  <h4>{job.title}</h4>
                  <Text bold>Job ID: {job.id}</Text>
                </div>
                {job.userHasAccess.map(item => (
                  <JobFeatureItem>
                    <div>Job Feature: {item.feature}</div>
                    <div>
                      <Text as="span" size={0}>
                        <Text bold as="span">
                          Granted:
                        </Text>{" "}
                        {item.granted ? "True" : "False"}
                      </Text>
                    </div>

                    {item.message && (
                      <div>
                        <Text as="span" size={0}>
                          <Text bold as="span">
                            Message:
                          </Text>{" "}
                          {item.message}
                        </Text>
                      </div>
                    )}
                  </JobFeatureItem>
                ))}
              </AccessItem>
            ))}
          </AccessList>
        </AccessListWrapper>
      )}
    </AccessWrapper>
  );
}
