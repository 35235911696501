import styled from "@xstyled/styled-components";

import { Field, IFieldProps } from ".";

import { Link } from "@toolbox/components/Link";
import { Input } from "@toolbox/components/Input";

export const LinkFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    padding-left: 4px;
  }
`;

export function LinkField(
  props: Omit<IFieldProps, "children">
): React.ReactElement {
  return (
    <Field {...props}>
      {renderProps => (
        <LinkFieldWrapper>
          <Input {...renderProps} />
          {renderProps.value && (
            <Link to={renderProps.value} underline={false}>
              <span role="img" aria-label="rocket">
                🚀
              </span>
            </Link>
          )}
        </LinkFieldWrapper>
      )}
    </Field>
  );
}
