import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";

const ChevronWrapper = styled.span<{ isOpen: boolean }>`
  cursor: pointer;
  display: block;
  height: ${modularScale(0)};
  svg {
    height: ${modularScale(0)};
    width: ${modularScale(0)};
    transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : "")};
  }
`;
export default function Chevron({
  isOpen,
}: {
  isOpen: boolean;
}): React.ReactElement {
  return (
    <ChevronWrapper isOpen={isOpen}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="chevron-right"
        className="svg-inline--fa fa-chevron-right fa-w-8"
        role="img"
        viewBox="0 0 256 512"
      >
        <path
          fill={palette.grayscale.shade400}
          d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
        />
      </svg>
    </ChevronWrapper>
  );
}
