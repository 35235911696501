import { format, parseISO } from "date-fns";
import styled, { css, up } from "@xstyled/styled-components";

import { modularScale, pxToRem } from "@otta/design-tokens";
import { CompanyChangesFragment, JobChangesFragment } from "@toolbox/schema";
import { parseValue } from "@toolbox/utils/parseChanges";

interface IChangesTableProps {
  headings: string[];
  changes: Array<CompanyChangesFragment | JobChangesFragment>;
  isFullWidth?: boolean;
}

const Container = styled.div<{ isFullWidth: boolean }>(
  ({ isFullWidth }) => css`
    width: 100%;
    max-height: 25vh;
    overflow-x: scroll;
    font-size: ${modularScale(-1)};
    ${up(
      "desktop",
      css`
        width: ${isFullWidth ? "fit-content" : "100%"};
      `
    )}
  `
);

const Table = styled.table<{ isFullWidth: boolean }>(
  ({ isFullWidth }) => css`
    width: 100%;
    min-width: ${pxToRem(800)};
    ${up(
      "desktop",
      css`
        width: ${isFullWidth ? "fit-content" : "100%"};
      `
    )}
  `
);

const TableHeader = styled.th`
  text-align: left;
  white-space: nowrap;
`;

const TableCell = styled.td<{ _wrap?: boolean }>`
  white-space: ${({ _wrap }) => (_wrap ? "normal" : "nowrap")};
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: gray-200;
  }
`;

function isJobChange(
  change: JobChangesFragment | CompanyChangesFragment
): change is JobChangesFragment {
  return (change as JobChangesFragment).changedBy !== undefined;
}

export function ChangesTable({
  headings,
  changes,
  isFullWidth = false,
  ...props
}: IChangesTableProps): React.ReactElement {
  const hasChanges = changes.length > 0;

  if (!hasChanges) {
    return <div>There are no changes to display</div>;
  }
  return (
    <Container isFullWidth={isFullWidth} {...props}>
      <Table isFullWidth={isFullWidth}>
        <tbody>
          <TableRow>
            {headings.map(header => (
              <TableHeader key={header}>{header}</TableHeader>
            ))}
          </TableRow>
          {changes.map(change => (
            <TableRow key={change.id}>
              <TableCell>{change.fieldName}</TableCell>
              <TableCell _wrap>
                {parseValue(change.fieldName, change.oldValue)}
              </TableCell>
              <TableCell _wrap>
                {parseValue(change.fieldName, change.newValue)}
              </TableCell>
              {isJobChange(change) && change.changedBy ? (
                <TableCell>
                  {change.changedBy === "USER"
                    ? `${change.user?.firstName} ${change.user?.lastName}`
                    : change.changedBy}
                </TableCell>
              ) : null}
              <TableCell>
                {format(parseISO(change.insertedAt), "dd/MM/yy kk:mm")}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
