import { useCallback, useState } from "react";
import styled from "@xstyled/styled-components";

import {} from "@otta/design";
import { Checkbox } from "@toolbox/components/Input/Checkbox";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { Delete } from "@toolbox/components/Icons/Delete";
import { HamburgerMenu } from "@toolbox/components/Icons/HamburgerMenu";

const FieldWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 6;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  padding-left: 5;
`;

const DeleteWrapper = styled.div`
  width: 12px;
  cursor: pointer;
`;

interface IRequirementBulletItemProps {
  id: string;
  value: string;
  desirable: boolean;
  order: number;
  handleUpdate: (item: {
    id: string;
    value: string;
    order: number;
    desirable: boolean;
  }) => void;
  handleDelete: (id: string) => void;
}

export function RequirementBulletItem({
  id,
  handleUpdate,
  handleDelete,
  value,
  desirable,
  order,
  ...props
}: IRequirementBulletItemProps): React.ReactElement {
  const [newValue, setNewValue] = useState(value);
  const [checked, setChecked] = useState(!!desirable);

  const handleBlur = useCallback(() => {
    if (newValue !== value) {
      handleUpdate({
        id,
        value: newValue,
        desirable: checked,
        order,
      });
    }
  }, [handleUpdate, newValue, value, id, checked, order]);

  const handleDesirableToggle = useCallback(() => {
    setChecked(previousState => !previousState);
    handleUpdate({
      id,
      value,
      desirable: !checked,
      order,
    });
  }, [handleUpdate, checked, id, order, value]);

  return (
    <FieldWrapper data-testid="drag-handle">
      <IconWrapper>
        <HamburgerMenu />
      </IconWrapper>
      <Textarea
        margin={false}
        value={newValue}
        onBlur={handleBlur}
        data-testid="requirement-bullet-item"
        onChange={e => setNewValue(e.target.value)}
        style={{ overflow: "hidden" }}
        {...props}
      />
      <Checkbox
        label={""}
        onChange={handleDesirableToggle}
        checked={checked}
        data-testid="requirement-checkbox-item"
        {...props}
      />
      <DeleteWrapper
        data-testid="requirement-delete-button"
        onClick={() => handleDelete(id)}
      >
        <Delete />
      </DeleteWrapper>
    </FieldWrapper>
  );
}
