import styled from "@xstyled/styled-components";
import { Outlet } from "react-router-dom";

import {} from "@otta/design";
import { NavBar } from "@toolbox/components/NavBar";

const PageContainer = styled.div`
  flex: 1;
  position: relative;
`;

const InnerContainer = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 lg;
  display: flex;
  flex-direction: column;
`;

export function Home(): React.ReactElement {
  return (
    <>
      <NavBar />
      <PageContainer>
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </PageContainer>
    </>
  );
}
