import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { PendingSurveyResponseList } from "./PendingSurveyResponseList";

import { handleMutationError } from "@toolbox/utils/error";
import {
  UpdateCompanyEmployeeSurveyIsApprovedDocument,
  CompanyEmployeeSurveysPendingApproval,
} from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";
import { Card } from "@toolbox/components/Card";
import { StyledButton as Button } from "@toolbox/components/Button";

const SurveyWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 10px;
  }
`;

const DetailsWrapper = styled.div`
  div {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
  }
`;

interface IPendingSurveyCard {
  survey: CompanyEmployeeSurveysPendingApproval.CompanyEmployeeSurveysPendingApproval;
}

export function PendingSurveyCard({
  survey,
}: IPendingSurveyCard): React.ReactElement {
  const [mutate, { loading }] = useMutation(
    UpdateCompanyEmployeeSurveyIsApprovedDocument,
    {
      onError: handleMutationError,
    }
  );

  return (
    <Card data-testid="survey">
      <SurveyWrapper>
        <div>
          <DetailsWrapper>
            <div>
              <Link
                to={`${import.meta.env.VITE_URL}/companies/${
                  survey.company.urlSafeName
                }`}
              >
                {survey.company.name}
              </Link>
            </div>
            <div>{survey.jobFunction.value}</div>
          </DetailsWrapper>
          <PendingSurveyResponseList responses={survey.responses} />
        </div>
        <ButtonWrapper>
          <Button
            level={"danger"}
            onClick={() =>
              mutate({ variables: { id: survey.id, isApproved: false } })
            }
            disabled={loading}
          >
            Disapprove
          </Button>
          <Button
            level={"primary"}
            onClick={() =>
              mutate({ variables: { id: survey.id, isApproved: true } })
            }
            disabled={loading}
          >
            Approve
          </Button>
        </ButtonWrapper>
      </SurveyWrapper>
    </Card>
  );
}
