import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { Button } from "@otta/design";
import { handleMutationError } from "@toolbox/utils/error";
import { EnrolIntoJobSlotsDocument } from "@toolbox/schema";

interface IEnrolIntoJobSlotsButtonProps {
  userId: string;
  verified: boolean;
}

export function EnrolIntoJobSlotsButton({
  userId,
  verified,
}: IEnrolIntoJobSlotsButtonProps): React.ReactElement {
  const [mutation] = useMutation(EnrolIntoJobSlotsDocument, {
    variables: { userId },
    onError: handleMutationError,
  });

  const handleClick = useCallback(() => {
    if (
      window.confirm(
        "Are you sure you want to enrol this user into the job slots experiment?"
      )
    ) {
      mutation();
    }
  }, [mutation]);

  return (
    <Button
      data-testid="enrol-job-slots-button"
      level="primary"
      onClick={handleClick}
      size="small"
      disabled={!verified}
    >
      Enrol job slots
    </Button>
  );
}
