import { useEffect, useRef } from "react";
import styled from "@xstyled/styled-components";

const Dialogue = styled.dialog`
  border: none;
  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export function Modal({
  children,
  open,
  ...props
}: React.PropsWithChildren<
  React.DialogHTMLAttributes<HTMLDialogElement>
>): React.ReactElement {
  const ref = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (open && ref.current) {
      ref.current.showModal();
    } else if (!open && ref.current) {
      ref.current.close();
    }
  }, [open]);

  return (
    <Dialogue ref={ref} {...props}>
      {children}
    </Dialogue>
  );
}
