import { useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";

import { Card, Checkbox, Text } from "@otta/design";
import { Spinner } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import {
  Permission,
  AllPermissionsDocument,
  AddUserPermissionDocument,
  RemoveUserPermissionDocument,
  PermissionScope,
} from "@toolbox/schema";

interface PermissionsModalProps {
  id: string;
  email: string;
  permissions: { id: string; name: Permission }[];
}

export function PermissionsModal({
  id,
  email,
  permissions,
}: PermissionsModalProps) {
  const { data, loading } = useQuery(AllPermissionsDocument, {
    variables: { scopes: [PermissionScope.Toolbox] },
  });

  const currentPermissions = useMemo(
    () => new Set(permissions.map(p => p.id) ?? []),
    [permissions]
  );

  const [addPermission, { loading: adding }] = useMutation(
    AddUserPermissionDocument
  );
  const [removePermission, { loading: removing }] = useMutation(
    RemoveUserPermissionDocument
  );

  const handleChange = useCallback(
    (permissionId: string, checked: boolean) => {
      const variables = {
        userId: id,
        permissionId,
      };

      if (checked) {
        addPermission({ variables });
      } else {
        removePermission({ variables });
      }
    },
    [addPermission, id, removePermission]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <Card>
      <Text>
        <Text as="span" bold>
          Permissions for:{" "}
        </Text>
        {email}
      </Text>

      {data?.permissions.map(p => (
        <Checkbox
          key={p.name}
          label={`${p.name} - ${p.description}`}
          name="permissions"
          value={p.name}
          checked={currentPermissions.has(p.id)}
          disabled={adding || removing}
          onChange={checked => handleChange(p.id, checked)}
        />
      ))}
    </Card>
  );
}
