import { useCallback, useState } from "react";
import styled from "@xstyled/styled-components";
import { ApolloQueryResult, useMutation } from "@apollo/client";

import { FieldWrapper } from "../FieldWrapper";

import { handleMutationError } from "@toolbox/utils/error";
import {
  DeleteCompanyOtherBenefitDocument,
  UpdateCompanyOtherBenefitDocument,
} from "@toolbox/schema";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { Delete } from "@toolbox/components/Icons/Delete";

const DeleteWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  cursor: pointer;
`;

interface IRequirementBulletItemProps {
  id: string;
  value: string;

  refetchAll: () => Promise<ApolloQueryResult<unknown>>;
}

export function BenefitTextField({
  id,
  refetchAll,
  value,
  ...props
}: IRequirementBulletItemProps): React.ReactElement {
  const [newValue, setNewValue] = useState(value);

  const [updateBullet] = useMutation(UpdateCompanyOtherBenefitDocument, {
    onError: handleMutationError,
  });

  const handleBlur = useCallback(() => {
    if (newValue !== value) {
      updateBullet({
        variables: { value: newValue, id },
      });
    }
  }, [id, newValue, updateBullet, value]);

  const [deleteRequirement] = useMutation(DeleteCompanyOtherBenefitDocument, {
    variables: { id },
    onError: handleMutationError,
    onCompleted() {
      refetchAll();
    },
  });

  const handleDelete = useCallback(() => {
    deleteRequirement();
  }, [deleteRequirement]);

  return (
    <FieldWrapper>
      <DeleteWrapper data-testid="benefit-delete-button" onClick={handleDelete}>
        <Delete />
      </DeleteWrapper>
      <Textarea
        margin={false}
        value={newValue}
        onBlur={handleBlur}
        onChange={e => setNewValue(e.target.value)}
        {...props}
      />
    </FieldWrapper>
  );
}
