import styled from "@xstyled/styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading = (): JSX.Element => (
  <Wrapper>
    <svg
      viewBox="0 0 64 64"
      width="50px"
      height="auto"
      stroke="#7A7A68"
      fill="#7A7A68"
    >
      <g>
        <circle cx="16" cy="32" strokeWidth="0">
          <animate
            attributeName="fill-opacity"
            dur="750ms"
            values=".5;.6;.8;1;.8;.6;.5;.5"
            repeatCount="indefinite"
          />
          <animate
            attributeName="r"
            dur="750ms"
            values="3;3;4;5;6;5;4;3"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="32" cy="32" strokeWidth="0">
          <animate
            attributeName="fill-opacity"
            dur="750ms"
            values=".5;.5;.6;.8;1;.8;.6;.5"
            repeatCount="indefinite"
          />
          <animate
            attributeName="r"
            dur="750ms"
            values="4;3;3;4;5;6;5;4"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="48" cy="32" strokeWidth="0">
          <animate
            attributeName="fill-opacity"
            dur="750ms"
            values=".6;.5;.5;.6;.8;1;.8;.6"
            repeatCount="indefinite"
          />
          <animate
            attributeName="r"
            dur="750ms"
            values="5;4;3;3;4;5;6;5"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  </Wrapper>
);
