import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";

type ListType = "bullet" | "positive";

export interface ListProps {
  size?: number;
  type?: ListType;
  color?: string;
}

const BULLET_COLOR = palette.grayscale.shade600.replace("#", "%23");
const BULLET_SVG_URL = `url("data:image/svg+xml,%3Csvg version='1.1' fill='${BULLET_COLOR}' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 29.107 29.107' style='enable-background:new 0 0 29.107 29.107;' xml:space='preserve'%3E%3Cpath d='M14.554,0C6.561,0,0,6.562,0,14.552c0,7.996,6.561,14.555,14.554,14.555c7.996,0,14.553-6.559,14.553-14.555 C29.106,6.562,22.55,0,14.554,0z'/%3E%3C/svg%3E")`;
const TICK_SVG_URL = `url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cpath d='M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z'/%3E%3C/svg%3E")`;

const getBackgroundImage = (type: ListType) => {
  switch (type) {
    case "bullet":
      return BULLET_SVG_URL;
    case "positive":
      return TICK_SVG_URL;
    default:
      return BULLET_SVG_URL;
  }
};

const getBackgroundSize = (size: number, type: ListType) => {
  switch (type) {
    case "bullet":
      return modularScale(size - 6);
    case "positive":
      return modularScale(size - 1);
    default:
      return modularScale(size - 6);
  }
};

const getBackgroundPosition = (size: number, type: ListType) => {
  switch (type) {
    case "bullet":
      return `0 ${modularScale(size - 2)}`;
    case "positive":
      return `0 ${modularScale(size - 5)}`;
    default:
      return modularScale(size - 2);
  }
};

const getPaddingLeft = (size: number, type: ListType) => {
  switch (type) {
    case "bullet":
      return modularScale(size);
    case "positive":
      return modularScale(size + 2);
    default:
      return modularScale(size);
  }
};

/**
 * Lists can be of three types: **Bullet**, **Positive**, or **Plain**, where no type is provided.
 *
 * ```ts
 *
 * import { List } from '@otta/design'
 *
 * ```
 */

export const List = styled.ul<ListProps>`
  font-size: ${({ size = 0 }) => modularScale(size)};
  color: ${({ color = "inherit" }) => color};
  list-style: none;

  li {
    text-align: left;
    line-height: 1.6;
    overflow-wrap: anywhere;
    &:not(:last-child) {
      margin-bottom: md;
    }
    ${({ size = 0, type }) =>
      type
        ? `
					background-image: ${getBackgroundImage(type)};
					background-size: ${getBackgroundSize(size, type)};
					background-position: ${getBackgroundPosition(size, type)};
					padding-left: ${getPaddingLeft(size, type)};
					background-repeat: no-repeat;
				`
        : null}
  }
`;
