import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

export const Table = styled.table`
  width: 100%;
  table-layout: auto;
  font-size: ${modularScale(-2)};
`;

export const TableHeader = styled.th`
  text-align: left;
  white-space: nowrap;
  padding: xs;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: gray-200;
  }
`;

export const TableCell = styled.td<{ fullWidth?: boolean; center?: boolean }>`
  padding: xs;
  white-space: nowrap;
  ${({ center }) => center && "text-align: center"};
  ${({ fullWidth }) => fullWidth && "width: 100%"};
`;
