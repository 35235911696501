export const brand = {
  yellow: "#FFCD00",
  teal: "#00C7AE",
  orange: "#FF9868",
  blue: "#55C3E9",
  pink: "#F696C8",
  green: "#BADE94",
  purple: "#ACACFF",
  red: "#E1003A",
  grey: "#EEEEEE",
  beige: "#F6F3EF",
  white: "#FFFFFF",
  black: "#212121",
} as const;

export const extended = {
  red: {
    shade100: "#FBDEDC",
    shade200: "#FCC7C3",
    shade400: "#FF9490",
    shade500: "#FF6165",
    shade600: brand.red,
    shade800: "#75001A",
  },
  orange: {
    shade100: "#FFEBCE",
    shade200: "#FFD495",
    shade400: "#FF9F14",
    shade500: "#DB860A",
    shade600: "#A6670A",
    shade800: "#573607",
  },
  yellow: {
    shade100: "#FFF8D9",
    shade200: "#FFE166",
    shade500: brand.yellow,
  },
  green: {
    shade100: "#EAFFD4",
    shade200: brand.green,
    shade400: "#9FC873",
    shade500: "#83AD57",
    shade600: "#5A8034",
    shade800: "#2A4210",
  },
  blue: {
    shade100: "#E0F5FF",
    shade200: "#9BDEF7",
    shade400: brand.blue,
    shade500: "#27A5D0",
    shade600: "#057AA3",
    shade800: "#025A79",
  },
  pink: {
    shade100: "#FFE8F0",
    shade200: "#FFC5DB",
    shade300: "#FFA1C4",
    shade400: brand.pink,
  },
} as const;

export const grayscale = {
  shade50: "#FAFAFA",
  shade100: brand.grey,
  shade200: "#D5D5D5",
  shade400: "#A5A5A5",
  shade600: "#717171",
} as const;

export const beige = {
  shade100: "#FBF9F7",
  shade200: brand.beige,
  shade300: "#F0EBE5",
  shade400: "#D8D2CB",
} as const;
