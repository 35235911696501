import { useMutation } from "@apollo/client";
import { formatDistanceToNow, parseISO, format, isPast } from "date-fns";
import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";

import { Button, Card, Clickable, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import {
  CompanyBundlesDocument,
  CompanyBundlesQuery,
  CompanySaleTerminationType,
  TerminateCompanySaleDocument,
} from "@toolbox/schema";
import { modularScale, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

type Sale = NonNullable<CompanyBundlesQuery["company"]>["sales"][0];

const Table = styled.table`
  font-size: ${modularScale(-1)};
  border-collapse: collapse;
  text-align: left;
  width: 100%;
`;

const TD = styled.td`
  padding: 0.2rem 0;
  &:not(:last-child) {
    padding-right: 0.5rem;
  }
`;

const TH = styled.th`
  padding: 0.2rem 0;
  &:not(:last-child) {
    padding-right: 0.5rem;
  }
`;

const RedIcon = styled(Icon)`
  color: red-600;
`;

const SmallButton = styled(Button).attrs(p => ({ ...p, size: "small" }))`
  padding: 0.2rem 0.6rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const SaleHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const SaleRow = styled.tr<{ expired?: boolean }>`
  color: ${p => (p.expired ? palette.grayscale.shade600 : "inherit")};
  padding: 0.25rem;
`;

function CancelItem({
  id,
  name,
}: {
  id: string;
  name: string;
}): React.ReactElement {
  const [run] = useMutation(TerminateCompanySaleDocument);
  return (
    <Clickable
      aria-label={`Cancel ${name}`}
      onClick={() =>
        run({
          variables: {
            saleProductVariantId: id,
            type: CompanySaleTerminationType.Cancel,
          },
        })
      }
    >
      <RedIcon icon="delete" />
    </Clickable>
  );
}

function TerminateSale({
  saleId,
  type,
}: {
  saleId: string;
  type: CompanySaleTerminationType;
}): React.ReactElement {
  const [run] = useMutation(TerminateCompanySaleDocument);
  const isCancel = type === CompanySaleTerminationType.Cancel;

  return (
    <SmallButton
      size="small"
      level={isCancel ? "secondary" : "destructive"}
      onClick={() => run({ variables: { saleId, type } })}
    >
      {isCancel ? "Cancel" : "Delete"}
    </SmallButton>
  );
}

function Sale({ sale }: { sale: Sale }): React.ReactElement {
  return (
    <Card>
      <Spacing size={0}>
        <SaleHeader>
          <Text bold as="h3" size={1}>
            {sale.bundle.displayName}
          </Text>
          <Buttons>
            <TerminateSale
              type={CompanySaleTerminationType.Cancel}
              saleId={sale.id}
            />
            <TerminateSale
              type={CompanySaleTerminationType.Delete}
              saleId={sale.id}
            />
          </Buttons>
        </SaleHeader>
        <Table>
          <thead>
            <tr>
              <TH>Name</TH>
              <TH>Quantity</TH>
              <TH>Region</TH>
              <TH>Function</TH>
              <TH>Valid from</TH>
              <TH>Expires</TH>
              <TH />
            </tr>
          </thead>
          <tbody>
            {sale.saleProductVariants.map((pv, i) => {
              const validUntil = pv.validUntil ? parseISO(pv.validUntil) : null;
              const isExpired = !!validUntil && isPast(validUntil);
              return (
                <SaleRow key={i} expired={isExpired}>
                  <TD>{pv.productVariant.internalDisplayName}</TD>
                  <TD>{pv.quantity ?? "∞"}</TD>
                  <TD>{pv.productVariant.region?.name ?? "All"}</TD>
                  <TD>{pv.productVariant.jobFunction?.value ?? "All"}</TD>
                  <TD>{format(parseISO(pv.validFrom), "d MMM yy HH:ii")}</TD>
                  {validUntil ? (
                    <TD title={format(validUntil, "d MMM yy HH:ii")}>
                      {formatDistanceToNow(validUntil)}
                      {isExpired && " ago"}
                    </TD>
                  ) : (
                    <TD>N/A</TD>
                  )}
                  <TD>
                    {!isExpired && (
                      <CancelItem
                        id={pv.id}
                        name={pv.productVariant.internalDisplayName}
                      />
                    )}
                  </TD>
                </SaleRow>
              );
            })}
          </tbody>
        </Table>
        <Text color={palette.grayscale.shade600} size={-1}>
          Last updated by {sale.updatedBy.firstName} {sale.updatedBy.lastName}
        </Text>
      </Spacing>
    </Card>
  );
}

export function CompanySales({
  id,
}: {
  id: string;
}): React.ReactElement | null {
  const { data, loading } = useQuery(CompanyBundlesDocument, {
    variables: { id },
  });
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  } else if (!data?.company) {
    return null;
  } else {
    return (
      <Spacing>
        <Button level="primary" onClick={() => navigate("./add-sales")}>
          Add demo or sales
        </Button>
        {data.company.sales.map((sale, index) => (
          <Sale sale={sale} key={index} />
        ))}
      </Spacing>
    );
  }
}
