import Select from "react-select";
import styled from "@xstyled/styled-components";
import { useCallback } from "react";
import {
  ApolloQueryResult,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";

import { genderOptions } from ".";

import { Label as DefaultLabel } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { Card } from "@toolbox/components/Card";
import { FieldWrapper } from "@toolbox/components/Field/FieldWrapper";
import { LinkField } from "@toolbox/components/Field/LinkField";
import { TextareaField } from "@toolbox/components/Field/TextareaField";
import { TextField } from "@toolbox/components/Field/TextField";
import { Delete } from "@toolbox/components/Icons/Delete";
import { TwoColumns } from "@toolbox/containers/Founders";
import { Gender } from "@toolbox/schema";

const DeleteWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const Label = styled(DefaultLabel)`
  font-size: ${modularScale(-1)};
`;

interface IPersonProps {
  id: string;
  name: string;
  linkedinUrl?: string | null;
  bio?: string | null;
  gender?: Gender | null;
  title?: string | null;
  showGender: boolean;
  showTitle: boolean;
  refetchAll: () => Promise<ApolloQueryResult<unknown>>;
  DELETE_MUTATION: TypedDocumentNode<unknown, { id: string }>;
  UPDATE_MUTATION: TypedDocumentNode<
    unknown,
    { id: string; input: { gender?: Gender | null } }
  >;
}

export function Person({
  id,
  name,
  linkedinUrl,
  bio,
  gender,
  title,
  showGender,
  showTitle,
  refetchAll,
  DELETE_MUTATION,
  UPDATE_MUTATION,
}: IPersonProps): React.ReactElement {
  const [updateMutation] = useMutation(UPDATE_MUTATION);
  const [deleteMutation] = useMutation(DELETE_MUTATION, { variables: { id } });

  const handleDelete = useCallback(async () => {
    await deleteMutation();
    await refetchAll();
  }, [deleteMutation, refetchAll]);
  return (
    <Card style={{ position: "relative" }}>
      <DeleteWrapper onClick={handleDelete}>
        <Delete />
      </DeleteWrapper>

      <FieldWrapper>
        <TwoColumns>
          <TextField
            label="Name"
            value={name}
            mutation={UPDATE_MUTATION}
            fieldName="name"
            id={id}
          />
          {showTitle && (
            <TextField
              label="Title"
              value={title}
              mutation={UPDATE_MUTATION}
              fieldName="title"
              id={id}
            />
          )}
        </TwoColumns>
        <LinkField
          label="LinkedIn URL"
          value={linkedinUrl}
          mutation={UPDATE_MUTATION}
          fieldName="linkedinUrl"
          id={id}
        />
        <TextareaField
          label="Bio"
          value={bio}
          mutation={UPDATE_MUTATION}
          fieldName="bio"
          id={id}
        />
        {showGender && (
          <Label>
            Gender
            <Select
              value={genderOptions.find(v => v.value === gender)}
              options={genderOptions}
              onChange={e => {
                updateMutation({
                  variables: { id, input: { gender: e?.value } },
                });
              }}
              placeholder="Select..."
              aria-label="gender-select-field"
            />
          </Label>
        )}
      </FieldWrapper>
    </Card>
  );
}
