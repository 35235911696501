import { Form, Formik } from "formik";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { useQuery } from "@toolbox/apollo";
import {
  Button,
  Card,
  Fieldset,
  InputField,
  Spacing,
  Text,
  Tipbox,
} from "@otta/design";
import {
  CompanyPeopleBreakdownDocument,
  UpdatePeopleBreakdownDocument,
} from "@toolbox/schema";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";

const LOCATION_OPTIONS = ["Office", "Hybrid", "Remote"];
const GENDER_OPTIONS = ["Female", "Male", "Non-binary", "Other", "Unknown"];
const ETHNICITY_OPTIONS = [
  "Asian",
  "Black or African American",
  "Hispanic or Latino",
  "Multiracial / Multiethnic",
  "White",
  "Other",
  "Unknown",
];

const SmallInput = styled(InputField)`
  max-width: ${pxToRem(300)};
  height: ${pxToRem(61)};
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, ${pxToRem(260)});
  grid-auto-rows: minmax(${pxToRem(100)}, auto);
  gap: ${pxToRem(24)};
`;

type Values = {
  genders: Record<string, number>;
  ethnicities: Record<string, number>;
  locations: Record<string, number>;
};

const empty = {
  genders: Object.fromEntries(GENDER_OPTIONS.map(o => [o, 0.0])),
  ethnicities: Object.fromEntries(ETHNICITY_OPTIONS.map(o => [o, 0.0])),
  locations: Object.fromEntries(LOCATION_OPTIONS.map(o => [o, 0.0])),
};

function formatInitialStat(stat: {
  value: string;
  percent: string;
}): [string, number] {
  return [stat.value, parseFloat(stat.percent)];
}

function formatStatToSubmit(values: Record<string, number>) {
  return Object.entries(values).map(([key, value]) => ({
    percent: value.toString(),
    value: key,
    total: 100,
  }));
}

export function PeopleBreakdown({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  const { loading, data } = useQuery(CompanyPeopleBreakdownDocument, {
    variables: { id: companyId },
  });

  const initialValues: Values = useMemo(() => {
    const stats = data?.company?.ebStatistics?.peopleBreakdown;
    const genders = stats?.genders?.map(formatInitialStat) ?? [];
    const ethnicities = stats?.ethnicities?.map(formatInitialStat) ?? [];
    const locations = stats?.workingLocations?.map(formatInitialStat) ?? [];

    return {
      genders: { ...empty.genders, ...Object.fromEntries(genders) },
      ethnicities: { ...empty.ethnicities, ...Object.fromEntries(ethnicities) },
      locations: { ...empty.locations, ...Object.fromEntries(locations) },
    };
  }, [data]);

  const [updateMutation, { called, loading: mutationLoading }] = useMutation(
    UpdatePeopleBreakdownDocument
  );

  const handleSubmit = useCallback(
    async (values: Values) => {
      await updateMutation({
        variables: {
          companyId,
          peopleBreakdown: {
            genders: formatStatToSubmit(values.genders),
            workingLocations: formatStatToSubmit(values.locations),
            ethnicities: formatStatToSubmit(values.ethnicities),
          },
        },
      });
    },
    [companyId, updateMutation]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Spacing size={1}>
      <Text bold size={2}>
        People breakdown
      </Text>
      <Text>
        Each section must add up to 100 or it will not appear. Leave whole
        section zeroed if it should be excluded.
      </Text>
      <Card>
        {called && <Tipbox level="positive">Changes saved</Tipbox>}
        <Formik
          initialValues={initialValues}
          onSubmit={data => handleSubmit(data)}
        >
          {({ handleChange, values }) => (
            <Form>
              <Spacing size={1}>
                <Fieldset legend="Working locations">
                  <InputGrid>
                    {LOCATION_OPTIONS.map((option: string) => (
                      <SmallInput
                        type="number"
                        onChange={handleChange}
                        key={`locations.${option}`}
                        name={`locations.${option}`}
                        value={values.locations[option] ?? ""}
                        label={option}
                      />
                    ))}
                  </InputGrid>
                </Fieldset>
                <Fieldset legend="Genders">
                  <InputGrid>
                    {GENDER_OPTIONS.map((option: string) => (
                      <SmallInput
                        type="number"
                        onChange={handleChange}
                        key={`genders.${option}`}
                        name={`genders.${option}`}
                        value={values.genders[option] ?? ""}
                        label={option}
                      />
                    ))}
                  </InputGrid>
                </Fieldset>
                <Fieldset legend="Ethnicities">
                  <InputGrid>
                    {ETHNICITY_OPTIONS.map((option: string) => (
                      <SmallInput
                        type="number"
                        onChange={handleChange}
                        key={`ethnicities.${option}`}
                        name={`ethnicities.${option}`}
                        value={values.ethnicities[option] ?? ""}
                        label={option}
                      />
                    ))}
                  </InputGrid>
                </Fieldset>
                <Button
                  type="submit"
                  level="primary"
                  disabled={mutationLoading}
                >
                  Save
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </Card>
    </Spacing>
  );
}
