import { offsetLimitPagination, Reference } from "@apollo/client/utilities";
import { FieldFunctionOptions, TypePolicies } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

const mergeByIds = (
  existing: Reference[] = [],
  incoming: Reference[] = [],
  readField: ReadFieldFunction
) => {
  const newIdSet = new Set(
    incoming.map(item => readField("id", item) as string)
  );
  const overlap = existing.some(item => {
    const id = readField("id", item) as string;
    return newIdSet.has(id);
  });
  return overlap ? incoming : [...existing, ...incoming];
};

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      userJobSubFunctionPreferences: {
        merge: false,
      },
      salaryStatistics: {
        merge: true,
      },
      submittedSalaries: {
        merge: false,
      },
      jobs: {
        merge: false,
      },
      statistics: {
        merge: true,
      },
      users: offsetLimitPagination([
        "email",
        "role",
        "companyWorkflowStatus",
        "companyId",
        "activated",
      ]),
      topSectorTags: offsetLimitPagination(),
      topInvestors: offsetLimitPagination(),
      personalisedTechnologiesUsed: offsetLimitPagination(),
      personalisedCompanies: offsetLimitPagination(),
    },
  },
  JobInterviewCandidateFeedbackRating: {
    fields: {
      factors: {
        merge: false,
      },
    },
  },
  Company: {
    fields: {
      jobApplications: {
        keyArgs: ["id"],
        merge(existing: Reference[] = [], incoming: Reference[] = []) {
          return [...existing, ...incoming];
        },
      },
      listJobs: {
        keyArgs: ["id"],
        merge(
          existing: Reference[] = [],
          incoming: Reference[] = [],
          { readField }: FieldFunctionOptions
        ) {
          return mergeByIds(existing, incoming, readField);
        },
      },
    },
  },
  Job: {
    fields: {
      changes: {
        merge: false,
      },
      requirements: {
        keyArgs: ["id"],
        merge(
          existing: Reference[] = [],
          incoming: Reference[] = [],
          { readField }: FieldFunctionOptions
        ) {
          // this is almost certainly a bug in that this is missing `return` so does nothing
          // but I want to avoid changing toolbox cache behaviour until someone complains
          mergeByIds(existing, incoming, readField);
        },
      },
      involvesBullets: {
        keyArgs: ["id"],
        merge(
          existing: Reference[] = [],
          incoming: Reference[] = [],
          { readField }: FieldFunctionOptions
        ) {
          mergeByIds(existing, incoming, readField);
        },
      },
    },
  },
};
