import styled, { css } from "@xstyled/styled-components";

import {
  BasicBubble as DefaultBasicBubble,
  CustomerBubble as DefaultCustomerBubble,
} from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const sharedStyles = css`
  height: 100%;
  margin-left: 6;
`;

const BasicBubble = styled(DefaultBasicBubble)`
  width: ${pxToRem(40)};
  ${sharedStyles}
`;

const CustomerBubble = styled(DefaultCustomerBubble)`
  width: ${pxToRem(56)};
  ${sharedStyles}
`;

export function ProductBubbles({
  company,
}: {
  company: {
    isCurrentPayingCustomer?: boolean;
  };
}) {
  return (
    <>
      {company.isCurrentPayingCustomer && (
        <CustomerBubble data-testid="customer-bubble" />
      )}
      {!company.isCurrentPayingCustomer && (
        <BasicBubble data-testid="basic-bubble" />
      )}
    </>
  );
}
