import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import styled from "@xstyled/styled-components";

interface IWrapperProps {
  margin: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  textarea {
    width: 100%;
    line-height: 2;
    font-size: 14px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: ${({ theme }) => theme.input.borderColour};
    background-color: ${({ theme }) => theme.palette.white.shade0}
    padding: 0 4px;
    box-sizing: border-box;
    outline: none;

    &:focus {
      border-color: ${({ theme }) => theme.input.focus.borderColour};
    }

    &::placeholder {
      color: ${({ theme }) => theme.palette.grey.shade400}
    }
  }
`;

export function Textarea({
  margin = true,
  ...props
}: TextareaAutosizeProps &
  React.RefAttributes<HTMLTextAreaElement> & {
    margin?: boolean;
  }): React.ReactElement {
  return (
    <Wrapper margin={margin}>
      <TextareaAutosize spellCheck {...props} />
    </Wrapper>
  );
}
