import { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import useGraphQLFilters from "./hooks/useGraphQLFilters";
import { WorkflowSelect } from "./WorkflowSelect";
import { ButtonBar, WorkflowButton } from "./Buttons";
import { Job as SidebarJob, PaginatedSidebar } from "./PaginatedSidebar";
import { JobPage } from "./JobPage";

import { Loading } from "@otta/shared-components";
import {
  useNextWorkflowStatus,
  usePreviousWorkflowStatus,
} from "@toolbox/utils/workflowStatus";
import { CurrentRoleType } from "@toolbox/utils/user";
import { ButtonWrapper } from "@toolbox/components/Button";
import { Link } from "@toolbox/components/Link";
import { P } from "@toolbox/components/Text";
import {
  JobDisapprovalReasonDocument,
  JobsDocument,
  JobWorkflowStatus,
  Permission,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
`;

const JobWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FooterWrapper = styled(ButtonWrapper)`
  margin-left: 16px;
  justify-content: left;
`;

interface JobProps {
  workflowStatus: JobWorkflowStatus;
  role: CurrentRoleType;
  permissions: readonly Permission[];
  onComplete: (jobId: string) => Promise<void>;
}

function Job({
  workflowStatus,
  permissions,
  role,
  onComplete,
}: JobProps): React.ReactElement | null {
  const { jobId } = useParams<{ jobId: string }>();

  const { data } = useQuery(JobDisapprovalReasonDocument, {
    variables: { id: jobId ? jobId : "" },
  });

  const nextStatus = useNextWorkflowStatus(role);

  const prevStatus = usePreviousWorkflowStatus(role);

  const [disableCompletedButton, setDisableCompletedButton] = useState(false);

  useEffect(() => {
    if (workflowStatus === JobWorkflowStatus.Changed) {
      setDisableCompletedButton(true);

      const timeout = setTimeout(() => {
        setDisableCompletedButton(false);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [jobId, workflowStatus]);

  if (!jobId || !data) {
    return null;
  }

  const handleComplete = () => {
    onComplete(jobId);
  };

  return (
    <JobWrapper>
      <JobPage key={jobId} jobId={jobId} />

      <ButtonBar>
        <FooterWrapper>
          <WorkflowSelect
            jobId={jobId}
            workflowStatus={workflowStatus}
            onComplete={handleComplete}
          />
          {workflowStatus === JobWorkflowStatus.QaInternal &&
            (role === CurrentRoleType.Admin ||
              (role === CurrentRoleType.InternalOperator &&
                permissions.includes(Permission.SetExceptionJobs))) && (
              <WorkflowButton
                jobId={jobId}
                level="secondary"
                status={JobWorkflowStatus.Exception}
                onComplete={handleComplete}
              >
                Exception
              </WorkflowButton>
            )}
          {prevStatus && (
            <WorkflowButton
              jobId={jobId}
              level="secondary"
              status={prevStatus}
              onComplete={handleComplete}
              disable={!data.job?.disapprovalReason}
            >
              {role === CurrentRoleType.Admin ||
              prevStatus === JobWorkflowStatus.Disapproved
                ? "Disapprove"
                : "Exception"}
            </WorkflowButton>
          )}
          {nextStatus && (
            <WorkflowButton
              jobId={jobId}
              status={nextStatus}
              level="primary"
              onComplete={handleComplete}
              disable={disableCompletedButton}
            >
              Complete
            </WorkflowButton>
          )}
        </FooterWrapper>
      </ButtonBar>
    </JobWrapper>
  );
}

interface IJobsIndexProps {
  perPage: number;
  workflowStatus: JobWorkflowStatus;
  role: CurrentRoleType;
  permissions: readonly Permission[];
}

const POLL_INTERVAL = 15000;

export function JobsIndex({
  workflowStatus,
  perPage,
  role,
  permissions,
}: IJobsIndexProps): React.ReactElement {
  const navigate = useNavigate();
  const filters = useGraphQLFilters();

  const { data, loading, error, refetch, startPolling, stopPolling } = useQuery(
    JobsDocument,
    {
      variables: {
        offset: 0,
        limit: perPage,
        workflowStatus,
        ...filters,
      },
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  const handleVisibilityChange = useCallback(() => {
    const isVisible = document.visibilityState === "visible";
    if (isVisible) {
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange, {
      capture: true,
    });

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange, {
        capture: true,
      });
    };
  }, [handleVisibilityChange]);

  const jobs = useMemo(() => data?.jobs ?? [], [data?.jobs]);

  const onPageChange = useCallback(
    (currentPage: number) => {
      refetch({
        limit: perPage,
        offset: Math.ceil(currentPage * perPage),
      });
    },
    [perPage, refetch]
  );
  const total = useMemo(() => data?.totalJobs ?? 0, [data?.totalJobs]);
  const totalPages = useMemo(
    () => Math.ceil(total / perPage),
    [perPage, total]
  );

  const handleComplete = useCallback(
    async (jobId: string) => {
      const idx = jobs.findIndex(j => j.id === jobId) + 1;

      if (idx > 0 && idx < jobs.length) {
        navigate(jobs[idx].id);
      } else {
        await refetch();
        navigate("");
      }
    },
    [jobs, navigate, refetch]
  );

  return (
    <Wrapper>
      <PaginatedSidebar
        total={total}
        totalPages={totalPages}
        onPageChange={onPageChange}
      >
        {loading && <Loading />}
        {error && <P>Something went wrong!</P>}
        {jobs.map(job => (
          <Link key={job.id} to={job.id}>
            <SidebarJob
              title={job.title}
              subtitle={job.subtitle}
              company={job.company}
            />
          </Link>
        ))}
        {!loading &&
          jobs.length === 0 &&
          (filters.subFunctionIds ?? []).length > 0 && (
            <p>No jobs found with selected filters</p>
          )}
      </PaginatedSidebar>

      <Routes>
        <Route
          path=":jobId"
          element={
            <Job
              workflowStatus={workflowStatus}
              role={role}
              permissions={permissions}
              onComplete={handleComplete}
            />
          }
        />
      </Routes>
    </Wrapper>
  );
}
