import { PendingSurveyList } from "./PendingSurveyList";

import { H2 } from "@toolbox/components/Headings";
import { PageWrapper } from "@toolbox/components/PageWrapper";

export default function Surveys() {
  return (
    <PageWrapper>
      <H2>Endorsements</H2>
      <PendingSurveyList />
    </PageWrapper>
  );
}
