import styled from "@xstyled/styled-components";

import { Badge, locationLabel } from "./helpers";
import { HandleToggleProps } from "./PaginatedTable";

import { Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { Checkbox } from "@toolbox/components/Input/Checkbox";
import { JobsList, JobWorkflowStatus } from "@toolbox/schema";
import { ProductBubbles } from "@toolbox/components/ProductBubbles";

const Row = styled.tr`
  :focus {
    background-color: gray-50;
  }
`;

const Cell = styled.td`
  padding: xs;
  border-top: 1px solid ${palette.grayscale.shade400};
`;

const UrlCell = styled(Cell)`
  min-width: ${pxToRem(400)};
  max-width: ${pxToRem(500)};
  word-break: break-all;
  padding-right: xl;
`;

const SelectionCell = styled(Cell)`
  border-left: 1px solid ${palette.grayscale.shade400};
`;

interface IJobRowProps {
  job: JobsList.Jobs;
  unsavedWorkflowStatus: JobWorkflowStatus | null;
  handleToggle: ({ ids, workflowStatus }: HandleToggleProps) => void;
}

export const JobRow = ({
  job,
  unsavedWorkflowStatus,
  handleToggle,
}: IJobRowProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    switch (e.key) {
      case "ArrowRight":
        handleToggle({
          ids: [job.id],
          workflowStatus: JobWorkflowStatus.QueuedExternal,
        });
        break;
      case "ArrowLeft":
        handleToggle({
          ids: [job.id],
          workflowStatus: JobWorkflowStatus.Disapproved,
        });

        break;
      default:
        break;
    }
  };

  return (
    <Row tabIndex={1} onKeyDown={handleKeyDown}>
      <Cell>
        <Text bold>{job.company.name}</Text>
        {<ProductBubbles company={job.company} />}
      </Cell>
      <Cell>{job.title}</Cell>
      <Cell>
        {job.subFunction && (
          <Badge $colour={palette.brand.yellow}>{job.subFunction.value}</Badge>
        )}
      </Cell>
      <UrlCell>
        {job.originalUrl && (
          <Text as="a" href={job.originalUrl} target="_blank">
            {job.originalUrl}
          </Text>
        )}
      </UrlCell>
      <Cell>{job.scrapedLocation}</Cell>
      <Cell>
        {job.locationPreferences
          .map(({ location }) => locationLabel(location))
          .filter(location => !!location)
          .map(location => (
            <Badge key={location} $colour={palette.extended.green.shade400}>
              {location}
            </Badge>
          ))}
      </Cell>
      <SelectionCell>
        <Checkbox
          data-testid={`disapprove-${job.id}`}
          label=""
          checked={unsavedWorkflowStatus === JobWorkflowStatus.Disapproved}
          onChange={() => {
            handleToggle({
              ids: [job.id],
              workflowStatus: JobWorkflowStatus.Disapproved,
            });
          }}
        />
      </SelectionCell>
      <SelectionCell>
        <Checkbox
          data-testid={`queue-${job.id}`}
          label=""
          checked={unsavedWorkflowStatus === JobWorkflowStatus.QueuedExternal}
          onChange={() => {
            handleToggle({
              ids: [job.id],
              workflowStatus: JobWorkflowStatus.QueuedExternal,
            });
          }}
        />
      </SelectionCell>
    </Row>
  );
};
