import styled from "@xstyled/styled-components";

interface ISpaceProps {
  size: number;
  vertical?: boolean;
  horizontal?: boolean;
}

export const Space = styled.div<ISpaceProps>`
  margin-right: ${({ horizontal, size }) => (horizontal ? `${size}px` : 0)};
  margin-bottom: ${({ vertical, size }) => (vertical ? `${size}px` : 0)};
  user-select: none;
  background: transparent;
  border: none;
  padding: none;
  margin: none;
`;
