import { useContext, useMemo } from "react";

import { FiltersContext } from "../FiltersProvider";

import { JobsQueryVariables } from "@toolbox/schema";

export default function useGraphQLFilters(): JobsQueryVariables {
  const { subFunction, location } = useContext(FiltersContext);

  const subFunctionIds = useMemo(
    () => Object.values(subFunction ?? {}).flatMap(i => i),
    [subFunction]
  );

  const locations = useMemo(
    () => Object.values(location ?? []).flatMap(i => i.location),
    [location]
  );

  return {
    subFunctionIds,
    locations,
  };
}
