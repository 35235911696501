import styled from "@xstyled/styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { Text } from "../Typography";

import { palette, pxToRem } from "@otta/design-tokens";

interface ContainerProps {
  position: "left" | "center";
  invertMotion?: boolean;
}

const ComponentWrapper = styled.div<{ invertMotion?: boolean }>`
  display: flex;
  align-items: ${({ invertMotion }) => (invertMotion ? "flex-end" : "center")};
  justify-content: center;
  width: 100%;
  ${({ invertMotion }) => invertMotion && "height: 100%"};
`;

const ToastWrapper = styled(motion.div)<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: md;
  margin-top: 6;
  position: absolute;
  top: ${({ invertMotion }) => (invertMotion ? 0 : "100%")};
  left: ${({ position }) => (position === "center" ? "50%" : 0)};
  width: auto;

  background: ${palette.brand.black};
  box-shadow: ${pxToRem(0)} ${pxToRem(4)} ${pxToRem(16)} rgba(0, 0, 0, 0.16);
  border-radius: 8;
  pointer-events: none;
`;

const ToastContainer = styled.div`
  position: relative;
  width: 100%;
`;
interface IToastProps extends Partial<ContainerProps> {
  className?: string;
  isVisible: boolean;
  toastMessage: string;
  children: React.ReactNode;
}

/**
 * **Toast** is a pop-up that happens when an object is clicked. This component has an `isVisible` state that is controlled by a `useState` timer within the `onClick` function of the child component.
 *
 * ```tsx
 *
 *  import { Toast } from '@otta/design'
 *
 * ```
 *
 * Anything can be used with a toast notification! To use, just wrap a Button, Text, an image (whatever you want to trigger it) in `<Toast>`:
 *
 * ```tsx
 *  <Container>
 *    <Toast
 *      className={'example-toast'}
 *      position={'center'}
 *      isVisible={isVisible}
 *      toastMessage={'This is a toast notification'}
 *    >
 *      <Button size="regular" level="primary" onClick={onClick}>
 *        Click me for Toast 🍞
 *      </Button>
 *    </Toast>
 *  </Container>
 * ```
 *
 * You can also invert the motion of the toast and make it pop up, using the `invertMotion` prop.
 */

export function Toast({
  className,
  position = "center",
  isVisible,
  invertMotion = false,
  toastMessage,
  children,
}: IToastProps): React.ReactElement {
  return (
    <ToastContainer data-testid="toast-notification">
      <ComponentWrapper invertMotion={invertMotion}>
        {children}
      </ComponentWrapper>
      <AnimatePresence>
        {isVisible && (
          <ToastWrapper
            className={className}
            position={position}
            style={position === "center" ? { x: "-50%" } : undefined}
            initial={{ opacity: 0, y: "0%" }}
            animate={
              isVisible
                ? { opacity: 1, y: invertMotion ? "-60%" : "60%" }
                : { opacity: 0, y: "0%" }
            }
            transition={{
              type: "tween",
              duration: 1,
              ease: "easeInOut",
            }}
            exit={{ opacity: 0, y: "0%" }}
            invertMotion={invertMotion}
          >
            <Text color={palette.brand.white}>{toastMessage}</Text>
          </ToastWrapper>
        )}
      </AnimatePresence>
    </ToastContainer>
  );
}
