import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Button, Spacing } from "@otta/design";
import { RefreshLiveJobsDocument, ScrapeJobsDocument } from "@toolbox/schema";

export function RefreshLiveJobs() {
  const { companyId } = useParams();
  const [refreshLiveJobs, { loading: refreshLoading }] = useMutation(
    RefreshLiveJobsDocument,
    {
      variables: { companyId: companyId || "" },
    }
  );

  const [scrapeJobs, { loading: scrapeLoading }] = useMutation(
    ScrapeJobsDocument,
    {
      variables: { companyId: companyId || "" },
    }
  );

  return (
    <div style={{ padding: "2rem" }}>
      <Spacing size={2}>
        <Button
          onClick={() => refreshLiveJobs()}
          disabled={refreshLoading}
          level="primary"
        >
          Refresh live jobs for this company
        </Button>
        <Button
          onClick={() => scrapeJobs()}
          disabled={scrapeLoading}
          level="primary"
        >
          Scrape jobs for this company
        </Button>
      </Spacing>
    </div>
  );
}
