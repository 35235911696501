import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";

import { AutoSetContainer } from "../FieldLabel";

import {
  Button,
  ErrorText,
  Input,
  Label,
  Text,
  VerticalSpacing,
} from "@otta/design";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";

type IOfficeDays = {
  minDaysInOffice: string;
  maxDaysInOffice: string;
};

const OfficeRangeWrapper = styled.div`
  display: flex;
  box-shadow: inset 0 0 0 1px ${palette.grayscale.shade400};
  border-radius: ${pxToRem(5)};
  background-color: white;
  width: 200px;
`;

const OfficeDayInput = styled(Input)`
  text-align: center;
  border-radius: 0;
  border: 1px solid transparent;
  background-color: transparent;
`;

const RangeSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${pxToRem(30)};
  color: gray-400;
`;

const OfficeLabel = styled(Label)`
  font-size: ${modularScale(-1)};
  font-weight: 600;
`;

export const RemoveButton = styled(Button)`
  font-size: ${modularScale(-2)};
  font-weight: normal;
  background: ${palette.grayscale.shade400};
  &:hover {
    background: ${palette.grayscale.shade200};
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputLabel = styled(Label)`
  font-size: ${modularScale(-1)};
`;

const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 200px;
`;

const validOfficeDayRange = (
  minValue: string,
  maxValue: string
): string | undefined => {
  if (!minValue && !maxValue) {
    return undefined;
  }

  const formattedMin = parseInt(minValue);
  const formattedMax = parseInt(maxValue);

  if (isNaN(formattedMin)) {
    return "Enter a minimum value";
  }

  // Ensure min is in range
  if (!(formattedMin >= 0 && formattedMin <= 5)) {
    return "Enter a minimum between 0 - 5";
  }

  // Ensure max is in range if exists
  if (!isNaN(formattedMax)) {
    if (!(formattedMax >= 1 && formattedMax <= 5)) {
      return "Enter a maximum between 1 - 5";
    }

    if (formattedMax < formattedMin) {
      return "Max value must be greater than or equal to minimum value";
    }
  }

  return undefined;
};

export const RequiredOfficeDaysField = ({
  label,
  subText,
  onBlur: handleSubmit,
  onRemove: handleRemove,
  autoSet,
}: {
  label?: string;
  subText?: string | JSX.Element;
  onBlur: () => void;
  onRemove?: () => void;
  autoSet?: boolean;
}): React.ReactElement => {
  return (
    <VerticalSpacing size={-4} data-testid="required-office-days-range-field">
      <LabelWrapper>
        <OfficeLabel>{label}</OfficeLabel>
        {handleRemove && (
          <RemoveButton
            level="secondary"
            size="small"
            onClick={() => handleRemove()}
            data-testid="clear-days-in-office-button"
          >
            Remove
          </RemoveButton>
        )}
      </LabelWrapper>
      {subText && <Text size={-1}>{subText}</Text>}

      <InputLabelContainer>
        <InputLabel htmlFor="minDaysInOffice">
          Min {autoSet && <AutoSetContainer>Auto-set</AutoSetContainer>}
        </InputLabel>
        <RangeSeparator />
        <InputLabel htmlFor="minDaysInOffice">
          Max {autoSet && <AutoSetContainer>Auto-set</AutoSetContainer>}
        </InputLabel>
      </InputLabelContainer>
      <Field<IOfficeDays>
        name="requiredOfficeDaysRange"
        validate={requiredOfficeDaysRange => {
          return validOfficeDayRange(
            requiredOfficeDaysRange.minDaysInOffice,
            requiredOfficeDaysRange.maxDaysInOffice
          );
        }}
      >
        {({ input, meta }) => (
          <>
            <OfficeRangeWrapper>
              <OfficeDayInput
                value={input.value.minDaysInOffice}
                type="number"
                name="minDaysInOffice"
                data-testid="min-office-days-input"
                error={meta.touched && meta.error}
                onChange={event => {
                  input.onChange({
                    target: {
                      value: {
                        ...input.value,
                        minDaysInOffice: event.target.value,
                      },
                    },
                  });
                }}
                onBlur={() => handleSubmit()}
              />
              <RangeSeparator>
                <Text>-</Text>
              </RangeSeparator>
              <OfficeDayInput
                value={input.value.maxDaysInOffice}
                type="number"
                name="maxDaysInOffice"
                data-testid="max-office-days-input"
                error={meta.touched && meta.error}
                onChange={event => {
                  input.onChange({
                    target: {
                      value: {
                        ...input.value,
                        maxDaysInOffice: event.target.value,
                      },
                    },
                  });
                }}
                onBlur={() => handleSubmit()}
              />
            </OfficeRangeWrapper>
            {meta.error && (
              <ErrorText data-testid="office-days-error-text">
                {meta.error}
              </ErrorText>
            )}
          </>
        )}
      </Field>
    </VerticalSpacing>
  );
};
