import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";

import WorkflowButton from "./WorkflowButton";
import { ArchiveButton, ButtonBar } from "./WorkflowButtons";
import { CompanyPage } from "./CompanyPage";

import { Button, ErrorText, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { Redirect } from "@toolbox/router";
import { CurrentRoleType } from "@toolbox/utils/user";
import {
  CompanyWorkflowStatus,
  NextCompanyToProcessDocument,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";

interface CompanyPipelineProps {
  workflowStatus: CompanyWorkflowStatus;
}

function GetNextCompany({ workflowStatus }: CompanyPipelineProps) {
  const { data, loading, error } = useQuery(NextCompanyToProcessDocument, {
    variables: { workflowStatus: workflowStatus },
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorText>There was an issue getting companies!</ErrorText>;
  }

  if (!data?.nextCompanyToProcess) {
    return <Text>There are no companies remaining</Text>;
  }

  return <Redirect to={data.nextCompanyToProcess.id} />;
}

function Company({
  role,
  onComplete,
  workflowStatus,
}: {
  role: CurrentRoleType;
  workflowStatus: CompanyWorkflowStatus;
  onComplete?: () => void;
}) {
  const { companyId } = useParams();

  if (!companyId) {
    return null;
  }

  return (
    <>
      <CompanyPage role={role} companyId={companyId} />
      <ButtonBar data-testid="company-workflow-buttons">
        <Button level="secondary" onClick={onComplete}>
          Skip
        </Button>
        <ArchiveButton companyId={companyId} workflowStatus={workflowStatus} />
        <WorkflowButton
          level="primary"
          companyId={companyId}
          nextStatus={CompanyWorkflowStatus.QaInternal}
        >
          Mark as done
        </WorkflowButton>
      </ButtonBar>
    </>
  );
}

export function CompanyPipeline({
  role,
  workflowStatus,
}: CompanyPipelineProps & { role: CurrentRoleType }): React.ReactElement {
  const navigate = useNavigate();

  const handleComplete = useCallback(() => {
    navigate("", { replace: true });
  }, [navigate]);

  return (
    <Routes>
      <Route
        path="/"
        element={<GetNextCompany workflowStatus={workflowStatus} />}
      />
      <Route
        path=":companyId"
        element={
          <Company
            role={role}
            workflowStatus={workflowStatus}
            onComplete={handleComplete}
          />
        }
      />
    </Routes>
  );
}
