import { useState } from "react";

import { Filters } from "./Filters";
import { PaginatedTable } from "./PaginatedTable";

import { VerticalSpacing } from "@otta/design";

export const BulkPreApproval = () => {
  const [group, setGroup] = useState<string | undefined>(undefined);
  const [isPro, setIsPro] = useState<boolean | undefined>(undefined);
  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  const [scrapedLocation, setScrapedLocation] = useState<string | undefined>(
    undefined
  );

  const handleGroupChange = (
    option: { value: string | undefined; label: string } | null
  ) => {
    if (option) {
      setGroup(option.value);
    }
  };

  const handleIsProChange = (
    option: { value?: boolean; label: string } | null
  ) => {
    if (option) {
      setIsPro(option.value);
    }
  };

  const handleSearchFieldsChange = ({
    companyName,
    scrapedLocation,
  }: {
    companyName?: string;
    scrapedLocation?: string;
  }) => {
    setCompanyName(companyName);
    setScrapedLocation(scrapedLocation);
  };

  return (
    <VerticalSpacing>
      <Filters
        handleGroupChange={handleGroupChange}
        handleIsProChange={handleIsProChange}
        handleSearchFieldsChange={handleSearchFieldsChange}
        handleClear={() => {
          setCompanyName(undefined);
          setScrapedLocation(undefined);
        }}
      />
      <PaginatedTable
        group={group}
        isPro={isPro}
        companyName={companyName}
        scrapedLocation={scrapedLocation}
      />
    </VerticalSpacing>
  );
};
