import {
  Mutation as ApolloMutation,
  Query as ApolloQuery,
  QueryComponentOptions,
  MutationComponentOptions,
} from "@apollo/client/react/components";
import * as Sentry from "@sentry/browser";
import { OperationVariables } from "@apollo/client";

import { Loading } from "../../components/Loading";
import { ErrorBoundary } from "../../components/ErrorBoundary";

export function Query<
  Data = unknown,
  Variables extends OperationVariables = Record<string, never>
>({
  children,
  ...props
}: QueryComponentOptions<Data, Variables>): JSX.Element | null {
  return ApolloQuery<Data, Variables>({
    ...props,
    children(result) {
      if (result.loading || (!result.error && result.data === undefined)) {
        return <Loading />;
      }
      return <ErrorBoundary>{children(result)}</ErrorBoundary>;
    },
  });
}

export function Mutation<Data = unknown, Variables = Record<string, never>>({
  children,
  ...props
}: MutationComponentOptions<Data, Variables>): JSX.Element | null {
  return ApolloMutation<Data, Variables>({
    onError: e => {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const errorMessage = e.graphQLErrors.map(
          ({ message }) => `${message}\n`
        );
        window.alert(errorMessage);
      } else {
        Sentry.captureException(e);
        window.alert("Something went wrong. Try again.");
      }
    },
    ...props,
    children(mutation, rest) {
      return <ErrorBoundary>{children(mutation, rest)}</ErrorBoundary>;
    },
  });
}
