import styled, { css } from "@xstyled/styled-components";

import { Card } from "./Card";

import { palette, pxToRem } from "@otta/design-tokens";

export type AlertType = "danger" | "warning" | "information";

const StyledAlertCard = styled(Card)<{
  level: AlertType;
}>`
  border-radius: ${pxToRem(8)};
  padding: md;
  position: relative;

  ${({ level }: { level: AlertType }) =>
    level === "warning"
      ? css`
          background-color: orange-100;
          border: 1px solid ${palette.extended.orange.shade500};
        `
      : level === "danger"
      ? css`
          background-color: red-100;
          border: 1px solid ${palette.extended.orange.shade500};
        `
      : level === "information" &&
        css`
          background-color: blue-100;
          border: 1px solid ${palette.extended.blue.shade500};
        `}
`;

/**
 * ```tsx
 *
 * import { Alert } from '@otta/design'
 *
 * ```
 */

export const Alert = ({
  level,
  children,
  className,
}: React.PropsWithChildren<{
  className?: string;
  level: AlertType;
}>): React.ReactElement => {
  return (
    <StyledAlertCard className={className} level={level}>
      {children}
    </StyledAlertCard>
  );
};
