import styled, { css } from "@xstyled/styled-components";

import { Text } from "../../Typography";
import { fadeIn } from "../../../theme";

import { palette, pxToRem } from "@otta/design-tokens";

const CurrencyDropdown = styled.div<{
  expanded: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 4 0 0 4;
  border: 1px solid ${palette.grayscale.shade400};
  background-color: gray-100;
  width: ${pxToRem(90)};

  ${({ expanded }) =>
    expanded &&
    css`
      border: 1px solid ${palette.brand.black};
      outline: none;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: rgba(225, 225, 219, 0.5);
      color: rgba(0, 0, 0, 0.65);
    `}
`;

const TextWrapper = styled.div`
  margin: 1 lg 0 0;
`;

const DropdownArrow = styled.div`
  position: absolute;
  right: ${pxToRem(12)};
  bottom: ${pxToRem(15)};
  height: ${pxToRem(8)};
  width: ${pxToRem(8)};
  border-right: ${pxToRem(2)} solid ${palette.grayscale.shade400};
  border-bottom: ${pxToRem(2)} solid ${palette.grayscale.shade400};
  transform: rotate(45deg);
`;

const Dropdown = styled.div`
  background: ${palette.brand.white};
  z-index: 100;
  max-height: 200px;
  white-space: nowrap;
  border-radius: 4;
  animation-duration: 0.2s;
  animation-name: ${fadeIn};
  animation-timing-function: ease;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  position: absolute;
  margin-top: 40;
  border-radius: 4;
`;

const DropdownItem = styled.div`
  padding: 11 lg;
  cursor: pointer;
  &:hover {
    background: ${palette.brand.grey};
  }
  &:first-child {
    border-radius: 4 4 0 0;
  }
  &:last-child {
    border-radius: 0 0 4 4;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${palette.grayscale.shade50};
  }
  width: ${pxToRem(90)};

  &:first-child {
    border-radius: 4 4 0 0;
  }

  &:last-child {
    border-radius: 0 0 4 4;
  }
`;

interface ICurrencySelectionProps<T> {
  currency: T;
  onClick: (e: T) => void;
  expand: boolean;
  setExpand: (e: boolean) => void;
  disabled?: boolean;
  options: T[];
}

export function CurrencySelection<T extends string>({
  currency,
  onClick,
  expand,
  setExpand,
  disabled,
  options,
}: ICurrencySelectionProps<T>) {
  return (
    <>
      <CurrencyDropdown
        data-testid="currency-dropdown"
        onClick={() => setExpand(!expand)}
        disabled={disabled}
        expanded={expand}
      >
        <TextWrapper>
          <Text>{currency ? currency : "Select..."}</Text>
        </TextWrapper>
        <DropdownArrow />
      </CurrencyDropdown>
      {expand && (
        <Dropdown>
          {options.map(option => (
            <DropdownItem key={option} onClick={() => onClick(option)}>
              <Text>{option}</Text>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </>
  );
}
