import styled, { css } from "@xstyled/styled-components";

import { Card } from "./Card";

import { palette, pxToRem } from "@otta/design-tokens";

export type TipType =
  | "information"
  | "positive"
  | "neutral"
  | "error"
  | "warning";

const StyledTipCard = styled(Card)<{
  level: TipType;
}>`
  border-radius: 4;
  padding: md;

  position: relative;

  ${({ level }: { level: TipType }) =>
    level === "information"
      ? css`
          background-color: blue-100;
          border-left: ${pxToRem(4)} solid ${palette.extended.blue.shade500};
        `
      : level === "neutral"
      ? css`
          background-color: white;
          border-left: ${pxToRem(4)} solid ${palette.brand.yellow};
        `
      : level === "error"
      ? css`
          background-color: red-100;
          border-left: ${pxToRem(4)} solid ${palette.extended.red.shade500};
        `
      : level === "positive"
      ? css`
          background-color: green-100;
          border-left: ${pxToRem(4)} solid ${palette.extended.green.shade500};
        `
      : level === "warning" &&
        css`
          background-color: orange-100;
          border-left: ${pxToRem(4)} solid ${palette.extended.orange.shade500};
        `}
`;

/**
 * ```tsx
 *
 * import { Tipbox } from '@otta/design'
 *
 * ```
 */

export const Tipbox = ({
  level,
  children,
  className,
}: {
  level: TipType;
  children: (string | React.ReactElement)[] | (string | React.ReactElement);
  className?: string;
}): React.ReactElement => {
  return (
    <StyledTipCard className={className} level={level}>
      {children}
    </StyledTipCard>
  );
};
