import { Label, SelectField } from "@otta/design";

const OPTIONS = [
  { label: "All", value: null },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

interface ActivatedSelectProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export function ActivatedSelect({ value, onChange }: ActivatedSelectProps) {
  return (
    <div style={{ minWidth: 200 }}>
      <Label>Activated</Label>
      <SelectField
        options={OPTIONS}
        value={OPTIONS.find(o => o.value === value)}
        onChange={e => onChange(e?.value ?? null)}
      />
    </div>
  );
}
