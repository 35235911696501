import { useMutation } from "@apollo/client";
import { useState } from "react";
import styled from "@xstyled/styled-components";

import { Button, Card, Middle, Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { Input } from "@toolbox/components/Input";
import {
  JobWorkflowStatus,
  UpdateJobWorkflowStatusBatchDocument,
} from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-contents: center;
  align-items: center;

  > button {
    margin-left: lg;
  }
`;

const Upload = styled(Input)`
  background-color: white;
  height: ${pxToRem(46)};
  padding-top: 6;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Header = styled.th`
  border: 1px solid ${palette.grayscale.shade200};
  text-align: center;
  padding: xs;
`;

const Field = styled.td`
  border: 1px solid ${palette.grayscale.shade200};
  text-align: center;
  width: ${pxToRem(50)};
  padding: xs;
`;

const formatPlural = (count: number, word: string) =>
  count === 1 ? word : `${word}s`;

function CSVUpload() {
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [approvedJobs, setApprovedJobs] = useState<string[]>([]);
  const [disapprovedJobs, setDisapprovedJobs] = useState<string[]>([]);

  const [mutation, { loading }] = useMutation(
    UpdateJobWorkflowStatusBatchDocument,
    {
      onError: handleMutationError,
    }
  );

  const submitJobs = async () => {
    await mutation({
      variables: {
        jobIds: approvedJobs,
        workflowStatus: JobWorkflowStatus.QueuedExternal,
      },
    });

    await mutation({
      variables: {
        jobIds: disapprovedJobs,
        workflowStatus: JobWorkflowStatus.Disapproved,
      },
    });
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !document ||
      !e?.target?.files ||
      !(e.target.files[0] instanceof Blob)
    ) {
      return;
    }

    const uploadedFile = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const file = event?.target?.result;

      if (!file || typeof file !== "string") {
        return;
      }

      const fileContents = file.split("\n");

      const columns = fileContents[0].split(",");
      const approvedColIndex = columns.indexOf("Approved (Y/N)");
      const jobIdColIndex = columns.indexOf("Job ID");

      if (approvedColIndex == -1) {
        setError("Missing 'Approved (Y/N)' column");
        return;
      }

      if (jobIdColIndex == -1) {
        setError("Missing 'Job ID' column");
        return;
      }

      const rows = fileContents.slice(1);

      const formattedRows = rows.map((row: string) => {
        const rowContent = row.split(",");
        const approved = rowContent[approvedColIndex].trim() === "Y";
        const jobId = rowContent[jobIdColIndex].trim();

        return { approved, jobId };
      });

      const approvedCSVJobs = formattedRows
        .filter(({ approved }) => approved)
        .map(({ jobId }) => jobId);

      const disapprovedCSVJobs = formattedRows
        .filter(({ approved }) => !approved)
        .map(({ jobId }) => jobId);

      setApprovedJobs(approvedCSVJobs);
      setDisapprovedJobs(disapprovedCSVJobs);
      setUploaded(true);
      setError(null);
    };

    reader.readAsText(uploadedFile);
  };

  return (
    <Middle maxWidth={500}>
      <Spacing size={-1}>
        {uploaded && !error && (
          <Spacing size={-5}>
            <Text align="left">
              {approvedJobs.length} {formatPlural(approvedJobs.length, "job")}{" "}
              to be marked as{" "}
              <Text
                as="span"
                bold
                style={{ color: palette.extended.green.shade500 }}
              >
                Queued (External)
              </Text>
            </Text>
            <Text align="left">
              {disapprovedJobs.length}{" "}
              {formatPlural(disapprovedJobs.length, "job")} to be marked as{" "}
              <Text as="span" bold style={{ color: palette.brand.red }}>
                Disapproved
              </Text>
            </Text>
          </Spacing>
        )}
        <UploadWrapper>
          <Upload
            type="file"
            data-testid="file-upload"
            accept=".csv"
            onChange={handleUpload}
          />
          <Button
            level="primary"
            onClick={() => {
              submitJobs();
              setSubmitted(true);
            }}
            disabled={!!error || !uploaded || loading || submitted}
          >
            {submitted ? "Updated" : "Update"}
          </Button>
        </UploadWrapper>
        {error && (
          <Text
            bold
            style={{ color: palette.brand.red }}
            align="left"
            size={-1}
          >
            {error}
          </Text>
        )}
      </Spacing>
    </Middle>
  );
}

function TemplateDownload(): React.ReactElement {
  const createTemplate = (columns: string[]): string => {
    const divider = "\t";
    const th = `${columns.join(divider)}`;
    const td = columns.map(() => " ").join(`"${divider}"`);

    return `${th}\n"${td}"`;
  };

  const exportToCSV = (text: string): void => {
    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/plain;charset=utf-8," + encodeURIComponent(text);
    hiddenElement.download = "preapproval-jobs.csv";
    hiddenElement.click();
  };

  return (
    <Button
      level="primary"
      onClick={() => {
        const template = createTemplate([
          "Approved (Y/N)",
          "Job ID",
          "Company name",
          "Pro tier",
          "Job title",
          "Location",
          "URL",
        ]);

        exportToCSV(template);
      }}
    >
      Download template
    </Button>
  );
}

const DemoTable = (): React.ReactElement => (
  <Middle maxWidth={350}>
    <Table>
      <thead>
        <tr style={{ backgroundColor: palette.grayscale.shade200 }}>
          <Header>Approved (Y/N)</Header>
          <Header>Job ID</Header>
          <Header>...</Header>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Field>Y</Field>
          <Field>54712</Field>
          <Field>...</Field>
        </tr>
        <tr>
          <Field>N</Field>
          <Field>41872</Field>
          <Field>...</Field>
        </tr>
        <tr>
          <Field>...</Field>
          <Field>...</Field>
          <Field>...</Field>
        </tr>
      </tbody>
    </Table>
  </Middle>
);

interface ISectionProps {
  title: string;
  text: string;
  children?: React.ReactNode;
}

const Section = ({
  title,
  text,
  children,
}: ISectionProps): React.ReactElement => (
  <Spacing>
    <Spacing size={-1}>
      <Text bold align="left" size={1}>
        {title}
      </Text>
      <Text align="left">{text}</Text>
    </Spacing>
    {children}
  </Spacing>
);

export const BulkJobs = (): React.ReactElement => (
  <Middle maxWidth={650}>
    <Card>
      <Spacing size={3}>
        <Text bold align="center" size={2}>
          Bulk pre-approval
        </Text>
        <Section
          title="Step 1"
          text="Download the bulk jobs data template below and paste the jobs of
            interest in the template"
        >
          <TemplateDownload />
        </Section>
        <Section
          title="Step 2"
          text="Mark any approved jobs with a Y and any disapproved jobs
          with an N in the approved column"
        >
          <DemoTable />
        </Section>
        <Section
          title="Step 3"
          text="Save your document as a CSV file and upload it below to review your jobs. After reviewing, press update to insert the changes into the DB"
        >
          <CSVUpload />
        </Section>
      </Spacing>
    </Card>
  </Middle>
);
