"use client";

import {
  BrowserClient,
  Identify,
  ValidPropertyType,
  TrackingOptions,
} from "@amplitude/analytics-types";

interface IIdentify {
  new (): Identify;
}

/**
 * there's more at
 * https://developers.axeptio.eu/english/cookies/axeptio-javascript-sdk
 */
interface AxeptioSDK {
  hasAcceptedVendor(vendor: string): boolean;
}

declare global {
  interface Window {
    axeptioSDK?: AxeptioSDK;
    dataLayer?: {
      push?: (value: Record<string, unknown>) => void;
    };
    amplitude?: BrowserClient & {
      Identify: IIdentify;
      TrackingOptions: TrackingOptions;
    };
    amplitudeInitialised: boolean;
    Cookiebot?: {
      hide: () => void;
      show: () => void;
      consent?: {
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
        marketing: boolean;
      };
    };
  }
}

if (typeof window === "object") {
  window.amplitudeInitialised = false;
}

export function pushAnalyticsEvent({
  eventName,
  ...rest
}: { eventName: string } & Record<string, unknown>): void {
  maybeEnableAmplitudeTracking();
  pushEventToDataLayer({
    event: eventName,
    ...rest,
  });
  pushToAmplitude(eventName, rest);
}

export function pushEventToDataLayer(
  item: { event: string } & Record<string, unknown>
): void {
  pushVariablesToDataLayer(item);
}

export function pushVariablesToDataLayer(item: Record<string, unknown>) {
  if (
    typeof window?.dataLayer?.push === "function" &&
    process.env.NODE_ENV !== "development"
  ) {
    window.dataLayer.push(item);
  }
}

export function pushToAmplitude(
  eventName: string,
  eventProperties: Record<string, unknown>
): void {
  if (
    typeof window?.amplitude?.track === "function" &&
    process.env.NODE_ENV !== "development"
  ) {
    window.amplitude.track(eventName, eventProperties);
  }
}

export function identifyUser(
  userId: string,
  props?: Record<string, ValidPropertyType | undefined>
): void {
  if (typeof window?.amplitude?.setUserId !== "function") {
    return;
  }
  window.amplitude?.setUserId(userId);

  if (props) {
    setUserProperties(props);
  }
}

export function pushExperimentToAmplitude(name: string, variant: string): void {
  if (
    typeof window?.amplitude?.track === "function" &&
    typeof window?.amplitude?.track === "function" &&
    process.env.NODE_ENV !== "development"
  ) {
    setUserProperties({ [name]: variant });
    window.amplitude.track(`Experiment Viewed`, { name, variant });
  }
}

function setUserProperties(
  properties: Record<string, ValidPropertyType | undefined>
) {
  if (
    typeof window?.amplitude?.identify === "function" &&
    typeof window?.amplitude?.Identify === "function" &&
    process.env.NODE_ENV !== "development"
  ) {
    const identifyEvent = new window.amplitude.Identify();

    for (const property in properties) {
      identifyEvent.set(property, properties[property] || "");
    }

    window.amplitude.identify(identifyEvent);
  }
}

function maybeEnableAmplitudeTracking() {
  if (
    window &&
    (window?.Cookiebot?.consent?.statistics ||
      window?.axeptioSDK?.hasAcceptedVendor("amplitude")) &&
    !window.amplitudeInitialised
  ) {
    initialiseAmplitude();
  }
}

function trackPageViews(): boolean {
  return "__otta_track_page_views" in window
    ? !!window.__otta_track_page_views
    : false;
}

function initialiseAmplitude() {
  window.amplitude?.init("7ca7ecaae6dac6401059b756130282c3", {
    defaultTracking: {
      attribution: true,
      pageViews: trackPageViews(),
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
  });
  window.amplitudeInitialised = true;
}
