import Select, { ActionMeta, MultiValue } from "react-select";
import { useCallback } from "react";

import { Spacing, Text } from "@otta/design";

export interface Option {
  label: string;
  value: string;
}

interface IPasteableMultiSelectFieldProps {
  "aria-label"?: string;
  label?: string;
  disabled?: boolean;
  loading: boolean;
  values: Option[];
  options: Option[];
  onPaste?: (values: { id: string; value: string }[]) => Promise<unknown>;
  onCreate: (item: { id: string; value: string }) => Promise<void>;
  onRemove: (id: string) => Promise<unknown>;
  onRemoveAll: () => Promise<unknown>;
}

function readClipboard(): Promise<string> {
  return navigator?.clipboard?.readText() ?? Promise.resolve("");
}

export const generateEverySubsentence = (words: string[]): string[] => {
  return words.reduce<string[]>(
    (result, word, index) =>
      result.concat([
        word,
        ...Array.from(Array(words.length - index - 1).keys())
          .map(n => n + 2)
          .map(n => [...words.slice(index, index + n)].join(" ")),
      ]),
    []
  );
};

export function PasteableMultiSelectField({
  label,
  disabled,
  loading,
  options,
  onCreate,
  onRemove,
  onRemoveAll,
  onPaste,
  values,
  ...props
}: IPasteableMultiSelectFieldProps): React.ReactElement {
  const handleChange = useCallback(
    async (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
      // select an existing option
      if (actionMeta.action === "select-option") {
        const newItem = newValue.find(
          v => !values.find(o => o.value === v.value)
        );
        if (newItem) {
          await onCreate({ id: newItem.value, value: newItem.label });
        }
      }
      // remove an option by clicking on it
      else if (actionMeta.action === "remove-value") {
        if (actionMeta.removedValue.value) {
          await onRemove(actionMeta.removedValue.value);
        }
      }
      // remove the last item in the list by using the backspace key
      else if (actionMeta.action === "pop-value" && values.length > 0) {
        const [lastItem] = values.slice(-1);

        if (lastItem) {
          await onRemove(lastItem.value);
        }
      }
      // remove all items
      else if (actionMeta.action === "clear") {
        await onRemoveAll();
      }
    },
    [onCreate, onRemove, values]
  );

  const handleKeyDown = useCallback<React.KeyboardEventHandler>(
    async e => {
      const vKey = 86;

      if ((e.ctrlKey || e.metaKey) && e.keyCode === vKey) {
        e.preventDefault();

        const lowercaseOptions = options.reduce<Record<string, string>>(
          (acc, { label }) => {
            if (label) {
              acc[label.toLowerCase()] = label;
            }
            return acc;
          },
          {}
        );

        const valueSet = new Set(values.flatMap(o => o.label ?? []));
        const words = (await readClipboard()).split(/[\s,;()/]|\.(?:\s|$)/g);
        const found = generateEverySubsentence(words).flatMap(
          seq => lowercaseOptions[seq.toLowerCase()] ?? []
        );

        if (found.length > 0) {
          found.forEach(v => valueSet.add(v));
          const toSend = Array.from(valueSet);

          if (onPaste !== undefined) {
            const itemsMapped = options
              .filter(({ label }) => toSend.includes(label))
              .map(({ label, value }) => ({ id: value, value: label }));

            onPaste(itemsMapped);
          }
        }
      }
    },
    [onCreate, onPaste, options, values]
  );

  return (
    <Spacing size={-6}>
      {label && (
        <Text size={-1} bold>
          {label}
        </Text>
      )}

      <Select
        isMulti
        isDisabled={disabled}
        isLoading={loading}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        options={options}
        value={values}
        {...props}
      />
    </Spacing>
  );
}
