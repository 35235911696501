import { NumericFormat } from "react-number-format";

import { Field, IFieldProps } from ".";

import { Input } from "@toolbox/components/Input";

interface IDecimalFieldProps extends Omit<IFieldProps, "children" | "type"> {
  decimalPlaces: number;
}

export function DecimalField({
  decimalPlaces,
  ...props
}: IDecimalFieldProps): React.ReactElement {
  return (
    <Field {...props}>
      {renderProps => (
        <NumericFormat
          {...renderProps}
          decimalScale={decimalPlaces}
          customInput={Input}
          allowNegative={false}
          type="text"
        />
      )}
    </Field>
  );
}
