import styled from "@xstyled/styled-components";
import { useCallback, useState } from "react";
import {
  ApolloQueryResult,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";

import { FieldWrapper } from "../FieldWrapper";

import { handleMutationError } from "@toolbox/utils/error";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { Delete } from "@toolbox/components/Icons/Delete";

interface IIndividualBulletItemProps {
  id: string;
  value?: string | null;
  updateMutation: TypedDocumentNode<unknown, { id: string; value: string }>;
  deleteMutation: TypedDocumentNode<unknown, { id: string }>;
  refetchAll: () => Promise<ApolloQueryResult<unknown>>;
}

const DeleteWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export function IndividualBulletItem({
  id,
  updateMutation,
  deleteMutation,
  value,
  refetchAll,
}: IIndividualBulletItemProps): React.ReactElement {
  const [newValue, setNewValue] = useState(value ?? "");

  const [updateBullet] = useMutation(updateMutation, {
    onError: handleMutationError,
  });

  const [deleteBullet] = useMutation(deleteMutation, {
    variables: { id },
    onError: handleMutationError,
  });

  const handleBlur = useCallback(() => {
    if (newValue !== value) {
      updateBullet({
        variables: { value: newValue, id },
      });
    }
  }, [id, newValue, updateBullet, value]);

  const handleClick = useCallback(async () => {
    await deleteBullet();
    await refetchAll();
  }, [deleteBullet, refetchAll]);

  return (
    <FieldWrapper>
      <Textarea
        margin={false}
        value={newValue}
        onBlur={handleBlur}
        style={{ overflow: "hidden" }}
        onChange={e => setNewValue(e.target.value)}
      />

      <DeleteWrapper onClick={handleClick}>
        <Delete />
      </DeleteWrapper>
    </FieldWrapper>
  );
}
