import { useEffect, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { Modal as BaseModal, Button, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { pushAnalyticsEvent } from "@otta/analytics";

const LOCAL_STORAGE_KEY_WAVE2 = "-otta-rebranding-modal-dismissed-wave2";

const Modal = styled(BaseModal)`
  max-width: 648;
  width: calc(
    100% - ${pxToRem(48)}
  ) !important; /* The stylesheet of shared-components is after the one of design, so we need to use !important to override the default width */
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://static.otta.com/images/search-app/modal-rebranding-bg.webp");
  background-size: cover;
  background-position: center;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: xl;
  height: 303;
  padding: 56 lg xxl;

  ${up(
    "tablet",
    css`
      height: 312;
      padding: 40 104;
    `
  )}
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: lg;
  right: lg;
  font-size: 24;
  cursor: pointer;
`;

const Title = styled.img`
  width: 100%;
  height: auto;
`;

export function RebrandingModal({
  userType,
}: {
  userType: "Candidate" | "Company Recruiter";
}) {
  const shouldShowModal =
    typeof window !== "undefined" &&
    !localStorage.getItem(LOCAL_STORAGE_KEY_WAVE2);

  const localStorageKey = LOCAL_STORAGE_KEY_WAVE2;
  const [showModal, setShowModal] = useState(shouldShowModal);

  useEffect(() => {
    if (!shouldShowModal) return;

    localStorage.setItem(localStorageKey, "true");
    pushAnalyticsEvent({
      eventName: `${userType} Viewed Wave 1 Modal`,
      pathname: window.location.pathname,
    });
  }, [localStorageKey, shouldShowModal, userType]);

  if (!showModal) return null;

  const handleDismiss = () => {
    localStorage.setItem(localStorageKey, "true");
    setShowModal(false);
  };

  const url =
    userType === "Company Recruiter"
      ? "https://employers.welcometothejungle.com/en/introducing-our-new-brand"
      : "https://uk.welcometothejungle.com/introducing-our-new-brand";

  return (
    <Modal open dismissable={false}>
      <BG />
      <Container>
        <CloseButton
          onClick={() => {
            pushAnalyticsEvent({
              eventName: `${userType} Clicked`,
              name: "wave-2-modal-dismiss",
              pathname: window.location.pathname,
            });
            handleDismiss();
          }}
        >
          <Icon icon="close" />
        </CloseButton>
        <Title
          src="https://static.otta.com/images/search-app/otta-is-wttj.png"
          alt="Otta is now Welcome to the Jungle"
        />
        <Text align="center" size={1}>
          New name, bolder vision, with everything you already love.
        </Text>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Button
            level="secondary"
            onClick={() => {
              pushAnalyticsEvent({
                eventName: `${userType} Clicked`,
                name: "wave-2-modal-find-out-more",
                pathname: window.location.pathname,
              });
              handleDismiss();
            }}
          >
            Find out more
          </Button>
        </a>
      </Container>
    </Modal>
  );
}
