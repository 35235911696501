import styled from "@xstyled/styled-components";

export const DefaultCheckmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;
