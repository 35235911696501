import styled from "@xstyled/styled-components";

import { modularScale, palette } from "@otta/design-tokens";

export const Label = styled.label<{
  disabled?: boolean;
}>`
  font-weight: 600;
  font-size: ${modularScale()};
  color: ${({ disabled }) =>
    disabled ? palette.grayscale.shade400 : palette.brand.black};
`;
