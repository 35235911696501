import { useCallback, useState } from "react";
import { useMutation, TypedDocumentNode } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { Checkbox } from "@toolbox/components/Input/Checkbox";

const CheckboxContainer = styled.div`
  display: inline-block;
  margin-top: sm;
  margin-bottom: sm;
`;
interface IFieldProps<F extends string> {
  label: string;
  value?: boolean | null;
  mutation: TypedDocumentNode<
    unknown,
    { id: string } & { input: { [key in F]?: boolean | null } }
  >;
  fieldName: F;
  id: string;
  autoSet?: boolean;
}

export function CheckboxField<F extends string>({
  id,
  label,
  value,
  fieldName,
  mutation,
}: IFieldProps<F>): React.ReactElement {
  const [checked, setChecked] = useState(!!value);

  const [mutate] = useMutation(mutation, {
    variables: {
      id,
      input: {
        [fieldName]: !checked,
      } as { [f in F]: boolean },
    },
    onError: e => {
      window.alert(e);
      setChecked(!!value);
    },
  });

  const handleChange = useCallback(() => {
    setChecked(previousState => !previousState);
    mutate();
  }, [mutate]);

  return (
    <CheckboxContainer>
      <Checkbox
        label={label}
        onChange={handleChange}
        checked={checked}
        data-testid="checkbox-input-component"
      />
    </CheckboxContainer>
  );
}
