import { keyframes } from "@xstyled/styled-components";

export const floatUp = keyframes`
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(-6%);
    }`;

export const pressDown = keyframes`
    from {
        transform: translateY(-6%);
    }
    to {
        transform: translateY(12%);
    }`;
