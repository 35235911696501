export const baseFontSize = 16;

type TypographyLevel = "body" | "heading";

interface FontFamilyDefinition {
  name: string;
  src: {
    weight: number | string;
    sources: { format: string; url: string }[];
  }[];
}

export const family: Record<TypographyLevel, FontFamilyDefinition> = {
  body: {
    name: "'Work Sans'",
    src: [
      {
        weight: "100 900",
        sources: [
          {
            format: "woff2",
            url: "https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2",
          },
        ],
      },
    ],
  },
  heading: {
    name: "'Welcome Web'",
    src: [
      {
        weight: 600,
        sources: [
          {
            format: "woff2",
            url: "https://static.otta.com/fonts/WelcomeWeb-Bold.woff2",
          },
          {
            format: "woff",
            url: "https://static.otta.com/fonts/WelcomeWeb-Bold.woff",
          },
          {
            format: "truetype",
            url: "https://static.otta.com/fonts/WelcomeWeb-Bold.ttf",
          },
        ],
      },
    ],
  },
};
