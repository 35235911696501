import styled from "@xstyled/styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { palette } from "@otta/design-tokens";

export const DropdownItem = styled(DropdownMenu.Item)`
  padding: 11 lg;
  cursor: pointer;
  &:hover {
    background: ${palette.brand.grey};
  }
  &:first-child {
    border-radius: 4 4 0 0;
  }
  &:last-child {
    border-radius: 0 0 4 4;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${palette.grayscale.shade50};
  }
`;
