import styled from "@xstyled/styled-components";

import { Field, IFieldProps } from ".";

import { Input } from "@toolbox/components/Input";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    padding-left: 4px;
  }
`;

const Square = styled.span<{ colour: string }>`
  width: 24px;
  height: 24px;
  background-color: ${({ colour }) => colour};
`;

function ColourFieldComponent({
  value,
  ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> & {
  value?: string;
}) {
  return (
    <Wrapper>
      <Input value={value} {...props} />
      {value && <Square colour={value} />}
    </Wrapper>
  );
}

export function ColourField(
  props: Omit<IFieldProps<HTMLInputElement>, "children">
): React.ReactElement {
  return (
    <Field {...props}>
      {renderProps => <ColourFieldComponent {...renderProps} />}
    </Field>
  );
}
