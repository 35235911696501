import { useMemo } from "react";
import type { OptionProps } from "react-select";
import Select from "react-select";
import { WindowedMenuList } from "react-windowed-select";
import styled from "@xstyled/styled-components";

import { Label, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { useQuery } from "@toolbox/apollo";
import { CompaniesDocument, CompanyWorkflowStatus } from "@toolbox/schema";

const StyledOption = styled.div<{ $selected: boolean }>`
  padding: 10px;
  background-color: ${({ $selected }) =>
    $selected ? palette.extended.yellow.shade100 : palette.brand.white};
  cursor: pointer;
  &:hover {
    background-color: gray-100;
  }
`;

function Option({
  data,
  selectOption,
  isSelected,
}: OptionProps<{ label: string; value: string; url: string }, false>) {
  return (
    <StyledOption onClick={() => selectOption(data)} $selected={isSelected}>
      <Text>
        {data.value}{" "}
        <Text as="span" bold>
          {data.label}
        </Text>{" "}
        {data.url}
      </Text>
    </StyledOption>
  );
}

interface CompanySelectProps {
  label?: string;
  statuses?: CompanyWorkflowStatus[];
  value: string | null;
  onChange(value: string | null): void;
}

export function CompanySelect({
  label,
  statuses,
  value,
  onChange,
}: CompanySelectProps) {
  const { data, loading } = useQuery(CompaniesDocument, {
    variables: {
      workflowStatuses: statuses,
    },
  });

  const options = useMemo(
    () =>
      data?.companies.map(c => {
        const url = c.websiteUrl
          ? c.websiteUrl
              .replace(/(http(s?))?(:\/\/)?(www.)?/, "")
              .replace(/\/(.*)/, "")
              .replace(/\?(.*)/, "")
          : "";

        return {
          label: c.name,
          value: c.id,
          url,
        };
      }) ?? [],
    [data?.companies]
  );

  return (
    <div style={{ minWidth: 300 }}>
      {label && <Label>Company</Label>}
      <Select
        onChange={v => onChange(v?.value ?? null)}
        components={{ Option, MenuList: WindowedMenuList }}
        options={options}
        value={options.find(o => o.value === value) ?? null}
        placeholder="Select company..."
        isLoading={loading}
      />
    </div>
  );
}
