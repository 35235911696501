import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";

export interface MiddleProps {
  maxWidth?: string | number;
  textAlign?: "inherit" | "center" | "left" | "right";
}

const computeMaxWidth = (maxWidth?: string | number): string => {
  if (typeof maxWidth === "number") {
    return pxToRem(maxWidth);
  }

  if (typeof maxWidth === "string") {
    return maxWidth;
  }

  return "none";
};
/**
 * Middle is used to render content with a max width in the center of the screen.
 *
 * ```ts
 *
 * import { Middle } from '@otta/design'
 *
 * ```
 */
export const Middle = styled.div<MiddleProps>`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ maxWidth }) => computeMaxWidth(maxWidth)};
  text-align: ${({ textAlign = "center" }) => textAlign};
`;
