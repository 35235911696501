import styled from "@xstyled/styled-components";

import { Spacing } from "../Spacing";

const StyledContainer = styled.div`
  text-align: left;
`;

interface FieldContainerProps {
  children: React.ReactNode;
}

export function FieldContainer({
  children,
}: FieldContainerProps): React.ReactElement {
  return (
    <StyledContainer>
      <Spacing size={-5}>{children}</Spacing>
    </StyledContainer>
  );
}
