import { useMemo, useState } from "react";
import styled, { css } from "@xstyled/styled-components";

import { FieldLabel } from "../FieldLabel";

import { Spacing, Text, VerticalSpacing } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { Arrow as DefaultArrow } from "@toolbox/components/Icons/Arrow";
import { Checkbox } from "@toolbox/components/Input/Checkbox";
import { Location } from "@toolbox/schema";

export interface ILocationPreference {
  location: Location;
}

interface ILocationPreferencesFieldProps {
  scrapedLocation?: string | null;
  locationPreferences: ILocationPreference[];
  autoSet?: boolean;
  autoSetLocations?: Set<string>;
  label: string;
  handleUpdate: (preferences: { location: Location }[]) => void;
}

const europe: [string, Location][] = [
  ["Amsterdam", Location.Amsterdam],
  ["Barcelona", Location.Barcelona],
  ["Berlin", Location.Berlin],
  ["Dublin", Location.Dublin],
  ["London", Location.London],
  ["Paris", Location.Paris],
];

const remoteEurope: [string, Location][] = [
  ["Fully remote within Netherlands", Location.RemoteNetherlands],
  ["Fully remote within Spain", Location.RemoteSpain],
  ["Fully remote within Germany", Location.RemoteGermany],
  ["Fully remote within Ireland", Location.RemoteIreland],
  ["Fully remote within the UK", Location.RemoteUk],
  ["Fully remote within France", Location.RemoteFrance],
  ["Fully remote within the whole EU", Location.RemoteEurope],
];

const us: [string, Location][] = [
  ["Austin", Location.AustinUs],
  ["Boston", Location.BostonUs],
  ["Chicago", Location.ChicagoUs],
  ["Denver", Location.DenverUs],
  ["Los Angeles", Location.LosAngelesUs],
  ["Miami", Location.MiamiUs],
  ["New York", Location.NewYorkUs],
  ["San Francisco (Bay Area)", Location.SanFranciscoBayAreaUs],
  ["Fully remote within the US", Location.RemoteUs],
];

const canada: [string, Location][] = [
  ["Toronto", Location.Toronto],
  ["Vancouver", Location.Vancouver],
  ["Fully remote within Canada", Location.RemoteCanada],
];

const locationGroups: [string, [string, Location][]][] = [
  ["Europe", europe],
  ["", remoteEurope],
  ["United States", us],
  ["Canada", canada],
];

const allLocations: [string, Location][] = locationGroups.flatMap(
  ([, group]) => group
);

const LocationGroupLabel = styled(FieldLabel)`
  & > * {
    font-weight: normal;
  }
`;

const LocationGroups = styled(VerticalSpacing)`
  margin-top: lg;
`;

export const Grid = styled.div<{ $border?: boolean }>`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: sm;
  ${({ $border }) =>
    $border &&
    css`
      margin-top: sm;
      padding-top: lg;
      border-top: 1px solid ${palette.grayscale.shade400};
    `}
`;

const Arrow = styled(DefaultArrow)`
  width: ${modularScale()};
  height: ${modularScale()};
  cursor: pointer;
`;

const ScrapedLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 6;
  padding-left: 6;
  padding-bottom: 6;
`;

const ScrapedLocationWrapper = styled.div<{ hideOverflow: boolean }>`
  display: -webkit-box;
  margin: 0;
  text-align: left;

  ${({ hideOverflow }) =>
    hideOverflow &&
    css`
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    `}
`;

const ArrowWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
`;

const AutoSetCheckbox = styled(Checkbox)<{ autoSet?: boolean }>`
  input:checked ~ span {
    background-color: ${({ autoSet }) =>
      autoSet ? palette.extended.green.shade400 : palette.brand.yellow};
  }
`;

interface IScrapedLocationInformationProps {
  scrapedLocation?: string | null;
}

function ScrapedLocationInformation({
  scrapedLocation,
}: IScrapedLocationInformationProps): React.ReactElement | null {
  const [expand, setExpand] = useState(false);

  if (scrapedLocation) {
    const longLocation = scrapedLocation.length > 90;

    const hideOverflow = longLocation && !expand;

    return (
      <ScrapedLocationContainer>
        <ScrapedLocationWrapper hideOverflow={hideOverflow}>
          <Text size={-1}>{`Scraped location(s): ${scrapedLocation}`}</Text>
        </ScrapedLocationWrapper>
        {longLocation && (
          <ArrowWrapper
            onClick={() => setExpand(!expand)}
            data-testid={expand ? "expansion-arrow-rotated" : "expansion-arrow"}
          >
            <Arrow
              colour={palette.brand.black}
              transform={expand ? "rotate(180deg)" : "rotate(0deg)"}
            />
          </ArrowWrapper>
        )}
      </ScrapedLocationContainer>
    );
  } else {
    return null;
  }
}

export function LocationPreferencesField({
  scrapedLocation,
  locationPreferences,
  label,
  autoSet,
  autoSetLocations,
  handleUpdate,
}: ILocationPreferencesFieldProps): React.ReactElement {
  const preferences = useMemo(
    () =>
      allLocations.reduce<Record<string, boolean>>((acc, [label, value]) => {
        acc[label] = locationPreferences.some(pref => pref.location === value);
        return acc;
      }, {}),
    [locationPreferences]
  );

  const handleChange =
    (location: Location) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const update = allLocations
        .filter(([label, value]) =>
          location === value ? e.target.checked : preferences[label]
        )
        .map(([, value]) => ({ location: value }));

      handleUpdate(update);
    };
  return (
    <Spacing>
      <FieldLabel
        divElement
        text={label}
        autoSet={autoSet}
        extraInfo={
          <ScrapedLocationInformation scrapedLocation={scrapedLocation} />
        }
      >
        <LocationGroups>
          {locationGroups.map(([name, locs]) => (
            <LocationGroupLabel key={name} divElement text={name}>
              <Grid $border>
                {locs.map(([label, value]) => (
                  <AutoSetCheckbox
                    key={value}
                    checked={preferences[label]}
                    onChange={handleChange(value)}
                    autoSet={autoSetLocations?.has(value)}
                    labelStyle={
                      value === Location.RemoteEurope
                        ? { gridColumnStart: 3 }
                        : undefined
                    }
                    label={label}
                    fontSize={12}
                    style={{ background: "black" }}
                  />
                ))}
              </Grid>
            </LocationGroupLabel>
          ))}
        </LocationGroups>
      </FieldLabel>
    </Spacing>
  );
}
