import { FieldContainer } from "./FieldContainer";
import { Input } from "./Input";
import { ErrorText } from "./ErrorText";

import { Label } from "@otta/design";
export interface IInputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
}

/**
 * ```ts
 *
 * import { InputField } from '@otta/design'
 *
 * ```
 */

export function InputField({
  label,
  name,
  type,
  error,
  placeholder,
  required,
  ...props
}: IInputFieldProps): React.ReactElement {
  return (
    <FieldContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        error={!!error}
        {...props}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </FieldContainer>
  );
}
