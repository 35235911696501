import styled from "@xstyled/styled-components";

import { Checkbox } from "./Checkbox";

const InnerCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;

interface FilterSubOptionProps {
  parentValue: string;
  value: string;
  label: string;
  checked: boolean;
  handleSubOptionSelect: (value: string, parentValue: string) => void;
}

export function FilterSubOption({
  parentValue,
  value,
  label,
  checked,
  handleSubOptionSelect,
}: FilterSubOptionProps): React.ReactElement {
  return (
    <InnerCheckbox
      id={value}
      label={label}
      checked={checked}
      onChange={() => handleSubOptionSelect(value, parentValue)}
    />
  );
}
