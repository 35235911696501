import { Redirect } from "@toolbox/router";
import { useQuery } from "@toolbox/apollo";
import { CurrentUserDocument } from "@toolbox/schema";

export function SignedOutRoute({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement | null {
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return null;
  }

  const user = data?.me;

  if (!user) {
    return <>{children}</>;
  }

  return <Redirect to="/" />;
}
