import styled from "@xstyled/styled-components";

import {} from "@otta/design";
import { BulkJobs } from "./BulkJobs";

const Wrapper = styled.div`
  padding: lg 0;
`;

export function JobsList(): React.ReactElement {
  return (
    <Wrapper key="bulk">
      <BulkJobs />
    </Wrapper>
  );
}
