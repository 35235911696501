import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ColumnContainer,
  AssetContainer,
  FormContainer,
  AssetButton,
  Images,
  Placeholder,
} from "../assets-utils";

import { LinkForm } from "./LinkForm";

import { Spacing, Text } from "@otta/design";
import {
  CompanyBrandAssetsDocument,
  CompanyBrandAssetsQuery,
  BrandAssetType,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";
import { Loading } from "@otta/shared-components";

type Company = NonNullable<CompanyBrandAssetsQuery["company"]>;

export function Assets(): React.ReactElement {
  const { companyId } = useParams();

  const [assetId, setAssetId] = useState<string | null>();
  const { data, loading } = useQuery(CompanyBrandAssetsDocument, {
    variables: { id: companyId },
  });
  const asset = useMemo(
    () => data?.company?.brandAssets.find(i => i.id === assetId),
    [data, assetId]
  );

  if (!companyId) {
    return <>No company found</>;
  }

  if (loading || !data?.company) {
    return <Loading />;
  }

  return (
    <ColumnContainer>
      <AssetContainer>
        <Images>
          {data?.company?.brandAssets.map((ba, i) => (
            <AssetButton
              onClick={() => setAssetId(ba.id)}
              current={assetId === ba.id}
              key={i}
            >
              {ba.type === BrandAssetType.Image ? (
                <img
                  srcSet={ba.sourceSet ?? undefined}
                  src={ba.source ?? undefined}
                  alt={ba.alt ?? undefined}
                />
              ) : (
                <Placeholder>
                  <Text size={1}>
                    <span role="img" aria-label="video">
                      🎬
                    </span>
                  </Text>
                  <Text size={0}>{ba.source}</Text>
                </Placeholder>
              )}
            </AssetButton>
          ))}
        </Images>
      </AssetContainer>
      <FormContainer>
        {!asset && (
          <Spacing size={2}>
            <Text bold size={2}>
              Linking
            </Text>
            <Text size={1}>Select an asset to add or edit linking</Text>
          </Spacing>
        )}
        {!!asset && (
          <Spacing size={1}>
            {asset?.links.map(link => (
              <LinkForm
                parent={link}
                company={data.company as Company}
                placements={data.allBrandAssetPlacements ?? []}
                regions={data.regions}
                key={link.id}
              />
            ))}
            <LinkForm
              key={`new-asset-link-${asset.id}`}
              placements={data.allBrandAssetPlacements ?? []}
              company={data.company as Company}
              regions={data.regions}
              parent={asset}
            />
          </Spacing>
        )}
      </FormContainer>
    </ColumnContainer>
  );
}
