import { useState } from "react";
import styled from "@xstyled/styled-components";

import Chevron from "./Chevron";
import { Checkbox } from "./Checkbox";
import { Selected, SubOption } from "./Filter";
import { FilterSubOption } from "./FilterSubOption";

import { modularScale, pxToRem } from "@otta/design-tokens";

const OuterCheckbox = styled(Checkbox)<{ intermediate?: boolean }>`
  ${({ intermediate }) =>
    intermediate &&
    `
    input ~ span {
      background-color: yellow-500;
    }
    span:after {      
      display: block;
      left: ${modularScale(-8)};
      top: ${modularScale(-3)};
      width: ${modularScale(-1.5)};
      height: ${modularScale(-3)};
      border-width: ${pxToRem(2)} 0 0 0;
      transform: none;
    }
  `}
`;

const ChildWrapper = styled.div<{ isExpanded: boolean }>`
  margin: 10px 0px 10px 30px;
  display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
`;

const FunctionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterItemContainer = styled.div`
  margin-bottom: 10px;
  display: block;
  overflow: hidden;
`;

interface FilterOptionProps {
  parentValue: string;
  label: string;
  subOptions?: SubOption[];
  selectedOptions: Selected;
  handleOptionSelect: (value: string) => void;
  handleSubOptionSelect: (value: string, parentValue: string) => void;
}
export function FilterOption({
  parentValue,
  label,
  subOptions,
  selectedOptions,
  handleOptionSelect,
  handleSubOptionSelect,
}: FilterOptionProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <FilterItemContainer key={parentValue} data-testid={parentValue}>
      <FunctionWrapper>
        <span data-testid="chevron" onClick={() => setIsExpanded(!isExpanded)}>
          <Chevron isOpen={isExpanded} />
        </span>
        <OuterCheckbox
          label={label}
          id={parentValue}
          onChange={() => handleOptionSelect(parentValue)}
          checked={selectedOptions && parentValue in selectedOptions}
          intermediate={
            subOptions &&
            selectedOptions[parentValue] &&
            subOptions.length != selectedOptions[parentValue].length &&
            selectedOptions[parentValue].length > 0
          }
        />
      </FunctionWrapper>
      <ChildWrapper isExpanded={isExpanded}>
        {!!subOptions &&
          subOptions.map(({ value, label }) => (
            <FilterSubOption
              parentValue={parentValue}
              key={value}
              value={value}
              label={label}
              checked={selectedOptions[parentValue]?.includes(value) ?? false}
              handleSubOptionSelect={handleSubOptionSelect}
            />
          ))}
      </ChildWrapper>
    </FilterItemContainer>
  );
}
