import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import { Input, Button } from "@otta/design";
import { CompaniesIndexDocument, CreateCompanyDocument } from "@toolbox/schema";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: md;
  padding: md;
`;

export function CreateCompanyField(): React.ReactElement {
  const [value, setValue] = useState("");

  const navigate = useNavigate();

  const [createMutation] = useMutation(CreateCompanyDocument, {
    refetchQueries: [CompaniesIndexDocument],
  });

  const handleCreate = useCallback(async () => {
    const { data } = await createMutation({ variables: { name: value } });

    if (data?.createCompany?.id) {
      setValue("");

      navigate(`/companies/approved/${data.createCompany.id}`, {
        replace: true,
      });
    }
  }, [createMutation, navigate, value]);

  return (
    <Wrapper>
      <Input
        placeholder="Company Name"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Button level="tertiary" onClick={handleCreate}>
        Create company
      </Button>
    </Wrapper>
  );
}
