import { PendingSurveyCard } from "./PendingSurveyCard";

import { useQuery } from "@toolbox/apollo";
import { CompanyEmployeeSurveysPendingApprovalDocument } from "@toolbox/schema";

export const PendingSurveyList: React.FC = () => {
  const { data } = useQuery(CompanyEmployeeSurveysPendingApprovalDocument, {
    variables: { limit: 50, offset: 0 },
  });

  const surveys = data?.companyEmployeeSurveysPendingApproval;

  if (!surveys) {
    return null;
  }

  const surveysFiltered = surveys.filter(survey => survey.isApproved === null);

  return (
    <div>
      {surveysFiltered.map(survey => (
        <PendingSurveyCard key={survey.id} survey={survey} />
      ))}
    </div>
  );
};
