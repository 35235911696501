import ReactSelect, {
  ControlProps,
  createFilter,
  CSSObjectWithLabel,
  Props as ReactSelectProps,
} from "react-select";
import { WindowedMenuList } from "react-windowed-select";

import { modularScale, palette, pxToRem } from "@otta/design-tokens";

interface IOption<V> {
  value: V;
  label: string;
}

type StyleFunction<C, P> = (base: C, props: P) => C;

function composeStyles<C, P>(
  ...fns: (StyleFunction<C, P> | undefined)[]
): StyleFunction<C, P> {
  return (base, props) =>
    fns.reduce<C>((acc, fn) => (fn ? fn(acc, props) : acc), base);
}
interface ISelectProps<V, M extends boolean = false>
  extends ReactSelectProps<IOption<V>, M> {
  relativePosition?: boolean;
}

type AriaInvalidProp = {
  "aria-invalid"?: ISelectProps<unknown, boolean>["aria-invalid"];
};

/**
 * Restyle the border of the select control based on props / state
 * We can't use a CSS attr selector as we're not styling the input itself
 */
function controlBorderStyles<A>(
  { "aria-invalid": invalid }: AriaInvalidProp,
  { isFocused }: ControlProps<A>
): CSSObjectWithLabel {
  if (invalid && invalid !== "false") {
    return {
      border: `2px solid ${palette.brand.red}`,
      margin: "-1px",
    };
  } else if (isFocused) {
    return {
      border: `2px solid ${palette.brand.black}`,
      margin: "-1px",
    };
  } else {
    return {};
  }
}

/**
 *
 * ```tsx
 *
 * import { SelectField } from '@otta/design';
 *
 * ```
 */

export function SelectField<V, M extends boolean = false>({
  relativePosition,
  styles,
  ...props
}: ISelectProps<V, M>): React.ReactElement {
  return (
    <ReactSelect
      styles={{
        ...styles,
        groupHeading: composeStyles(styles?.groupHeading, base => ({
          ...base,
          color: palette.brand.black,
          fontSize: modularScale(-1),
          textTransform: "none",
          fontWeight: "bold",
        })),
        menu: composeStyles(styles?.menu, base =>
          relativePosition ? { ...base, position: "relative" } : base
        ),
        multiValue: composeStyles(styles?.multiValue, base => ({
          ...base,
          borderRadius: pxToRem(4),
          border: `${pxToRem(1)} solid ${palette.grayscale.shade400}`,
        })),
        multiValueRemove: composeStyles(styles?.multiValueRemove, base => ({
          ...base,
          borderRadius: pxToRem(4),
          cursor: "pointer",
        })),
        multiValueLabel: composeStyles(styles?.multiValueLabel, base => ({
          ...base,
          background: "white",
          padding: `${pxToRem(4)} ${pxToRem(12)}`,
          borderRadius: `${pxToRem(4)} 0 0 ${pxToRem(4)}`,
        })),
        valueContainer: composeStyles(styles?.valueContainer, base => ({
          ...base,
          padding: `${pxToRem(4)} ${pxToRem(12)}`,
        })),
        control: composeStyles(styles?.control, (base, state) => ({
          ...base,
          boxShadow: "none",
          transition: "none",
          ...controlBorderStyles(props, state),
          backgroundColor: "white",
        })),
        option: composeStyles(styles?.option, (base, state) => ({
          ...base,
          color: palette.brand.black,
          fontWeight: state.isSelected ? "bold" : base.fontWeight,
          backgroundColor: state.isSelected
            ? palette.extended.yellow.shade100
            : base.backgroundColor,
        })),
      }}
      theme={originalTheme => ({
        ...originalTheme,
        borderRadius: 4,
        colors: {
          ...originalTheme.colors,
          primary: palette.brand.black,
          primary25: palette.brand.grey,
          danger: palette.brand.white,
          dangerLight: palette.grayscale.shade600,
        },
      })}
      components={{ MenuList: WindowedMenuList, ...props.components }}
      filterOption={createFilter({ ignoreAccents: false })}
      {...props}
    />
  );
}
