import styled from "@xstyled/styled-components";
import {
  useParams,
  NavLink as RouterNavLink,
  useLocation,
  Outlet,
} from "react-router-dom";

import { theme, Text, Spacing, Tipbox } from "@otta/design";
import { useQuery } from "@toolbox/apollo";
import { CompanyDocument } from "@toolbox/schema";
import { Loading } from "@otta/shared-components";
import { palette, pxToRem } from "@otta/design-tokens";

const Page = styled.div`
  padding: 2rem;
`;

const EBTabs = styled.nav`
  list-style: none;
  display: flex;
  flex-direction: row;
  position: static;
  gap: ${pxToRem(16)};
  top: ${pxToRem(161)};
  left: ${pxToRem(284)};
`;

const NavLink = styled(RouterNavLink)`
  color: ${palette.brand.black};
  text-decoration: none;
  font-weight: 700;
  font-size: ${pxToRem(16)};
  border-bottom: ${pxToRem(4)} solid;
  border-color: transparent;
  padding-top: ${pxToRem(12)};
  padding-bottom: ${pxToRem(12)};
  cursor: pointer;

  &.active {
    border-color: ${theme.colors["yellow-500"]};
  }

  &:not(.active):hover {
    border-color: ${theme.colors["gray-200"]};
  }
`;

function StyledNavLink({ tab, children }: { tab: string; children: string }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(tab);

  return (
    <NavLink to={tab} className={isActive ? "active" : ""}>
      {children}
    </NavLink>
  );
}

export function EmployerBranding(): React.ReactElement {
  const { companyId } = useParams();

  const { data, loading } = useQuery(CompanyDocument, {
    variables: { id: companyId || "" },
  });

  if (loading) {
    return <Loading />;
  }

  if (!companyId || !data?.company) {
    return <Tipbox level="error">Missing company ID</Tipbox>;
  }

  return (
    <Page>
      <Spacing size={4}>
        <Text bold size={4}>
          Employer Branding
        </Text>
        <EBTabs>
          <StyledNavLink tab="overview">Overview</StyledNavLink>
          <StyledNavLink tab="upload">Upload</StyledNavLink>
          <StyledNavLink tab="assets">Assets</StyledNavLink>
        </EBTabs>

        <Outlet />
      </Spacing>
    </Page>
  );
}
