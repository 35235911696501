import { format, parseISO } from "date-fns";

import { Spacing, Text } from "@otta/design";
import { useQuery } from "@toolbox/apollo";
import {
  JobFunctionDocument,
  JobSubFunctionDocument,
  JobChanges,
  CompanyChanges,
} from "@toolbox/schema";

function JobFunctionName({ id }: { id: number }) {
  const { data } = useQuery(JobFunctionDocument, {
    variables: { id: id.toString() },
  });

  return data?.jobFunction ? <div>{data.jobFunction.value}</div> : null;
}

function JobSubFunctionName({ id }: { id: number }) {
  const { data } = useQuery(JobSubFunctionDocument, {
    variables: { id: id.toString() },
  });

  return data?.jobSubFunction ? <div>{data.jobSubFunction.value}</div> : null;
}

export const parseValue = (
  fieldName: string,
  value?:
    | JobChanges.OldValue
    | JobChanges.NewValue
    | CompanyChanges.OldValue
    | CompanyChanges.NewValue
    | null
) => {
  if (!value) {
    return null;
  }

  switch (value.__typename) {
    case "BooleanValue":
      return value.booleanValue ? "True" : "False";
    case "DatetimeValue":
      return format(parseISO(value.datetimeValue), "yyyy-MM-dd");
    case "DecimalValue":
      return value.decimalValue;
    case "IntegerValue":
      if (fieldName === "function_id") {
        return <JobFunctionName id={value.integerValue} />;
      }
      if (fieldName === "sub_function_id") {
        return <JobSubFunctionName id={value.integerValue} />;
      }
      return value.integerValue;
    case "StringValue":
      return value.stringValue;
    case "StringListValue":
      return (
        <Spacing size={-6}>
          {value.values.map((val, idx) => (
            <Text size={-1} key={idx}>
              {val}
            </Text>
          ))}
        </Spacing>
      );
    default:
      return null;
  }
};
