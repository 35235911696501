import styled from "@xstyled/styled-components";

import { HandleToggleProps, JobStatuses } from "./PaginatedTable";
import { JobRow } from "./JobRow";
import { HeaderRow } from "./HeaderRow";

import { pxToRem } from "@otta/design-tokens";
import { JobsList } from "@toolbox/schema";

const TableContainer = styled.div`
  padding: sm;
  background-color: white;
  border-radius: ${pxToRem(8)};
`;

const Table = styled.table`
  border-collapse: collapse;
`;

const TableBody = styled.tbody``;

interface JobTableProps {
  jobs: JobsList.Jobs[];
  unsavedChanges: JobStatuses;
  handleToggle: ({ ids, workflowStatus }: HandleToggleProps) => void;
}

export const JobTable = ({
  jobs,
  unsavedChanges,
  handleToggle,
}: JobTableProps) => {
  return (
    <TableContainer>
      <Table>
        <HeaderRow
          jobs={jobs}
          unsavedChanges={unsavedChanges}
          handleToggle={handleToggle}
        />
        <TableBody>
          {jobs.map(job => (
            <JobRow
              key={job.id}
              job={job}
              unsavedWorkflowStatus={unsavedChanges.get(job.id) ?? null}
              handleToggle={handleToggle}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
