import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { UpdateCompanyOtherBenefitDocument } from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";

interface IBenefitLocationFieldProps {
  location?: string | null;
  id: string;
  index: number;
}

const ColumnTitle = styled(Text)`
  color: black;
  text-align: center;
  margin-top: -45 !important;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CheckboxWrapper = styled.div`
  margin: 0 0 md;
  > * {
    margin: 0;
  }
`;

const Wrapper = styled.label`
  width: ${pxToRem(40)};
  display: block;
  position: relative;
  margin: sm 0;
  cursor: pointer;
  user-select: none;
  color: yellow-500;

  input:checked ~ span {
    background-color: transparent;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: ${pxToRem(0)};
    top: ${pxToRem(0)};
    width: ${pxToRem(8)};
    height: ${pxToRem(8)};
    border-radius: 50%;
    background-color: black;
    border: ${pxToRem(6)} solid ${palette.brand.yellow};
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${pxToRem(8)};
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  border-radius: 50%;
  background-color: gray-200;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export function BenefitLocationField({
  id,
  index,
  location,
  ...props
}: IBenefitLocationFieldProps): React.ReactElement {
  const [update] = useMutation(UpdateCompanyOtherBenefitDocument, {
    onError: handleMutationError,
  });

  const handleChange = useCallback(
    (location: string | null) => {
      update({
        variables: {
          id,
          location,
        },
      });
    },
    [id, update]
  );

  return (
    <CheckboxContainer>
      <Wrapper {...props} data-testid={`benefit-location-${location}`}>
        <CheckboxWrapper>
          {index == 0 ? <ColumnTitle> All </ColumnTitle> : null}
          <Input
            data-testid="all-checkbox"
            type="checkbox"
            checked={!location}
            onChange={() => {
              handleChange(null);
            }}
          />
          <Checkmark />
        </CheckboxWrapper>
      </Wrapper>
      <Wrapper data-testid={`benefit-location-${location}`}>
        <CheckboxWrapper>
          {index == 0 ? <ColumnTitle> US </ColumnTitle> : null}
          <Input
            data-testid="us-checkbox"
            type="checkbox"
            checked={location === "US"}
            onChange={() => {
              handleChange("US");
            }}
          />
          <Checkmark />
        </CheckboxWrapper>
      </Wrapper>
      <Wrapper data-testid={`benefit-location-${location}`}>
        <CheckboxWrapper>
          {index == 0 ? <ColumnTitle> UK </ColumnTitle> : null}
          <Input
            data-testid="uk-checkbox"
            type="checkbox"
            checked={location === "UK"}
            onChange={() => {
              handleChange("UK");
            }}
          />
          <Checkmark />
        </CheckboxWrapper>
      </Wrapper>
      <Wrapper data-testid={`benefit-location-${location}`}>
        <CheckboxWrapper>
          {index == 0 ? <ColumnTitle> EU </ColumnTitle> : null}
          <Input
            data-testid="eu-checkbox"
            type="checkbox"
            checked={location === "EU"}
            onChange={() => {
              handleChange("EU");
            }}
          />
          <Checkmark />
        </CheckboxWrapper>
      </Wrapper>
      <Wrapper data-testid={`benefit-location-${location}`}>
        <CheckboxWrapper>
          {index == 0 ? <ColumnTitle> CA </ColumnTitle> : null}
          <Input
            data-testid="ca-checkbox"
            type="checkbox"
            checked={location === "CA"}
            onChange={() => {
              handleChange("CA");
            }}
          />
          <Checkmark />
        </CheckboxWrapper>
      </Wrapper>
    </CheckboxContainer>
  );
}
