import { useCallback } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

import useGraphQLFilters from "./hooks/useGraphQLFilters";
import { ButtonBar, WorkflowButton } from "./Buttons";
import { JobPage } from "./JobPage";

import { Button, ErrorText, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import {
  useNextWorkflowStatus,
  usePreviousWorkflowStatus,
} from "@toolbox/utils/workflowStatus";
import { CurrentRoleType } from "@toolbox/utils/user";
import { Redirect } from "@toolbox/router";
import { ButtonWrapper } from "@toolbox/components/Button";
import {
  NextJobDocument,
  JobWorkflowStatus,
  JobCompanyScraperTypeDocument,
  CompanyScraperType,
  Permission,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";

interface JobProps {
  workflowStatus: JobWorkflowStatus;
  permissions: readonly Permission[];
  role: CurrentRoleType;
  onComplete: () => void;
}

function Job({
  workflowStatus,
  permissions,
  role,
  onComplete,
}: JobProps): React.ReactElement | null {
  const { jobId } = useParams();
  const nextStatus = useNextWorkflowStatus(role);
  const prevStatus = usePreviousWorkflowStatus(role);

  if (!jobId) {
    return null;
  }

  return (
    <>
      <JobPage jobId={jobId} />
      <ButtonBar data-testid="job-pipeline-action-buttons">
        <ButtonWrapper>
          <Button level="secondary" onClick={onComplete}>
            Skip
          </Button>
          {((role === CurrentRoleType.InternalOperator &&
            permissions.includes(Permission.SetExceptionJobs)) ||
            role == CurrentRoleType.Admin) &&
            prevStatus && (
              <WorkflowButton
                jobId={jobId}
                level="secondary"
                status={JobWorkflowStatus.Exception}
                onComplete={onComplete}
              >
                Exception
              </WorkflowButton>
            )}
          {(workflowStatus === JobWorkflowStatus.QaExternal ||
            role === CurrentRoleType.InternalOperator) &&
            prevStatus && (
              <WorkflowButton
                jobId={jobId}
                level="secondary"
                status={prevStatus}
                onComplete={onComplete}
              >
                {role == CurrentRoleType.Admin ||
                role == CurrentRoleType.InternalOperator
                  ? "Disapprove"
                  : "Exception"}
              </WorkflowButton>
            )}
          <ArchiveButton jobId={jobId} onComplete={onComplete} />
          {nextStatus && (
            <WorkflowButton
              jobId={jobId}
              level="primary"
              status={nextStatus}
              onComplete={onComplete}
            >
              Complete
            </WorkflowButton>
          )}
        </ButtonWrapper>
      </ButtonBar>
    </>
  );
}

interface JobsProps {
  workflowStatus: JobWorkflowStatus;
}

function Jobs({ workflowStatus }: JobsProps): React.ReactElement {
  const filters = useGraphQLFilters();

  const { data, loading, error } = useQuery(NextJobDocument, {
    variables: {
      workflowStatus,
      locations: filters.locations,
      subFunctionIds: filters.subFunctionIds,
    },
    fetchPolicy: "network-only",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorText>There was an issue getting jobs!</ErrorText>;
  }

  if (!data?.nextJobToProcess && (filters.subFunctionIds ?? []).length > 0) {
    return <Text>There are no jobs remaining with the selected filters</Text>;
  }

  if (!data?.nextJobToProcess) {
    return <Text>There are no jobs remaining</Text>;
  }
  return <Redirect to={data.nextJobToProcess.id} />;
}

interface JobsPipelineProps {
  workflowStatus: JobWorkflowStatus;
  permissions: readonly Permission[];
  role: CurrentRoleType;
}

export function JobsPipeline({
  workflowStatus,
  permissions,
  role,
}: JobsPipelineProps): React.ReactElement {
  const navigate = useNavigate();

  const handleComplete = useCallback(() => {
    navigate("", { replace: true });
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Jobs workflowStatus={workflowStatus} />} />
      <Route
        path=":jobId"
        element={
          <Job
            permissions={permissions}
            workflowStatus={workflowStatus}
            onComplete={handleComplete}
            role={role}
          />
        }
      />
    </Routes>
  );
}

function ArchiveButton({
  jobId,
  onComplete,
}: {
  jobId: string;
  onComplete: () => void;
}) {
  const { data, loading } = useQuery(JobCompanyScraperTypeDocument, {
    variables: {
      id: jobId,
    },
  });

  if (
    loading ||
    !data?.job?.workflowStatus ||
    !data?.job?.company?.scraperType
  ) {
    return null;
  }

  const { workflowStatus, company } = data.job;
  if (
    company.scraperType === CompanyScraperType.Manual &&
    [JobWorkflowStatus.Changed, JobWorkflowStatus.Completed].includes(
      workflowStatus
    )
  ) {
    return (
      <WorkflowButton
        jobId={jobId}
        level="secondary"
        status={JobWorkflowStatus.Archived}
        onComplete={onComplete}
      >
        Archive
      </WorkflowButton>
    );
  } else {
    return null;
  }
}
