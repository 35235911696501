import { NumericFormat, NumericFormatProps } from "react-number-format";

import { Field, IFieldProps } from ".";

import { palette } from "@otta/design-tokens";
import { Input } from "@toolbox/components/Input";

const FormattedInput = ({
  allowNegative = false,
  ...props
}: Omit<NumericFormatProps, "decimalScale" | "customInput" | "type">) => {
  return (
    <NumericFormat
      {...props}
      type="text"
      decimalScale={0}
      customInput={Input}
      allowNegative={allowNegative}
      style={{ backgroundColor: palette.brand.white }}
    />
  );
};

export function IntegerField(
  props: Omit<IFieldProps, "children" | "transform" | "type"> & {
    allowNegative?: boolean;
    disabled?: boolean;
  }
): React.ReactElement {
  return (
    <Field {...props} transform={x => (x ? Number(x) : x)}>
      {renderProps => <FormattedInput {...renderProps} />}
    </Field>
  );
}
