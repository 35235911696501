import styled, { css } from "@xstyled/styled-components";

import { modularScale, palette } from "@otta/design-tokens";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

interface DotProps {
  size: number;
  seen: boolean;
  selected: boolean;
}

const StyledDot = styled.svg<DotProps>`
  ${({ size }) => css`
    width: ${modularScale(size)};
    height: ${modularScale(size)};
  `}
  transform: scale(${({ selected }) => (selected ? 0.8 : 0.4)});

  fill: ${({ seen, selected }) =>
    seen || selected ? palette.brand.black : palette.brand.white};
  stroke: ${({ seen, selected }) =>
    seen || selected ? palette.brand.black : palette.grayscale.shade600};
  stroke-width: 15;
  transition: default;
`;

function Dot(props: DotProps): React.ReactElement {
  return (
    <StyledDot
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 130"
      {...props}
    >
      <circle cx="60" cy="60" r="50" />
    </StyledDot>
  );
}

interface PageProgressIndicatorProps {
  total: number;
  current: number;
  progress?: number;
  size?: number;
}

/**
 *
 * ```ts
 *
 * import { PageProgressIndicator } from '@otta/design'
 *
 * ```
 */

export function PageProgressIndicator({
  current,
  total,
  progress = 0,
  size = 0,
}: PageProgressIndicatorProps): React.ReactElement {
  const clampedTotal = Math.max(0, total);
  const clampedCurrent = Math.max(1, Math.min(current, clampedTotal)) - 1;
  const clampedProgress = Math.max(
    clampedCurrent,
    Math.min(progress, clampedTotal)
  );

  return (
    <Container>
      {Array.from({ length: clampedTotal }, (_v, i) => {
        return (
          <Dot
            data-testid="page-progress-dot"
            key={i}
            size={size}
            seen={i < clampedProgress}
            selected={clampedCurrent === i}
          />
        );
      })}
    </Container>
  );
}
