import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import {
  SubmittedSalariesDocument,
  UpdateSubmittedSalaryDocument,
} from "@toolbox/schema";
import { StyledButton as Button } from "@toolbox/components/Button";

interface IApproveButtonsProps {
  id: string;
}

const ButtonWrapper = styled.div`
  > * {
    margin: 0 8px;
  }
`;

export function ApproveButtons({
  id,
}: IApproveButtonsProps): React.ReactElement {
  const [mutation, { loading }] = useMutation(UpdateSubmittedSalaryDocument, {
    update(proxy) {
      const cacheData = proxy.readQuery({
        query: SubmittedSalariesDocument,
      });

      const filtered = (cacheData?.submittedSalaries ?? []).filter(
        ({ id: cacheId }) => cacheId !== id
      );

      proxy.writeQuery({
        query: SubmittedSalariesDocument,
        data: {
          ...cacheData,
          submittedSalaries: [...filtered],
        },
      });
    },
  });

  const handleClick = (approved: boolean) => {
    mutation({ variables: { id, input: { approved } } });
  };

  return (
    <ButtonWrapper>
      <Button
        level={"primary"}
        onClick={() => handleClick(true)}
        disabled={loading}
      >
        Approve
      </Button>
      <Button
        level={"danger"}
        onClick={() => handleClick(false)}
        disabled={loading}
      >
        Disapprove
      </Button>
    </ButtonWrapper>
  );
}
