import styled from "@xstyled/styled-components";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { EditForm } from "./EditForm";
import {
  ColumnContainer,
  AssetContainer,
  FormContainer,
  Images,
  Placeholder,
  AssetButton,
} from "./assets-utils";

import { Button, Input, Spacing, Text } from "@otta/design";
import {
  CompanyBrandAssetsDocument,
  UploadCompanyBrandAssetDocument,
  UpdateCompanyBrandAssetDocument,
  BrandAssetType,
  CreateCompanyBrandAssetDocument,
} from "@toolbox/schema";
import { UploadField } from "@toolbox/components/Field/UploadField";
import { useQuery } from "@toolbox/apollo";
import { Loading } from "@otta/shared-components";

const AddFormCard = styled.div`
  display: flex;
  gap: 1rem;
  & > button {
    flex-shrink: 0;
  }
`;

function AddForm({ companyId }: { companyId: string }) {
  const [source, setSource] = useState<string | null>(null);
  const [run, { loading }] = useMutation(CreateCompanyBrandAssetDocument);

  const onClick = useCallback(async () => {
    await run({
      variables: {
        id: companyId,
        input: {
          type: BrandAssetType.Vimeo,
          source,
        },
      },
    });
    setSource(null);
  }, [source, run, setSource, companyId]);

  return (
    <AddFormCard>
      <Input
        type="text"
        value={source ?? ""}
        onChange={e => setSource(e.target.value)}
        placeholder="Vimeo ID"
      />
      <Button level="primary" onClick={() => onClick()} disabled={loading}>
        Add video
      </Button>
    </AddFormCard>
  );
}

export function Upload(): React.ReactElement {
  const { companyId } = useParams();

  const [assetId, setAssetId] = useState<string | null>();
  const { data, loading } = useQuery(CompanyBrandAssetsDocument, {
    variables: { id: companyId },
  });

  const asset = useMemo(
    () => data?.company?.brandAssets.find(i => i.id === assetId),
    [data, assetId]
  );

  if (!companyId) {
    return <>No company found</>;
  }

  if (loading || !data?.company) {
    return <Loading />;
  }

  return (
    <ColumnContainer>
      <AssetContainer>
        <Images>
          {data?.company?.brandAssets.map((ba, i) => (
            <AssetButton
              onClick={() => setAssetId(ba.id)}
              current={assetId === ba.id}
              key={i}
            >
              {ba.type === BrandAssetType.Image ? (
                <img
                  srcSet={ba.sourceSet ?? undefined}
                  src={ba.source ?? undefined}
                  alt={ba.alt ?? undefined}
                />
              ) : (
                <Placeholder>
                  <Text size={1}>
                    <span role="img" aria-label="video">
                      🎬
                    </span>
                  </Text>
                  <Text size={0}>{ba.source}</Text>
                </Placeholder>
              )}
            </AssetButton>
          ))}
        </Images>
      </AssetContainer>
      <FormContainer>
        <Spacing size={1}>
          <Text bold size={3}>
            Upload a new asset
          </Text>

          <Text bold size={1}>
            Image
          </Text>
          <UploadField
            accept={["image/jpeg"]}
            id={data.company.externalId}
            mutation={UploadCompanyBrandAssetDocument}
            display={() => null}
            value={null}
          />
          <Text bold size={1}>
            Videos
          </Text>
          <AddForm companyId={data.company.id} />
          {!!asset && (
            <Spacing size={1}>
              <Text bold size={1}>
                Add information
              </Text>
              <EditForm
                item={asset}
                key={asset.id}
                onClose={() => setAssetId(null)}
                mutation={UpdateCompanyBrandAssetDocument}
                omitFields={["source", "links", "sourceSet", "type"]}
              />
            </Spacing>
          )}
        </Spacing>
      </FormContainer>
    </ColumnContainer>
  );
}
