import { useMemo } from "react";

import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import { getAllowedPermissionsCompanyWorkflowStatuses } from "@toolbox/utils/workflowStatus";
import { CompanyWorkflowStatus } from "@toolbox/schema";

/**
 * Find out if the current user has _full_ manage permissions for companies with the given status,
 * they may also have limited access to other statuses but this hook doesn't care about that -
 * we only return true if they can change all fields + archive
 */
export function useHasFullManageCompanyPermission(
  workflowStatus?: CompanyWorkflowStatus,
  role?: CurrentRoleType
) {
  const { permissions, role: userRole } = useUser();
  const roleToUse = role ?? userRole;

  return useMemo(() => {
    const isAdmin = roleToUse === CurrentRoleType.Admin;
    const allowed = getAllowedPermissionsCompanyWorkflowStatuses(permissions);
    return isAdmin || (!!workflowStatus && allowed.includes(workflowStatus));
  }, [workflowStatus, roleToUse, permissions]);
}
