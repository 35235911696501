import Select, { ActionMeta, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useCallback } from "react";

import { generateEverySubsentence, Option } from "./PasteableMultiSelectField";

import { Spacing, Text } from "@otta/design";

interface IPasteableMultiSelectCreateFieldProps {
  label: string;
  disabled?: boolean;
  loading: boolean;
  values: Option[];
  options: Option[];
  onPaste?: (values: string[]) => Promise<unknown>;
  onCreate: (value: string) => Promise<unknown>;
  onRemove: (value: string) => Promise<unknown>;
  allowCreate: boolean;
}

function readClipboard(): Promise<string> {
  return navigator?.clipboard?.readText() ?? Promise.resolve("");
}

export function PasteableMultiSelectCreateField({
  label,
  disabled,
  loading,
  options,
  onCreate,
  onRemove,
  onPaste,
  values,
  allowCreate,
}: IPasteableMultiSelectCreateFieldProps): React.ReactElement {
  const handleChange = useCallback(
    async (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
      // create a brand new option
      if (actionMeta.action === "create-option") {
        await onCreate(actionMeta.option.label);
      }
      // select an existing option
      else if (actionMeta.action === "select-option") {
        const newItem = newValue.find(
          v => !values.find(o => o.value === v.value)
        );
        if (newItem) {
          await onCreate(newItem.label);
        }
      }
      // remove an option by clicking on it
      else if (actionMeta.action === "remove-value") {
        if (actionMeta.removedValue.value) {
          await onRemove(actionMeta.removedValue.value);
        }
      }
      // remove the last item in the list by using the backspace key
      else if (actionMeta.action === "pop-value" && values.length > 0) {
        const [lastItem] = values.slice(-1);

        if (lastItem) {
          await onRemove(lastItem.value);
        }
      }
      // remove all items
      else if (actionMeta.action === "clear") {
        for (const { value } of values) {
          await onRemove(value);
        }
      }
    },
    [onCreate, onRemove, values]
  );

  const handleKeyDown = useCallback<React.KeyboardEventHandler>(
    async e => {
      const vKey = 86;

      if ((e.ctrlKey || e.metaKey) && e.keyCode === vKey) {
        e.preventDefault();

        const lowercaseOptions = options.reduce<Record<string, string>>(
          (acc, { label }) => {
            if (label) {
              acc[label.toLowerCase()] = label;
            }
            return acc;
          },
          {}
        );

        const valueSet = new Set(values.flatMap(o => o.label ?? []));
        const words = (await readClipboard()).split(/[\s,;()/]|\.(?:\s|$)/g);
        const found = generateEverySubsentence(words).flatMap(
          seq => lowercaseOptions[seq.toLowerCase()] ?? []
        );

        if (found.length > 0) {
          found.forEach(v => valueSet.add(v));
          const toSend = Array.from(valueSet);

          if (onPaste !== undefined) {
            await onPaste(toSend);
          } else {
            for (const item of toSend) {
              await onCreate(item);
            }
          }
        }
      }
    },
    [onCreate, onPaste, options, values]
  );

  return (
    <Spacing size={-6}>
      <Text size={-1} bold>
        {label}
      </Text>
      {allowCreate ? (
        <CreatableSelect
          isMulti
          isDisabled={disabled}
          isLoading={loading}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          options={options}
          value={values}
        />
      ) : (
        <Select
          isMulti
          isDisabled={disabled}
          isLoading={loading}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          options={options}
          value={values}
        />
      )}
    </Spacing>
  );
}
