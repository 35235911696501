import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { JobTable } from "./JobTable";
import { PageHeader } from "./PageHeader";

import { Text, VerticalSpacing } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { handleMutationError } from "@toolbox/utils/error";
import {
  JobsListDocument,
  JobWorkflowStatus,
  UpdateJobWorkflowStatusBatchDocument,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";

const ROW_LIMIT = 100;
export interface HandleToggleProps {
  ids: string[];
  workflowStatus: JobWorkflowStatus;
}

export type JobStatuses = Map<string, JobWorkflowStatus>;

export const PaginatedTable = ({
  group,
  isPro,
  companyName,
  scrapedLocation,
}: {
  group?: string;
  isPro?: boolean;
  companyName?: string;
  scrapedLocation?: string;
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState<JobStatuses>(new Map());

  useEffect(() => {
    setUnsavedChanges(new Map());
  }, [group, isPro, companyName, scrapedLocation]);

  const queryVariables = {
    limit: ROW_LIMIT,
    workflowStatus: JobWorkflowStatus.PreApproval,
    acquisitionChannel: group,
    isPro: isPro,
    companyName: companyName,
    scrapedLocation: scrapedLocation,
  };

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(JobsListDocument, {
    variables: {
      ...queryVariables,
      offset: 0,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const refetchJobs = async (selectedPage: number) => {
    await refetch({
      ...queryVariables,
      offset: selectedPage * ROW_LIMIT,
    });
  };

  const jobs = data?.jobs ?? [];

  const [mutation, { loading: mutationLoading }] = useMutation(
    UpdateJobWorkflowStatusBatchDocument,
    {
      onError: handleMutationError,
    }
  );

  const updateJobs = async (workflowStatus: JobWorkflowStatus) => {
    await mutation({
      variables: {
        jobIds: Array.from(unsavedChanges.entries()).flatMap(
          ([id, statusToSave]) => (statusToSave === workflowStatus ? [id] : [])
        ),

        workflowStatus: workflowStatus,
      },
    });
  };

  const submitJobs = async () => {
    await Promise.all([
      updateJobs(JobWorkflowStatus.Disapproved),
      updateJobs(JobWorkflowStatus.QueuedExternal),
    ]);
    await refetchJobs(currentPage);
    setUnsavedChanges(new Map());
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
    refetchJobs(selected);
  };

  const handleToggle = useCallback(
    ({ ids, workflowStatus }: HandleToggleProps) => {
      setUnsavedChanges(unsavedChanges => {
        const alreadySelected = ids.every(
          id => unsavedChanges.get(id) === workflowStatus
        );

        const newMap = new Map(unsavedChanges);

        ids.forEach(id => {
          if (alreadySelected) {
            newMap.delete(id);
          } else {
            newMap.set(id, workflowStatus);
          }
        });

        return newMap;
      });
    },
    []
  );

  const disableSubmit =
    queryLoading || mutationLoading || unsavedChanges.size === 0;

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <VerticalSpacing>
      <PageHeader
        currentPage={currentPage}
        handlePageClick={handlePageClick}
        submitJobs={submitJobs}
        disableSubmit={disableSubmit}
        pageCount={data?.totalJobs ? Math.ceil(data?.totalJobs / ROW_LIMIT) : 0}
      />
      {jobs.length === 0 ? (
        <Text align="center">No jobs found!</Text>
      ) : (
        <JobTable
          jobs={jobs}
          unsavedChanges={unsavedChanges}
          handleToggle={handleToggle}
        />
      )}

      <PageHeader
        currentPage={currentPage}
        handlePageClick={handlePageClick}
        submitJobs={submitJobs}
        disableSubmit={
          queryLoading || mutationLoading || unsavedChanges.size === 0
        }
        pageCount={data?.totalJobs ? Math.ceil(data?.totalJobs / ROW_LIMIT) : 0}
      />
    </VerticalSpacing>
  );
};
