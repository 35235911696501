import { Label, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

export const FieldHeading = ({
  htmlFor,
  label,
  required = false,
  optional = false,
  disabled = false,
}: {
  htmlFor?: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div>
      <Label disabled={disabled} htmlFor={htmlFor}>
        {label}
      </Label>{" "}
      {required && (
        <Text
          inline
          color={disabled ? palette.grayscale.shade400 : palette.brand.red}
        >
          *
        </Text>
      )}
      {optional && (
        <Text
          inline
          color={
            disabled ? palette.grayscale.shade400 : palette.grayscale.shade600
          }
          style={{ fontStyle: "italic" }}
        >
          optional
        </Text>
      )}
    </div>
  );
};
