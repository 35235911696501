import { Button, VerticalSpacing } from "@otta/design";
import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import { Redirect } from "@toolbox/router";

export function LoggedInRoute({
  allowedRoles = [
    CurrentRoleType.Admin,
    CurrentRoleType.Candidate,
    CurrentRoleType.CompanyRecruiter,
    CurrentRoleType.ExternalResearcher,
    CurrentRoleType.InternalOperator,
  ],
  allowedPermissions = [],
  children,
}: {
  allowedRoles?: CurrentRoleType[];
  allowedPermissions?: string[];
  children: React.ReactNode;
}): React.ReactElement | null {
  const { user, role, permissions, loading } = useUser();

  if (loading) {
    return null;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  const hasCorrectRole = role && allowedRoles.includes(role);
  const hasCorrectPermissions =
    allowedPermissions.filter(p => {
      return (permissions as string[]).indexOf(p) !== -1;
    }).length > 0;

  if (hasCorrectRole || hasCorrectPermissions) {
    return <>{children}</>;
  }

  return (
    <VerticalSpacing>
      <p>You do not have permission to view this page</p>
      <Button as="a" href="/logout" level="primary">
        Logout
      </Button>
    </VerticalSpacing>
  );
}
