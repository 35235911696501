import { forwardRef, useCallback, useMemo } from "react";
import styled from "@xstyled/styled-components";

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

interface SourceGenerator {
  (width: number): string;
}

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  widths: number[];
  src: SourceGenerator;
  sizes?: string[];
  alt?: string;
  sources?: Record<string, SourceGenerator>;
}
/**
 * Renders a responsive image using multiple sources.
 *
 * ** Note: **
 *
 * You must specify the most supported file type **<u>last</u>** in the `src` prop. This ensures the widest browser support.
 *
 * ```tsx
 *
 * import { ResponsiveImage } from '@otta/design';
 *
 * ```
 */

export const ResponsiveImage = forwardRef<HTMLImageElement, Props>(
  function ResponsiveImage(
    { className, style, widths, alt, sizes, src, sources },
    ref
  ) {
    const maxWidth = Math.max(...widths);

    const htmlSizes = useMemo(() => sizes?.join(", "), [sizes]);

    const sortedWidths = useMemo(
      () => Array.from(widths).sort((a, b) => a - b),
      [widths]
    );

    const createSrcSet = useCallback(
      (generator: SourceGenerator) => {
        return sortedWidths
          .map(width => `${generator(width)} ${width}w`)
          .join(", ");
      },
      [sortedWidths]
    );

    const FallbackImage = (
      <Image
        ref={ref}
        className={className}
        style={style}
        alt={alt}
        sizes={htmlSizes}
        srcSet={createSrcSet(src)}
        src={src(maxWidth)}
      />
    );

    if (!sources) {
      return FallbackImage;
    }

    return (
      <picture>
        {Object.keys(sources).map(type => (
          <source
            key={type}
            type={type}
            srcSet={createSrcSet(sources[type])}
            sizes={htmlSizes}
          />
        ))}
        {FallbackImage}
      </picture>
    );
  }
);
