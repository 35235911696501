import { baseFontSize } from "./typography";

export const borderRadius = 4;

const rootFontSize = 16;

const round = (value: number, dp = 0): number => {
  const multiplier = Math.pow(10, dp);

  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
};

export const pxToRem = (x: number): string =>
  `${round(x / baseFontSize, 2)}rem`;

export const pxToEm = (x: number): string => `${round(x / rootFontSize, 2)}em`;

export const modularScale = (step = 0): string =>
  pxToRem(Math.pow(1.2, step) * baseFontSize);
