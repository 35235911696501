/* eslint-disable no-restricted-imports */
import {
  useQuery as useApolloQuery,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from "@apollo/client";

export function useQuery<R, V extends OperationVariables>(
  document: TypedDocumentNode<R, V>,
  options?: QueryHookOptions<R, V>
) {
  return useApolloQuery(document, {
    ...options,
    ssr: options?.ssr ?? false,
  });
}
