import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";

const PlanCalloutWrapper = styled.div`
  background-color: blue-100;
  border-left: 4px solid ${palette.extended.blue.shade500};
  border-radius: ${pxToRem(4)};
  display: block;
  padding: sm;
  span {
    font-weight: 400;
  }
`;

export function PlanCallout({
  currentCustomerPlans,
}: {
  currentCustomerPlans: (string | null)[];
}) {
  return (
    <PlanCalloutWrapper>
      <h4>
        Current customer plan: <span>{currentCustomerPlans?.join(", ")}</span>
      </h4>
    </PlanCalloutWrapper>
  );
}
