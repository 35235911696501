import Select from "react-select";
import styled from "@xstyled/styled-components";
import { useCallback, useMemo, useState } from "react";
import { TypedDocumentNode, useMutation } from "@apollo/client";

import { Person } from "./Person";

import { useQuery } from "@toolbox/apollo";
import { Card } from "@toolbox/components/Card";
import { FieldWrapper } from "@toolbox/components/Field/FieldWrapper";
import { H4 } from "@toolbox/components/Headings";
import { Input } from "@toolbox/components/Input";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { TwoColumns } from "@toolbox/containers/Founders";
import { Gender } from "@toolbox/schema";

export const genderOptions = [
  { value: Gender.Female, label: "Female" },
  { value: Gender.Male, label: "Male" },
  { value: Gender.NonBinary, label: "Non-binary" },
  { value: Gender.Transgender, label: "Transgender" },
];

interface PersonData {
  id: string;
  name: string;
  title?: string;
  linkedinUrl?: string | null;
  bio?: string | null;
  gender?: Gender | null;
}
interface PersonInput {
  name?: string | null;
  title?: string | null;
  linkedinUrl?: string | null;
  bio?: string | null;
  gender?: Gender | null;
}

interface IMultiplePeopleFieldProps<F extends string, P extends string> {
  parentId: string;
  heading: string;
  parentName: P;
  fieldName: F;
  showTitle: boolean;
  showGender: boolean;
  QUERY: TypedDocumentNode<
    {
      [parent in P]:
        | {
            [field in F]: PersonData[];
          }
        | undefined
        | null;
    },
    { id: string }
  >;
  CREATE_MUTATION: TypedDocumentNode<
    unknown,
    { id: string; input: PersonInput }
  >;
  UPDATE_MUTATION: TypedDocumentNode<
    unknown,
    { id: string; input: PersonInput }
  >;
  DELETE_MUTATION: TypedDocumentNode<unknown, { id: string }>;
}

const StyledInput = styled(Input)`
  margin: 4px 0;
`;

export function MultiplePeopleField<F extends string, P extends string>({
  parentId,
  heading,
  parentName,
  fieldName,
  showTitle,
  showGender,
  QUERY,
  CREATE_MUTATION,
  UPDATE_MUTATION,
  DELETE_MUTATION,
}: IMultiplePeopleFieldProps<F, P>): React.ReactElement {
  const [name, setName] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [bio, setBio] = useState("");
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState<Gender | null>(null);

  const { data, refetch } = useQuery(QUERY, { variables: { id: parentId } });

  const [create] = useMutation(CREATE_MUTATION, {
    onError: e => {
      window.alert(e);
    },
  });

  const handleCreate = useCallback(async () => {
    const allFilledIn =
      name.length > 0 &&
      linkedinUrl.length > 0 &&
      bio.length > 0 &&
      (!showTitle || title.length > 0) &&
      (!showGender || gender);

    if (allFilledIn) {
      setName("");
      setLinkedinUrl("");
      setBio("");
      setTitle("");
      setGender(null);

      const input = {
        name,
        linkedinUrl,
        bio,
        ...(showTitle ? { title } : {}),
        ...(showGender ? { gender } : {}),
      };
      await create({
        variables: { input, id: parentId },
      });
      await refetch();
    }
  }, [
    bio,
    create,
    gender,
    linkedinUrl,
    name,
    parentId,
    refetch,
    showGender,
    showTitle,
    title,
  ]);

  const items = useMemo(
    () => data?.[parentName]?.[fieldName] ?? [],
    [data, fieldName, parentName]
  );

  return (
    <div>
      <H4>{heading}</H4>

      {items.map(({ id, name, linkedinUrl, bio, title, gender }) => (
        <Person
          name={name}
          title={title}
          linkedinUrl={linkedinUrl}
          bio={bio}
          gender={gender}
          key={id}
          id={id}
          showTitle={showTitle}
          showGender={showGender}
          refetchAll={refetch}
          DELETE_MUTATION={DELETE_MUTATION}
          UPDATE_MUTATION={UPDATE_MUTATION}
        />
      ))}
      <Card>
        <FieldWrapper>
          <TwoColumns>
            <StyledInput
              placeholder="Name"
              value={name}
              onChange={e => setName(e.target.value)}
              onBlur={handleCreate}
            />
            {showTitle && (
              <StyledInput
                placeholder="Title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                onBlur={handleCreate}
              />
            )}
          </TwoColumns>
          <StyledInput
            placeholder="LinkedIn URL"
            value={linkedinUrl}
            onChange={e => setLinkedinUrl(e.target.value)}
            onBlur={handleCreate}
          />
          <Textarea
            placeholder="Bio"
            value={bio}
            onChange={e => setBio(e.target.value)}
            onBlur={handleCreate}
          />
          {showGender && (
            <Select
              value={genderOptions.find(v => v.value === gender)}
              options={genderOptions}
              placeholder="Select gender identity"
              onChange={e => {
                setGender(e?.value ?? null);
              }}
              onBlur={handleCreate}
            />
          )}
        </FieldWrapper>
      </Card>
    </div>
  );
}
