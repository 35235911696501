import { ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Button } from "@otta/design";
import { handleMutationError } from "@toolbox/utils/error";
import {
  CompanyWorkflowStatus,
  UpdateCompanyWorkflowStatusDocument,
} from "@toolbox/schema";

interface IWorkflowButtonProps {
  companyId: string;
  nextStatus: CompanyWorkflowStatus;
  level: "primary" | "secondary";
  children: ReactNode;
}

export default function WorkflowButton({
  companyId,
  nextStatus,
  ...rest
}: IWorkflowButtonProps) {
  const navigate = useNavigate();
  const [updateMutation] = useMutation(UpdateCompanyWorkflowStatusDocument, {
    onError: handleMutationError,
  });

  const handleClick = useCallback(async () => {
    await updateMutation({
      variables: {
        id: companyId,
        workflowStatus: nextStatus,
      },
    });

    navigate("..");
  }, [companyId, updateMutation, nextStatus, navigate]);

  return <Button onClick={handleClick} {...rest} />;
}
