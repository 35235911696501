interface IArrowProps {
  colour: string;
  className?: string;
  transform?: string;
  height?: string;
}

export const Arrow = ({
  colour,
  className,
  transform = "rotate(0deg)",
  height,
}: IArrowProps): React.ReactElement => (
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ transform, height }}
  >
    <g
      transform="matrix(.868 0 0 .868 2.0621 2.1578)"
      fill={colour}
      stroke={colour}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".91211"
    >
      <rect
        transform="rotate(-45)"
        x="-3.2457"
        y="18.027"
        width="11.884"
        height="1.4099"
      />
      <rect
        transform="rotate(45)"
        x="7.5528"
        y="1.8357"
        width="11.884"
        height="1.4099"
      />
    </g>
  </svg>
);
