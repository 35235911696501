import { CurrentRoleType, useUser } from "@toolbox/utils/user";

export function AdminsOnly({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement | null {
  const { role } = useUser();
  return role === CurrentRoleType.Admin ? <>{children}</> : null;
}
