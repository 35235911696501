import { useMemo } from "react";
import { TypedDocumentNode, useMutation } from "@apollo/client";

import { PasteableMultiSelectCreateField } from "./PasteableMultiSelectCreateField";

import { useQuery } from "@toolbox/apollo";

interface Option {
  id: string;
  value?: string | null;
}

interface IMultiSelectCreateFieldProps<P extends string, F extends string> {
  label: string;
  disabled?: boolean;
  fieldName: F;
  parentName: P;
  parentId: string;
  optionsQuery: TypedDocumentNode<
    { [key in F]: Option[] },
    Record<string, never>
  >;
  createMutation: TypedDocumentNode<unknown, { id: string; value: string }>;
  valueQuery: TypedDocumentNode<
    {
      [key in P]: { [key in F]: Option[] } | null | undefined;
    },
    { id: string }
  >;
  removeMutation: TypedDocumentNode<
    unknown,
    { parentId: string; childId: string }
  >;
  allowCreate: boolean;
  doNotIncludeIds?: string[];
}

function toOption({ id, value }: Option) {
  return {
    label: value,
    value: id,
  };
}

function filterValidOption(v: {
  label: string | null | undefined;
  value: string;
}): v is { label: string; value: string } {
  return !!v.label;
}

export function MultiSelectCreateField<P extends string, F extends string>({
  label,
  disabled,
  optionsQuery,
  fieldName,
  parentName,
  createMutation,
  parentId,
  valueQuery,
  removeMutation,
  allowCreate,
}: IMultiSelectCreateFieldProps<P, F>): React.ReactElement {
  const { data: optionsData, loading: optionsLoading } = useQuery(optionsQuery);
  const { data: valuesData, loading: valuesLoading } = useQuery(valueQuery, {
    variables: { id: parentId },
  });

  const refetchAll = {
    refetchQueries: [
      { query: optionsQuery },
      { query: valueQuery, variables: { id: parentId } },
    ],
  };

  const [create] = useMutation(createMutation, refetchAll);
  const [remove] = useMutation(removeMutation, refetchAll);

  const options = useMemo(
    () =>
      optionsData?.[fieldName].map(toOption).filter(filterValidOption) ?? [],
    [optionsData, fieldName]
  );

  const values = useMemo(
    () =>
      valuesData?.[parentName]?.[fieldName]
        .map(toOption)
        .filter(filterValidOption) ?? [],
    [valuesData, parentName, fieldName]
  );

  return (
    <PasteableMultiSelectCreateField
      label={label}
      disabled={disabled}
      options={options}
      onCreate={v => create({ variables: { id: parentId, value: v } })}
      onRemove={v => remove({ variables: { parentId, childId: v } })}
      loading={valuesLoading || optionsLoading}
      allowCreate={allowCreate}
      values={values}
    />
  );
}
