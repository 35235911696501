import styled from "@xstyled/styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";

import { useQuery } from "@toolbox/apollo";
import {
  CompanyWorkflowStatus,
  PaginatedCompaniesDocument,
} from "@toolbox/schema";
import { palette, pxToRem } from "@otta/design-tokens";

const OffersWrapper = styled.div`
  padding: 40;
`;

const CompanyListItem = styled.div`
  color: black;
  display: block;
`;

const CompanyListWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  margin: xxl 0;
`;

const StyledLink = styled(Link)`
  background-color: white;
  border-radius: 5px;
  color: black;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: xl;
  text-decoration: none;
  word-wrap: break-word;
`;

const SearchWrapper = styled.div`
  background-color: white;
  border: 1px solid ${palette.brand.black};
  border-radius: 4px;
  display: inline-block;
  color: black;
  margin-top: lg;
  position: relative;
  z-index: 0;
  width: ${pxToRem(300)};
`;

const SearchBox = styled.input`
  border: none;
  background: transparent;
  color: white;
  opacity: 0;
  z-index: 1;
  padding: sm;
  width: 100%;
  &:focus {
    color: black;
    opacity: 1;
    outline: none;
  }
  &:focus ~ label {
    font-size: 11px;
    opacity: 0.8;
    left: 0;
    transform: none;
    top: -20px;
  }
`;

const SearchLabel = styled.label`
  color: black;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  transition: default;
  z-index: -1;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Pagination = styled(ReactPaginate)`
  display: flex;
  flex-basis: 20%;
  justify-content: space-between;
  list-style: none;
  width: 200px;
  li {
    &:hover {
      cursor: pointer;
    }
  }
`;

export function Offers(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize: number = parseInt(searchParams.get("num") ?? "50");
  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const { data: companiesData } = useQuery(PaginatedCompaniesDocument, {
    variables: {
      workflowStatuses: [CompanyWorkflowStatus.Completed],
    },
  });
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const companiesList = useMemo(() => {
    const pageStart = currentPage * pageSize;
    const pageEnd = currentPage * pageSize + pageSize;
    const currentCompaniesSlice = companiesData?.companies.slice(
      pageStart,
      pageEnd
    );
    if (searchTerm && companiesData) {
      return companiesData?.companies.filter(company => {
        return company.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    } else if (currentCompaniesSlice) {
      return currentCompaniesSlice;
    } else {
      return [];
    }
  }, [currentPage, pageSize, companiesData, searchTerm]);

  const totalPages = useMemo(() => {
    if (companiesData?.companies) {
      return Math.round(companiesData?.companies?.length / pageSize);
    } else {
      return 1;
    }
  }, [companiesData?.companies, pageSize]);

  return (
    <OffersWrapper>
      <h1>Job offers</h1>
      <SearchWrapper>
        <SearchBox
          data-testid="offers-search-field"
          name="companySearchField"
          type="text"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
          }}
        />
        <SearchLabel htmlFor="companySearchField">
          Search for company...
        </SearchLabel>
      </SearchWrapper>
      <CompanyListWrapper>
        {companiesList?.map(company => {
          return (
            <CompanyListItem key={company.id}>
              <StyledLink to={`${company.id}`}>
                <h3>{company.name}</h3>
                <h4>{company.websiteUrl}</h4>
              </StyledLink>
            </CompanyListItem>
          );
        })}
      </CompanyListWrapper>
      {totalPages > 1 && (
        <PaginationWrapper data-testid="offers-pagination">
          <Pagination
            previousLabel="<"
            nextLabel=">"
            pageCount={totalPages}
            forcePage={currentPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={0}
            onPageChange={handlePageClick}
          />
        </PaginationWrapper>
      )}
    </OffersWrapper>
  );
}
