import styled from "@xstyled/styled-components";

import { useQuery } from "@toolbox/apollo";
import {
  JobsAtSameCompanyDocument,
  JobsAtSameCompany as JobsAtSameCompanyTypes,
} from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";

const Title = styled.span`
  font-weight: 600;
`;
const Subtitle = styled.span`
  font-weight: 400;
`;

function Job({
  id,
  title,
  subtitle,
  minYearsExperienceRequired,
  maxYearsExperienceRequired,
}: JobsAtSameCompanyTypes.LiveJobs) {
  return (
    <div>
      <Link to={`/jobs/completed/${id}`}>
        <Title>{title}</Title>
      </Link>
      <Subtitle>{subtitle && ` (${subtitle})`}</Subtitle>
      <Subtitle>{` (${minYearsExperienceRequired} - ${maxYearsExperienceRequired} yrs)`}</Subtitle>
    </div>
  );
}

export function JobsAtSameCompany({
  jobId,
}: {
  jobId: string;
}): React.ReactElement {
  const { data } = useQuery(JobsAtSameCompanyDocument, {
    variables: { id: jobId },
  });

  const jobs = data?.job?.company?.liveJobs ?? [];

  const otherJobs = jobs
    .filter(({ id }) => id !== jobId)
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div data-testid="jobs-at-same-company">
      {otherJobs.map((job, index) => (
        <Job key={index} {...job} />
      ))}
    </div>
  );
}
