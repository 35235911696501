import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";
import { format, parseISO } from "date-fns";
import { useMutation } from "@apollo/client";

import { pxToRem } from "@otta/design-tokens";
import { Button, Card, ErrorText, Text, VerticalSpacing } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import {
  ApproveCompanyDocument,
  CompanyWorkflowStatus,
  DisapproveCompanyDocument,
  InternalCompaniesDocument,
  PushCompanyToProDocument,
  UpdateCompanyWorkflowStatusDocument,
} from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";
import { ProductBubbles } from "@toolbox/components/ProductBubbles";

const Wrapper = styled.div`
  display: grid;
  gap: lg;
  grid-template-columns: 1fr;

  ${up(
    "tablet",
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: md;
`;

interface CompanyProps {
  company: {
    id: string;
    name: string;
    websiteUrl: string | null;
    createdAt: string;
    isPro: boolean;
    isCurrentPayingCustomer: boolean;
    recruiters: Array<{
      id: string;
      email: string;
    }>;
    jobSlotData: {
      paid: number;
    } | null;
  };
  workflowStatus: CompanyWorkflowStatus;
}

function Company({ company, workflowStatus }: CompanyProps) {
  const creationTime = useMemo(
    () => format(parseISO(company.createdAt), "do MMMM yyyy HH:mm"),
    [company.createdAt]
  );

  const [archive, { loading: archiveLoading }] = useMutation(
    UpdateCompanyWorkflowStatusDocument,
    {
      variables: {
        id: company.id,
        workflowStatus: CompanyWorkflowStatus.Archived,
      },
      refetchQueries: [InternalCompaniesDocument],
    }
  );

  const [approve, { loading: approveLoading }] = useMutation(
    ApproveCompanyDocument,
    {
      variables: {
        companyId: company.id,
      },
      refetchQueries: [InternalCompaniesDocument],
    }
  );

  const [disapprove, { loading: disapproveLoading }] = useMutation(
    DisapproveCompanyDocument,
    {
      variables: {
        companyId: company.id,
      },
      refetchQueries: [InternalCompaniesDocument],
    }
  );

  const [pushToPro, { loading: pushToProLoading }] = useMutation(
    PushCompanyToProDocument,
    {
      variables: {
        companyId: company.id,
      },
      refetchQueries: [InternalCompaniesDocument],
    }
  );

  const [queue, { loading: queueLoading }] = useMutation(
    UpdateCompanyWorkflowStatusDocument,
    {
      variables: {
        id: company.id,
        workflowStatus: CompanyWorkflowStatus.Queued,
      },
      refetchQueries: [InternalCompaniesDocument],
    }
  );

  const loading =
    archiveLoading ||
    disapproveLoading ||
    approveLoading ||
    pushToProLoading ||
    queueLoading;

  return (
    <Card data-testid="company-preapproval-card">
      <VerticalSpacing size={-5}>
        <Text size={1} bold>
          {company.name}
        </Text>
        {company.websiteUrl && (
          <Link to={company.websiteUrl}>
            <Text
              style={{
                maxWidth: pxToRem(300),
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {company.websiteUrl}
            </Text>
          </Link>
        )}
        <Text>Requested at: {creationTime}</Text>
        <Text>By: {company.recruiters.map(r => r.email).join(", ")}</Text>
        <ButtonWrapper>
          <Button
            size="small"
            level="secondary"
            disabled={loading}
            onClick={() => {
              archive();
            }}
          >
            Archive
          </Button>
          <Button
            size="small"
            level="secondary"
            disabled={loading}
            onClick={() => {
              disapprove();
            }}
          >
            Disapprove
          </Button>
          {workflowStatus !== CompanyWorkflowStatus.PushedToPro && (
            <Button
              size="small"
              level="secondary"
              disabled={loading}
              onClick={() => {
                pushToPro();
              }}
            >
              Push To Pro
            </Button>
          )}
          {workflowStatus !== CompanyWorkflowStatus.PushedToPro && (
            <Button
              size="small"
              level="primary"
              disabled={loading}
              onClick={() => {
                approve();
              }}
            >
              Approve
            </Button>
          )}
          {workflowStatus === CompanyWorkflowStatus.PushedToPro && (
            <Button
              size="small"
              level="primary"
              disabled={loading}
              onClick={() => {
                queue();
              }}
            >
              Move to Queued
            </Button>
          )}
        </ButtonWrapper>
        <ProductBubbles company={company} />
      </VerticalSpacing>
    </Card>
  );
}

export function PreApproval({
  workflowStatus,
}: {
  workflowStatus: CompanyWorkflowStatus;
}): React.ReactElement {
  const { data, loading, error } = useQuery(InternalCompaniesDocument, {
    fetchPolicy: "network-only",
    variables: {
      workflowStatus,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorText>Something went wrong fetch companies</ErrorText>;
  }

  const companies = data?.internalCompanies ?? [];

  return (
    <Wrapper>
      {companies.map(company => (
        <Company
          key={company.id}
          workflowStatus={workflowStatus}
          company={company}
        />
      ))}
    </Wrapper>
  );
}
