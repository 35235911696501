import { useMutation } from "@apollo/client";
import { format, parseISO } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";

import {
  Button,
  Card,
  Input,
  Modal,
  Text,
  VerticalSpacing,
} from "@otta/design";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { Link } from "@toolbox/components/Link";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "@toolbox/components/Table";
import {
  CreateScrapeRequestDocument,
  ScrapeRequestStatus,
  ScrapeRequestsDocument,
} from "@toolbox/schema";

const WhitePage = styled.div`
  background-color: white;
  padding-bottom: 4rem;
  position: absolute;
  overflow-y: auto;
  inset: 0 0 0 0;
  bottom: 0;
  right: 0;
`;

const Content = styled.div`
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  flex-direction: column;
  display: flex;
  margin: auto;
  gap: 1.5rem;
`;

const TitleText = styled(Text)`
  line-height: 1;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledInput = styled(Input)`
  margin-right: -2px;
  max-width: 800px;
`;

const ModalContainer = styled.pre`
  padding: lg;
  font-family: monospace;
  background-color: black;
  color: white;
  overflow-x: scroll;
`;

const RequestInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const POLL_INTERVAL = 3000;

export default function Scraper(): React.ReactElement {
  const [scrapedItemData, setScrapedItemData] = useState<string | null>(null);
  const { data, startPolling, stopPolling } = useQuery(ScrapeRequestsDocument, {
    pollInterval: POLL_INTERVAL,
    variables: {
      limit: 100,
    },
  });

  const handleVisibilityChange = useCallback(() => {
    const isVisible = document.visibilityState === "visible";
    if (isVisible) {
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange, {
      capture: true,
    });

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange, {
        capture: true,
      });
    };
  }, [handleVisibilityChange]);

  const [createScrapeRequest] = useMutation(CreateScrapeRequestDocument, {
    update: (cache, result) => {
      const cacheData = cache.readQuery({
        query: ScrapeRequestsDocument,
      });

      if (!cacheData || !result.data?.createScrapeRequest) {
        return;
      }

      cache.writeQuery({
        query: ScrapeRequestsDocument,
        data: {
          scrapeRequests: [
            result.data?.createScrapeRequest,
            ...cacheData.scrapeRequests,
          ],
        },
      });
    },
  });

  const handleSubmit = ({ url }: { url: string }) => {
    createScrapeRequest({ variables: { url } });
  };

  const requests = data?.scrapeRequests ?? [];

  return (
    <WhitePage>
      <Content>
        <VerticalSpacing>
          <TitleText as="h2" size={2} bold>
            Scraper{" "}
            <span role="img" aria-label="Robot Emoji">
              🤖
            </span>
          </TitleText>
          <FinalForm<{ url: string }> onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <InputContainer>
                  <Field name="url">
                    {({ input }) => (
                      <StyledInput
                        placeholder="https://apply.workable.com/otta"
                        type="url"
                        {...input}
                      />
                    )}
                  </Field>
                  <Button level="primary" type="submit">
                    Submit
                  </Button>
                </InputContainer>
              </form>
            )}
          </FinalForm>
          {requests.map(request => (
            <Card key={request.id} data-testid="scrape-request">
              <VerticalSpacing>
                <RequestInfoContainer>
                  <Text>
                    <Link to={request.url}>{request.url}</Link>
                  </Text>
                  <Text>{request.status}</Text>
                </RequestInfoContainer>
                <Text size={-1}>
                  Created at (UTC):{" "}
                  {format(parseISO(request.insertedAt), "dd/MM/yy kk:mm")}
                </Text>
                {request.scrapedItems.length > 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <TableHeader>Item URL</TableHeader>
                        <TableHeader>View data</TableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {request.scrapedItems.map(item => (
                        <TableRow key={item.id} data-testid="scraped-item">
                          <TableCell fullWidth>
                            <Link to={item.url}>{item.url}</Link>
                          </TableCell>
                          <TableCell center>
                            <Button
                              size="small"
                              level="tertiary"
                              onClick={() => setScrapedItemData(item.data)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  request.status === ScrapeRequestStatus.Pending && <Loading />
                )}
              </VerticalSpacing>
            </Card>
          ))}
        </VerticalSpacing>

        <Modal
          open={!!scrapedItemData}
          onOpenChange={o => {
            if (!o) {
              setScrapedItemData(null);
            }
          }}
        >
          <ModalContainer>
            {scrapedItemData &&
              JSON.stringify(JSON.parse(scrapedItemData), null, 2)}
          </ModalContainer>
        </Modal>
      </Content>
    </WhitePage>
  );
}
