import { Field, IFieldProps } from ".";

import { Input } from "@toolbox/components/Input";

export function TextField(
  props: Omit<IFieldProps, "children">
): React.ReactElement {
  return (
    <Field {...props}>
      {renderProps => <Input {...renderProps} disabled={props.disabled} />}
    </Field>
  );
}
