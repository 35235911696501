import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { FieldWrapper } from "./FieldWrapper";

import { handleMutationError } from "@toolbox/utils/error";
import { Checkbox } from "@toolbox/components/Input/Checkbox";
import {
  UpdateCompanyProductDocument,
  CompanyProductsDocument,
  Product,
} from "@toolbox/schema";

interface IProductCheckboxFieldProps {
  id: string;
  value: boolean;
  product: Pick<Product, "__typename" | "id" | "name" | "displayName">;
}

export function ProductCheckboxField({
  id,
  value,
  product,
}: IProductCheckboxFieldProps): React.ReactElement {
  const [mutate] = useMutation(UpdateCompanyProductDocument, {
    variables: {
      companyId: id,
      productId: product.id,
      active: !value,
    },
    onError: handleMutationError,
    optimisticResponse(args) {
      if (!args.active) {
        return { updateCompanyProduct: null };
      }

      return {
        updateCompanyProduct: product,
      };
    },
    update(cache, result) {
      const newCompanyProduct = result.data?.updateCompanyProduct;

      const cachedData = cache.readQuery({
        query: CompanyProductsDocument,
        variables: { id },
      });

      if (!newCompanyProduct && cachedData?.company) {
        cache.writeQuery({
          query: CompanyProductsDocument,
          variables: { id },
          data: {
            allProducts: cachedData.allProducts,
            company: {
              ...cachedData.company,
              products: cachedData.company.products.filter(
                p => p.id !== product.id
              ),
            },
          },
        });
      }

      if (
        cachedData?.company &&
        newCompanyProduct &&
        cachedData.company.products.every(p => p.id !== newCompanyProduct.id)
      ) {
        cache.writeQuery({
          query: CompanyProductsDocument,
          variables: { id },
          data: {
            allProducts: cachedData.allProducts,
            company: {
              ...cachedData.company,
              products: [...cachedData.company.products, newCompanyProduct],
            },
          },
        });
      }
    },
  });

  const handleChange = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <FieldWrapper>
      <Checkbox
        label={product.displayName}
        onChange={handleChange}
        checked={value}
        data-testid="product-checkbox-input-component"
      />
    </FieldWrapper>
  );
}
