import { DefaultTheme, StyledComponentProps } from "styled-components";
import styled, { css } from "@xstyled/styled-components";

import { palette, typography, modularScale } from "@otta/design-tokens";

type TypographyElement =
  | "strong"
  | "span"
  | "p"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6";

export interface TypographyProps {
  color?: string;
  size?: number;
  bold?: boolean;
  align?: "inherit" | "center" | "left" | "right";
  inline?: boolean;
}

type StyledTypographyProps = StyledComponentProps<
  TypographyElement,
  DefaultTheme,
  TypographyProps,
  any
>;

const computeSize = ({ as, size }: StyledTypographyProps) => {
  if (size || (as !== "span" && as !== "strong")) {
    return css`
      font-size: ${modularScale(size)};
    `;
  }

  return null;
};

const computeLineHeight =
  (value: string) =>
  ({ as }: StyledTypographyProps) => {
    if (as !== "span" && as !== "strong") {
      return css`
        line-height: ${value};
      `;
    }

    return null;
  };

const sharedStyle = css<TypographyProps>`
  ${computeSize}
  text-align: ${({ align = "inherit" }) => align};
  color: ${({ color = "inherit" }) => color};
`;
/**
 * The size of the typography components is independent from the type of component used. We use the modular scale where the base is `16px` on mobile and `20px` on desktop and the ratio between sizes is `1.2`
 *
 * ```tsx
 *
 * import { Text } from '@otta/design';
 *
 * ```
 * ### Shared Props
 *
 * Both the `Text` and the `Heading` component take the following props: `color`, `align`, and `as`.
 *
 * - Using the `align` prop, you can change the text-align property of the `Heading` or `Text`.
 * - Use the `color` prop to change the color of the `Text` or `Heading`. It accepts any valid color string e.g. `#fefefe` or `rgba(0, 0, 0, 0.5)` but usually you'll use a predefined color from the palette.
 * - The `Heading` component provides the styling for the heading text, and the `Text` component provides the style for body text. If you need to use another element e.g. an `h1` use the `as` prop to configure this.
 *
 */
export const Text = styled.p<TypographyProps>`
  ${sharedStyle}
  ${computeLineHeight("1.3")};
  font-weight: ${({ bold = false }) => (bold ? 600 : 400)};
  letter-spacing: -0.02em;
  ${({ inline = false }) => inline && "display: inline"};
`;

/**
 * The size of the typography components is independent from the type of component used. We use the modular scale where the base is `16px` on mobile and `20px` on desktop and the ratio between sizes is `1.2`
 *
 * ```tsx
 *
 * import { Heading } from '@otta/design';
 *
 * ```
 * ### Shared Props
 *
 * Both the `Text` and the `Heading` component take the following props: `color`, `align`, and `as`.
 *
 * - Using the `align` prop, you can change the text-align property of the `Heading` or `Text`.
 * - Use the `color` prop to change the color of the `Text` or `Heading`. It accepts any valid color string e.g. `#fefefe` or `rgba(0, 0, 0, 0.5)` but usually you'll use a predefined color from the palette.
 * - The `Heading` component provides the styling for the heading text, and the `Text` component provides the style for body text. If you need to use another element e.g. an `h1` use the `as` prop to configure this.
 *
 */

export const Heading = styled.h1<TypographyProps>`
  ${sharedStyle}
  ${computeLineHeight("1")};
  font-family: ${typography.family.heading.name};
  font-weight: 600;
  letter-spacing: -0.02em;
`;

/**
 *```tsx
 *
 * import { Overline } from '@otta/design';
 *
 * ```
 */

export const Overline = styled.p<Omit<TypographyProps, "as">>`
  font-size: ${({ size }) => modularScale(size)};
  line-height: 1.3;
  font-weight: ${({ bold = false }) => (bold ? 600 : 400)};
  text-align: ${({ align = "inherit" }) => align};
  color: ${({ color = palette.grayscale.shade600 }) => color};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

/**
 *```tsx
 *
 * import { Paragraph } from '@otta/design';
 *
 * ```
 */

export const Paragraph = styled.p<Omit<TypographyProps, "as">>`
  ${sharedStyle}
  line-height: 1.6;
  font-weight: ${({ bold = false }) => (bold ? 600 : 400)};
  overflow-wrap: anywhere;
`;
