import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import * as Yup from "yup";

import { Modal } from "./lib/Modal";

import {
  Button,
  FieldWrapper,
  InputField,
  SelectField,
  Text,
} from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { AddCompanyToStarterDocument, CompanyDocument } from "@toolbox/schema";
import { handleMutationErrorGraceful } from "@toolbox/utils/error";

const StyledModal = styled(Modal)`
  &[open] {
    display: flex;
    flex: 1 1 0;
  }
  max-width: ${pxToRem(800)};
  border-radius: 8;
  padding: xl;
  margin: auto;
`;

const ModalContent = styled.div`
  flex-direction: column;
  max-height: 100%;
  display: flex;
  gap: 1.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: lg;
  padding-top: lg;
`;

const InputWrapper = styled.div`
  min-width: ${pxToRem(400)};
  margin: lg 0;
  label {
    display: block;
    margin-bottom: sm;
  }
  display: flex;
  flex-direction: column;
  gap: sm;
`;

const StyledButton = styled(Button)`
  flex: 1 1 0;
`;

const COHORT_OPTIONS = [
  {
    label: "Core Sales",
    value: "20231031_Core_Sales",
  },
  {
    label: "Churned Pro",
    value: "20231031_Churned_Pro",
  },
];

const validationSchema = Yup.object().shape({
  companyId: Yup.string().required(),
  cohortName: Yup.string().required(),
});

export function AddCompanyToStarter() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [successCompanyName, setSuccessCompanyName] = useState<
    string | undefined
  >();

  const [addToJobSlotMutation, { loading }] = useMutation(
    AddCompanyToStarterDocument,
    {
      onError: e => {
        setErrors(handleMutationErrorGraceful(e));
      },
    }
  );

  const [companyQuery, { data: companyData }] = useLazyQuery(CompanyDocument);

  const form = useFormik({
    initialValues: {
      companyId: "",
      cohortName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      const { data } = await addToJobSlotMutation({
        variables: {
          slots: 0,
          ...form.values,
        },
      });
      if (
        data?.manualEnrolIntoJobSlotExperiment &&
        companyData?.company?.name
      ) {
        setSuccessCompanyName(companyData.company.name);
      }
    },
  });

  const handleChangeCompanyId = (companyId: string) => {
    form.setFieldValue("companyId", companyId);
    companyQuery({
      variables: { id: companyId },
    });
  };

  if (successCompanyName) {
    return (
      <StyledModal open>
        <ModalContent>
          <Text as={"h1"} bold size={2} align="center">
            {successCompanyName} has been added to Starter
          </Text>
          <Button
            level="primary"
            style={{ maxWidth: "fit-content", alignSelf: "center" }}
            onClick={() => navigate("../")}
          >
            Close
          </Button>
        </ModalContent>
      </StyledModal>
    );
  }

  return (
    <StyledModal open>
      <ModalContent>
        <Text as={"h1"} bold size={2} align="center">
          Add company to Starter
        </Text>
        <form onSubmit={form.handleSubmit}>
          <FieldWrapper
            label="Company ID"
            fieldError={
              form.touched.companyId ? form.errors.companyId : undefined
            }
            required
          >
            {({ field }) => (
              <InputWrapper>
                <InputField
                  type="text"
                  name="companyId"
                  value={form.values.companyId}
                  onChange={e => handleChangeCompanyId(e.target.value)}
                  onBlur={form.handleBlur}
                  data-testid="company-id-field"
                  {...field}
                />
                {companyData?.company?.name && (
                  <Text>Company name: {companyData?.company?.name}</Text>
                )}
              </InputWrapper>
            )}
          </FieldWrapper>
          <FieldWrapper
            label="Cohort name"
            fieldError={
              form.touched.cohortName ? form.errors.cohortName : undefined
            }
            required
          >
            {({ field }) => (
              <InputWrapper>
                <SelectField
                  inputId="cohortName"
                  aria-label="Cohort name"
                  name="cohortName"
                  onBlur={form.handleBlur("cohortName")}
                  styles={{
                    container: provided => ({
                      ...provided,
                      textAlign: "left",
                    }),
                  }}
                  onChange={e => {
                    if (e) {
                      form.setFieldValue("cohortName", e.value);
                    }
                  }}
                  value={COHORT_OPTIONS.find(
                    o => o.value === form.values.cohortName
                  )}
                  options={COHORT_OPTIONS}
                  {...field}
                />
              </InputWrapper>
            )}
          </FieldWrapper>

          <ButtonWrapper>
            <StyledButton level="secondary" onClick={() => navigate("../")}>
              Cancel
            </StyledButton>
            <StyledButton
              level="primary"
              type="submit"
              disabled={
                loading || !(form.values.cohortName && form.values.companyId)
              }
              data-testid="submit-button"
            >
              {loading ? "Loading..." : "Add to Starter"}
            </StyledButton>
          </ButtonWrapper>
        </form>
        {errors && <p>{errors}</p>}
      </ModalContent>
    </StyledModal>
  );
}
