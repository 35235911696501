import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

interface FieldLabelProps {
  text: string;
  extraInfo?: React.ReactNode;
  autoSet?: boolean;
  divElement?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Label = styled.label`
  font-size: ${modularScale(-1)};
  font-weight: 600;
  display: block;
  width: 100%;
`;

export const AutoSetContainer = styled.span`
  display: inline-block;
  background-color: green-400;
  padding: 3px 8px;
  margin-left: 10px;
  margin-right: 8px;
  border-radius: 10px;
  font-size: ${modularScale(-2)};
  font-weight: 600;
`;

const ExtraInfoContainer = styled.span`
  display: inline-block;
  font-size: ${modularScale(-1)};
  font-weight: normal;
  max-width: 600px;
`;

const InputContainer = styled.div`
  margin-top: xs;
`;

export function FieldLabel({
  text,
  extraInfo,
  autoSet,
  divElement,
  children,
  className,
}: FieldLabelProps): React.ReactElement {
  return (
    <Label as={divElement ? "div" : "label"} className={className}>
      {text}
      {autoSet && <AutoSetContainer>Auto-set</AutoSetContainer>}
      {extraInfo && <ExtraInfoContainer>{extraInfo}</ExtraInfoContainer>}
      <InputContainer>{children}</InputContainer>
    </Label>
  );
}
