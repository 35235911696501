import { TypedDocumentNode, useMutation } from "@apollo/client";
import { useState } from "react";
import styled from "@xstyled/styled-components";

import { Text, InputField, Button } from "@otta/design";
import { handleMutationError } from "@toolbox/utils/error";

interface IMutationVariables {
  id: string;
  value: string;
}
export const FieldWithButton = styled.div`
  display: grid;
  grid-template-columns: 9fr 1fr;
  column-gap: 0.875rem;
`;

export const CreateNewJobTextItemField = ({
  jobId,
  label,
  create,
}: {
  jobId: string;
  label: string;
  create: TypedDocumentNode<unknown, IMutationVariables>;
}) => {
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");

  const [createMutation, { loading }] = useMutation(create, {
    onError: handleMutationError,
    onCompleted: () => {
      setMessage("Successfully created " + value);
      setValue("");
    },
  });

  return (
    <>
      <Text size={-1} bold>
        {label}
      </Text>
      <FieldWithButton>
        <InputField
          name={label.toLowerCase().replace(/\s/g, "-")}
          type="text"
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder="Enter a new item"
          data-testid="create-input"
        />
        <Button
          level="primary"
          size="small"
          data-testid="create-button"
          disabled={loading || !value}
          onClick={() => {
            const cleanedValue = value.trim();
            if (cleanedValue) {
              setMessage("");
              createMutation({
                variables: {
                  id: jobId,
                  value: cleanedValue,
                },
              });
            }
          }}
        >
          {loading ? "Creating..." : "Create"}
        </Button>
        <Text>{message}</Text>
      </FieldWithButton>
    </>
  );
};
