import styled from "@xstyled/styled-components";

import { CompanyEmployeeSurveysPendingApproval } from "@toolbox/schema";

const ResponseListContainer = styled.div`
  margin-right: 20px;
`;

const ResponseContainer = styled.div`
  margin-bottom: 12px;
`;

interface IPendingSurveyResponseListProps {
  responses: CompanyEmployeeSurveysPendingApproval.Responses[];
}

export function PendingSurveyResponseList({
  responses,
}: IPendingSurveyResponseListProps): React.ReactElement {
  return (
    <ResponseListContainer>
      {responses.map(response => (
        <ResponseContainer key={response.id}>
          <h4>{response.category.name}</h4>
          <div>{response.content}</div>
        </ResponseContainer>
      ))}
    </ResponseListContainer>
  );
}
