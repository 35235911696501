import { useCallback, useMemo } from "react";
import { ActionMeta, MultiValue } from "react-select";
import { useMutation } from "@apollo/client";

import { Text, VerticalSpacing, SelectField } from "@otta/design";
import { useQuery } from "@toolbox/apollo";
import {
  CompanySectorTagsDocument,
  SectorTagsDocument,
  CreateSectorTagDocument,
  RemoveSectorTagDocument,
  UpdateCompanyDocument,
} from "@toolbox/schema";
import { CheckboxField } from "@toolbox/components/Field/CheckboxField";
import { Grid } from "@toolbox/components/Field/LocationPreferencesField";
import { Checkbox } from "@toolbox/components/Input/Checkbox";

interface SectorWithCategory {
  __typename: "SectorTag";
  id: string;
  value: string;
  category: {
    __typename: "SectorCategory";
    id: string;
    value: string;
  };
}

interface ISectorTagsProps {
  bcorp: boolean;
  id: string;
}

const sellToValues = ["B2B", "B2C"];
const modifierValues = [
  "Retail",
  "Enterprise",
  "Marketplace",
  "SaaS",
  "eCommerce",
];

function filterOptions<V extends { label: string; value: string }>(
  option: {
    label: string;
    value: string;
    data: V;
  },
  input: string
) {
  const normalizedInput = input.trim().toLocaleLowerCase();

  if (option.label.trim().toLowerCase().includes(normalizedInput)) {
    return true;
  }

  if (
    "parentLabel" in option.data &&
    typeof option.data.parentLabel === "string"
  ) {
    return option.data.parentLabel
      .trim()
      .toLowerCase()
      .includes(normalizedInput);
  }

  return false;
}

export function SectorTags({
  bcorp,
  id,
}: ISectorTagsProps): React.ReactElement {
  const { data: allSectorOptions, loading: allLoading } =
    useQuery(SectorTagsDocument);

  const { data: currentOptions, loading: currentLoading } = useQuery(
    CompanySectorTagsDocument,
    {
      variables: { id: id },
    }
  );

  const currentSelectedIds = useMemo(
    () => (currentOptions?.company?.sectorTags ?? []).map(option => option.id),
    [currentOptions?.company?.sectorTags]
  );

  const allOptions = useMemo(
    () => allSectorOptions?.sectorTags ?? [],
    [allSectorOptions?.sectorTags]
  );

  const [create, { loading: createLoading }] = useMutation(
    CreateSectorTagDocument,
    {
      optimisticResponse(args) {
        const existing = allOptions.find(o => o.value === args.value) ?? null;
        return {
          createSectorTag: existing,
        };
      },
      update(cache, result) {
        const newTag = result.data?.createSectorTag;

        if (!newTag) {
          return;
        }
        const cachedData = cache.readQuery({
          query: CompanySectorTagsDocument,
          variables: { id },
        });

        if (
          cachedData?.company &&
          cachedData.company.sectorTags.every(t => t.id !== newTag.id)
        ) {
          cache.writeQuery({
            query: CompanySectorTagsDocument,
            variables: { id },
            data: {
              company: {
                ...cachedData.company,
                sectorTags: [...cachedData.company.sectorTags, newTag],
              },
            },
          });
        }
      },
    }
  );

  const [remove, { loading: removeLoading }] = useMutation(
    RemoveSectorTagDocument
  );

  const sellToOptions = useMemo(
    () =>
      allOptions
        .filter(v => sellToValues.includes(v.value))
        .sort(
          (a, b) =>
            sellToValues.indexOf(a.value) - sellToValues.indexOf(b.value)
        )
        .map(option => ({
          ...option,
          selected: currentSelectedIds.includes(option.id),
        })),
    [allOptions, currentSelectedIds]
  );

  const modifierOptions = useMemo(
    () =>
      allOptions
        .filter(option => modifierValues.includes(option.value))
        .sort(
          (a, b) =>
            modifierValues.indexOf(a.value) - modifierValues.indexOf(b.value)
        )
        .map(option => ({
          ...option,
          selected: currentSelectedIds.includes(option.id),
        })),
    [allOptions, currentSelectedIds]
  );

  const otherOptions = useMemo(
    () =>
      allOptions
        .filter(
          (t): t is SectorWithCategory =>
            !sellToValues.includes(t.value) &&
            !modifierValues.includes(t.value) &&
            Boolean(t.category)
        )
        .map(t => ({
          label: t.value,
          value: t.id,
          parentLabel: t.category.value,
        })),
    [allOptions]
  );

  const otherOptionGroups = useMemo(() => {
    const groups = otherOptions.reduce<
      Record<string, { label: string; value: string; parentLabel: string }[]>
    >((acc, option) => {
      if (!acc[option.parentLabel]) {
        acc[option.parentLabel] = [];
      }

      acc[option.parentLabel].push(option);

      return acc;
    }, {});

    return Object.entries(groups).map(([label, options]) => ({
      label,
      options,
    }));
  }, [otherOptions]);

  const selectedOtherOptions = useMemo(
    () =>
      otherOptions.filter(option => currentSelectedIds.includes(option.value)),
    [currentSelectedIds, otherOptions]
  );

  const handleCreate = useCallback(
    (value: string) => {
      create({ variables: { id, value } });
    },
    [create, id]
  );

  const handleRemove = useCallback(
    (childId: string) => {
      remove({ variables: { parentId: id, childId } });
    },
    [remove, id]
  );

  const handleChange = useCallback(
    (option: { id: string; value: string; selected: boolean }) => {
      if (!option.selected) {
        handleCreate(option.value);
      } else {
        handleRemove(option.id);
      }
    },
    [handleCreate, handleRemove]
  );

  const handleSelectChange = useCallback(
    (
      _newValue: MultiValue<{ label: string; value: string }>,
      actionMeta: ActionMeta<{ label: string; value: string }>
    ) => {
      switch (actionMeta.action) {
        case "select-option": {
          if (actionMeta.option) {
            handleCreate(actionMeta.option.label);
          }
          break;
        }

        case "deselect-option": {
          if (actionMeta.option) {
            handleRemove(actionMeta.option.value);
          }
          break;
        }

        case "remove-value":
        case "pop-value": {
          if (actionMeta.removedValue) {
            handleRemove(actionMeta.removedValue.value);
          }
          break;
        }

        case "clear": {
          selectedOtherOptions.forEach(v => {
            handleRemove(v.value);
          });
          break;
        }
      }
    },
    [handleCreate, handleRemove, selectedOtherOptions]
  );

  return (
    <VerticalSpacing size={-3}>
      <VerticalSpacing size={-3}>
        <Text size={-1} bold>
          Who does the company sell to? (Select all that apply)
        </Text>
        <Grid>
          {sellToOptions.map(option => (
            <Checkbox
              key={option.value}
              label={option.value}
              id={option.id}
              checked={option.selected}
              onChange={() => handleChange(option)}
            />
          ))}
        </Grid>
      </VerticalSpacing>
      <VerticalSpacing size={-3}>
        <Text size={-1} bold>
          Modifiers
        </Text>
        <Grid>
          {modifierOptions.map(option => (
            <Checkbox
              key={option.value}
              label={option.value}
              id={option.id}
              checked={option.selected}
              onChange={() => handleChange(option)}
            />
          ))}
          <CheckboxField
            label="B Corporation"
            value={bcorp}
            mutation={UpdateCompanyDocument}
            fieldName="bcorp"
            id={id}
          />
        </Grid>
      </VerticalSpacing>
      <VerticalSpacing size={-6}>
        <Text size={-1} bold>
          What sector(s) does the company operate in? (Select all that apply)
        </Text>
        <SelectField<string, true>
          isMulti
          options={otherOptionGroups}
          isLoading={
            allLoading || currentLoading || createLoading || removeLoading
          }
          value={selectedOtherOptions}
          onChange={handleSelectChange}
          filterOption={filterOptions}
        />
      </VerticalSpacing>
    </VerticalSpacing>
  );
}
