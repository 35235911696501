import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { Button, Card, ErrorText, Text } from "@otta/design";
import { Spinner } from "@otta/shared-components";
import {
  DeleteJobReactionsDocument,
  DeleteUserDocument,
  RoleType,
  UsersDocument,
} from "@toolbox/schema";
import { handleMutationErrorGraceful } from "@toolbox/utils/error";

interface DeleteUserModalProps {
  id: string;
  email: string;
  role: RoleType;
}

const DeleteSection = styled.div`
  margin: sm 0;
  p {
    margin-bottom: sm;
  }
`;

export function DeleteUserModal({ id, email, role }: DeleteUserModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [deleteMutation] = useMutation(DeleteUserDocument, {
    variables: { userId: id },
    refetchQueries: [{ query: UsersDocument }],
    onError: e => {
      setLoading(false);
      setErrorMessage(handleMutationErrorGraceful(e));
    },
    onCompleted: () => {
      setLoading(false);
      setShowSuccess(true);
    },
  });

  const handleDeleteUserClick = useCallback(() => {
    if (
      window.confirm(
        "Are you sure you want to delete this user? This can't be undone."
      )
    ) {
      const emailPrompt = window.prompt("Type the user's email to confirm.");
      if (emailPrompt === email) {
        deleteMutation();
        setLoading(true);
      } else {
        window.alert("Incorrect email.");
      }
    }
  }, [deleteMutation, email]);

  const [deleteJobReactions] = useMutation(DeleteJobReactionsDocument, {
    variables: { userId: id },
    onError: e => {
      setLoading(false);
      setErrorMessage(handleMutationErrorGraceful(e));
    },
    onCompleted: () => {
      setLoading(false);
      setShowSuccess(true);
    },
  });

  const handleDeleteReactionsClick = useCallback(() => {
    if (
      window.confirm(
        "Are you sure you want to delete this user's reactions? This can't be undone."
      )
    ) {
      deleteJobReactions();
      setLoading(true);
    }
  }, [deleteJobReactions]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Card>
      {!loading && showSuccess && <Text>User Deleted!</Text>}
      {loading && !showSuccess && <Spinner />}
      {!loading && !showSuccess && (
        <DeleteSection>
          <Text>
            <Text bold>{email}</Text>
          </Text>
          <DeleteSection>
            <Text as="h3" bold>
              Delete user
            </Text>
            <Text>
              {" "}
              Deletions can sometimes take a few minutes, once you've completed
              the below, please wait until the spinner is finished.
            </Text>
            <Button
              level="destructive"
              size="small"
              onClick={handleDeleteUserClick}
            >
              Delete
            </Button>
          </DeleteSection>
          {role === RoleType.Candidate && (
            <DeleteSection>
              <Text as="h3" bold>
                Delete reactions
              </Text>
              <Text>
                {" "}
                Deleting reactions can sometimes take a few seconds, once you've
                completed the below, please wait until the spinner is finished.
              </Text>
              <Button
                level="destructive"
                size="small"
                onClick={handleDeleteReactionsClick}
              >
                Delete reactions
              </Button>
            </DeleteSection>
          )}
        </DeleteSection>
      )}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Card>
  );
}
