import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

export const Icon = styled.span`
  svg {
    width: ${modularScale(-2)};
    height: ${modularScale(-2)};
  }
  align-items: center;
`;

export const BulletIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  margin-right: 6;
`;
