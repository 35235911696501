import { createContext } from "react";

import { ILocationPreference } from "@toolbox/components/Field/LocationPreferencesField";

interface Filters {
  subFunction: { [subFunctionId: string]: string[] };
  location: ILocationPreference[];
}

export const FiltersContext = createContext<Filters>({
  subFunction: {},
  location: [],
});

export default FiltersContext.Provider;
