export function Delete(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 69.623711 69.623711"
      version="1.1"
      fill="#000000"
      {...props}
    >
      <g id="layer1" transform="translate(-48.153909,-87.657633)">
        <rect
          fillOpacity="1"
          stroke="none"
          strokeWidth="2.32599998"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeDasharray="none"
          strokeOpacity=""
          id="rect815"
          width="91.470238"
          height="6.9925594"
          x="99.529549"
          y="24.437071"
          transform="rotate(45)"
        />
        <rect
          transform="rotate(135)"
          y="-148.76094"
          x="-17.801767"
          height="6.9925594"
          width="91.470238"
          id="rect825"
          fillOpacity="1"
          stroke="none"
          strokeWidth="2.32599998"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeDasharray="none"
          strokeOpacity=""
        />
      </g>
    </svg>
  );
}
