import styled from "@xstyled/styled-components";

const CardOuter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.card.background};
  box-shadow: ${({ theme }) => theme.shadow};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.card.border};
  margin: 12px auto;

  border-radius: ${({ theme }) => theme.card.borderRadius};
  margin-bottom: 32px;
`;

const CardInner = styled.div`
  margin: 0 12px;
  padding: 0 32px;
  > * {
    margin: 12px auto;
  }
`;

export const Card = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactElement => (
  <CardOuter {...props}>
    <CardInner>{children}</CardInner>
  </CardOuter>
);
