import {
  CompanyPeopleProgressingDocument,
  CreateCompanyPersonProgressingDocument,
  DeleteCompanyPersonProgressingDocument,
  UpdateCompanyPersonProgressingDocument,
} from "@toolbox/schema";
import { MultiplePeopleField } from "@toolbox/components/Field/MultiplePeopleField";

export function CompanyPeopleProgressing({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  return (
    <MultiplePeopleField
      heading="People progressing"
      parentName="company"
      fieldName="peopleProgressing"
      showTitle={false}
      showGender={false}
      CREATE_MUTATION={CreateCompanyPersonProgressingDocument}
      DELETE_MUTATION={DeleteCompanyPersonProgressingDocument}
      UPDATE_MUTATION={UpdateCompanyPersonProgressingDocument}
      QUERY={CompanyPeopleProgressingDocument}
      parentId={companyId}
    />
  );
}
