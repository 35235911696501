import deepEqual from "deep-equal";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";
import {
  Label,
  RadioCurrencySelection,
  Spacing,
  MoneyRange,
  ErrorText,
  Button,
} from "@otta/design";
import { composeValidators, validSalary } from "@otta/shared-components";
import { AVAILABLE_CURRENCIES, CURRENCY_LABELS } from "@toolbox/utils/currency";
import { validOTESalary } from "@toolbox/utils/money";
import { PlusIcon } from "@toolbox/components/Icons/PlusIcon";
import { RemoveButton } from "@toolbox/components/Field/RequiredOfficeDaysField";
import { Currency, UpdateJobDocument } from "@toolbox/schema";
import { FieldLabel } from "@toolbox/components/Field/FieldLabel";

const StyledLabel = styled(Label)<{ bold?: boolean; small?: boolean }>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ small }) => (small ? modularScale(-1) : modularScale())};
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: xs;
  align-items: center;
`;

interface Range {
  minAmount?: string | null;
  maxAmount?: string | null;
}

interface FormState {
  currency: Currency | null;
  salaryRange: { minAmount: number | null; maxAmount: number | null };
  oteSalaryRange: { minAmount: number | null; maxAmount: number | null };
}

export function SalaryRange({
  jobId,
  currency,
  range,
  oteRange,
  autoSetFields,
}: {
  jobId: string;
  currency: Currency | null;
  range: Range | null;
  oteRange: Range | null;
  autoSetFields: Set<string> | null;
}) {
  const [showOTE, setShowOTE] = useState(!!oteRange);

  const [updateJobMutation] = useMutation(UpdateJobDocument);

  const handleSalarySubmit = ({
    salaryRange,
    oteSalaryRange,
    currency,
  }: FormState) => {
    const salaryRangeComplete = salaryRange.minAmount && salaryRange.maxAmount;
    const oteSalaryRangeComplete =
      oteSalaryRange.minAmount && oteSalaryRange.maxAmount;

    if (currency) {
      updateJobMutation({
        variables: {
          id: jobId,
          input: {
            salaryRange: salaryRangeComplete
              ? {
                  minAmount: salaryRange.minAmount?.toString(),
                  maxAmount: salaryRange.maxAmount?.toString(),
                  currency: currency,
                }
              : null,
            oteSalaryRange: oteSalaryRangeComplete
              ? {
                  minAmount: oteSalaryRange.minAmount?.toString(),
                  maxAmount: oteSalaryRange.maxAmount?.toString(),
                  currency: currency,
                }
              : null,
          },
        },
      });
    }
  };

  return (
    <div>
      <FieldLabel
        divElement={true}
        text="Salary range"
        autoSet={
          autoSetFields?.has("min_salary") ||
          autoSetFields?.has("max_salary") ||
          autoSetFields?.has("salary_currency")
        }
      >
        <Form<FormState>
          initialValues={{
            currency,
            salaryRange: {
              minAmount: range?.minAmount ? Number(range.minAmount) : null,
              maxAmount: range?.maxAmount ? Number(range.maxAmount) : null,
            },
            oteSalaryRange: {
              minAmount: oteRange?.minAmount
                ? Number(oteRange.minAmount)
                : null,
              maxAmount: oteRange?.maxAmount
                ? Number(oteRange.maxAmount)
                : null,
            },
          }}
          validate={({ salaryRange, oteSalaryRange }) => {
            if (!oteSalaryRange.minAmount && !oteSalaryRange.maxAmount) {
              return;
            }
            const salaryIsValid = validOTESalary({
              oteMin: oteSalaryRange.minAmount,
              oteMax: oteSalaryRange.maxAmount,
              baseMin: salaryRange.minAmount,
              baseMax: salaryRange.maxAmount,
            });
            if (salaryIsValid) {
              return { oteSalaryRange: salaryIsValid };
            }

            return undefined;
          }}
          initialValuesEqual={deepEqual}
          onSubmit={handleSalarySubmit}
        >
          {({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Field<Currency>
                name="currency"
                autoSet={
                  autoSetFields ? autoSetFields.has("salary_currency") : false
                }
              >
                {({ input: currencyInput }) => (
                  <RadioCurrencySelection
                    {...currencyInput}
                    onClick={e => {
                      currencyInput.onChange(e);

                      if (
                        (values.salaryRange.minAmount &&
                          values.salaryRange.maxAmount) ||
                        (values.oteSalaryRange.minAmount &&
                          values.oteSalaryRange.maxAmount)
                      ) {
                        handleSubmit();
                      }
                    }}
                    currency={currencyInput.value}
                    options={AVAILABLE_CURRENCIES}
                  />
                )}
              </Field>

              <Spacing size={-2}>
                <Field<{
                  minAmount: number | null;
                  maxAmount: number | null;
                  currency: Currency;
                }>
                  autoSet={
                    autoSetFields
                      ? autoSetFields.has("min_salary") ||
                        autoSetFields.has("max_salary")
                      : false
                  }
                  name="salaryRange"
                  validate={composeValidators(input => {
                    if (input.minAmount === null && input.maxAmount === null) {
                      return;
                    }

                    return validSalary(input.minAmount, input.maxAmount);
                  })}
                >
                  {({ input, meta: rangeMeta }) => {
                    return (
                      <div data-testid="salary-range">
                        {showOTE && (
                          <LabelWrapper>
                            <StyledLabel htmlFor="salaryRange" small bold>
                              Base salary
                            </StyledLabel>
                          </LabelWrapper>
                        )}
                        <MoneyRange
                          {...input}
                          name="salaryRange"
                          values={input.value}
                          prefix={
                            values.currency
                              ? CURRENCY_LABELS[values.currency]
                              : ""
                          }
                          placeholders={{
                            minAmount: "(Min)",
                            maxAmount: "(Max)",
                          }}
                          onBlur={handleSubmit}
                          disabled={!values.currency}
                        />
                        {rangeMeta.touched && rangeMeta.error && (
                          <ErrorText>{rangeMeta.error}</ErrorText>
                        )}
                      </div>
                    );
                  }}
                </Field>

                {showOTE ? (
                  <>
                    <LabelWrapper>
                      <StyledLabel htmlFor="oteSalaryRange" small>
                        <strong>Total on-target earnings (OTE)</strong>{" "}
                        including base salary
                      </StyledLabel>
                      <RemoveButton
                        role="button"
                        level="secondary"
                        size="small"
                        onClick={() => {
                          form.change("oteSalaryRange", {
                            minAmount: null,
                            maxAmount: null,
                          });
                          setShowOTE(false);
                          handleSubmit();
                        }}
                        data-testid="remove-ote-button"
                      >
                        Remove
                      </RemoveButton>
                    </LabelWrapper>
                    <Field<{
                      minAmount: number | null;
                      maxAmount: number | null;
                      currency: Currency;
                    }> name="oteSalaryRange">
                      {({ input, meta: rangeMeta }) => (
                        <div data-testid="my-test">
                          <MoneyRange
                            {...input}
                            name="oteSalaryRange"
                            values={input.value}
                            prefix={
                              values.currency
                                ? CURRENCY_LABELS[values.currency]
                                : ""
                            }
                            placeholders={{
                              minAmount: "(Min)",
                              maxAmount: "(Max)",
                            }}
                            onBlur={() => handleSubmit()}
                            disabled={!values.currency}
                          />
                          {rangeMeta.touched && rangeMeta.error && (
                            <ErrorText>{rangeMeta.error}</ErrorText>
                          )}
                        </div>
                      )}
                    </Field>
                  </>
                ) : (
                  <Button
                    level="secondary"
                    size="small"
                    onClick={() => setShowOTE(true)}
                    data-testid="add-ote-button"
                  >
                    <IconContainer>
                      <PlusIcon />
                    </IconContainer>
                    On-target earnings (OTE)
                  </Button>
                )}
              </Spacing>
            </form>
          )}
        </Form>
      </FieldLabel>
    </div>
  );
}
