import Cookies from "js-cookie";
import { useCallback, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import RadioSelection from "./RadioSelection";

import { pxToRem } from "@otta/design-tokens";
import { Button, Text } from "@otta/design";
import { H2 } from "@toolbox/components/Headings";
import { PageWrapper } from "@toolbox/components/PageWrapper";
import { RefreshJobPerformanceStatisticsDocument } from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";
import { Icon } from "@otta/icons";

const AVAILABLE_REGIONS = ["UK", "US", "Rest of World"];

const REGION_COOKIE = "x-otta-region";

const DEBUGGER_COOKIE = "_otta_experiment_debugger";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  padding-top: lg;
`;

const getRegion = (region: string | null) => {
  if (region === "GB") {
    return "UK";
  }

  return region;
};

export default function Misc(): React.ReactElement {
  const domain = useMemo(() => {
    if (import.meta.env.MODE === "staging") {
      return ".staging.otta.com";
    }

    if (import.meta.env.MODE === "production") {
      return ".otta.com";
    }

    return "localhost";
  }, []);

  const region = Cookies.get(REGION_COOKIE) ?? null;

  const [currentRegion, setCurrentRegion] = useState(getRegion(region));

  const [debuggerEnabled, setDebuggerEnabled] = useState(
    Cookies.get(DEBUGGER_COOKIE) === "true"
  );

  const [refreshStats, { data: refreshData, loading: refreshLoading }] =
    useMutation(RefreshJobPerformanceStatisticsDocument);

  const handleToggleDebugger = useCallback(() => {
    if (debuggerEnabled) {
      Cookies.remove(DEBUGGER_COOKIE);
      setDebuggerEnabled(false);
    } else {
      Cookies.set(DEBUGGER_COOKIE, "true", {
        domain,
        expires: 365,
        path: "/",
      });
      setDebuggerEnabled(true);
    }
  }, [debuggerEnabled, domain]);

  const handleRegionClick = useCallback(
    (region: string) => {
      if (region === "UK") {
        Cookies.set(REGION_COOKIE, "GB", {
          domain,
        });
      } else {
        Cookies.set(REGION_COOKIE, region, {
          domain,
        });
      }
      setCurrentRegion(region);
    },
    [domain]
  );

  const removeRegion = useCallback(() => {
    Cookies.remove(REGION_COOKIE);
    setCurrentRegion(null);
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <H2>Misc</H2>
        <Text style={{ paddingLeft: `${pxToRem(15)}` }} size={1} bold>
          View site:
        </Text>
        <RadioSelection
          onClick={handleRegionClick}
          current={currentRegion}
          options={AVAILABLE_REGIONS}
        />
        <ButtonWrapper>
          <Button
            level="primary"
            onClick={removeRegion}
            disabled={region === null}
          >
            Remove region cookie
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            level={debuggerEnabled ? "secondary" : "primary"}
            onClick={handleToggleDebugger}
          >
            {debuggerEnabled ? "Disable" : "Enable"} experiment debugger
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Link underline={false} to="/access-check">
            <Button
              style={{
                display: "flex",
                gap: ".5rem",
              }}
              level="secondary"
            >
              Check user access
              <Icon icon="external" />
            </Button>
          </Link>
        </ButtonWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <H2>Developer tools</H2>
        <Text>⚠️ Please use with caution</Text>
        <ButtonWrapper>
          <Button
            level={"destructive"}
            onClick={() => refreshStats()}
            disabled={
              refreshLoading || refreshData?.refreshJobPerformanceStatistics
            }
          >
            {refreshLoading ? (
              "Loading"
            ) : refreshData?.refreshJobPerformanceStatistics ? (
              <>Job queued</>
            ) : (
              <>Refresh job performance statistics</>
            )}
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}
