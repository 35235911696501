export const required = <T = unknown>(value: T): string | undefined =>
  value ? undefined : "Fill in this field";

export const minLength =
  (min: number) =>
  <T = unknown>(value: T): string | undefined =>
    !value || (typeof value === "string" && value.length >= min)
      ? undefined
      : `Enter at least ${min} character${min > 1 ? "s" : ""}`;

export const maxLength =
  (max: number) =>
  <T = unknown>(value: T): string | undefined =>
    !value || (typeof value === "string" && value.length <= max)
      ? undefined
      : `Enter at most ${max} character${max > 1 ? "s" : ""}`;

export const minMaxLength =
  (min: number, max: number) =>
  <T = unknown>(value: T): string | undefined =>
    typeof value === "string" && value.length <= max && value.length >= min
      ? undefined
      : `Enter between ${min}-${max} characters to balance detail with being concise`;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validEmail = <T = unknown>(value: T): string | undefined => {
  return typeof value === "string" && emailRegex.test(value)
    ? undefined
    : "Enter a valid email address";
};

const urlRegex =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/i;

export const validUrl = <T = unknown>(value: T): string | undefined =>
  !value || (typeof value === "string" && urlRegex.test(value))
    ? undefined
    : "Enter a valid URL";

const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10,11}$/;

export const validMobile = <T = unknown>(value: T): string | undefined => {
  return typeof value === "string" && mobileRegex.test(value)
    ? undefined
    : "Enter a valid mobile number";
};

const linkedinUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[-%\p{L}\p{M}0-9]{3,100}\/?$/iu;

export const validLinkedinProfileUrl = <T = unknown>(
  value: T
): string | undefined =>
  value === undefined ||
  (typeof value === "string" && linkedinUrlRegex.test(value))
    ? undefined
    : "Enter a valid profile URL";

export const composeValidators =
  <T = unknown>(...validators: ((value: T) => string | undefined)[]) =>
  (value: T): string | undefined =>
    validators.reduce<string | undefined>(
      (error, validator) => error || validator(value),
      undefined
    );

export const acceptableSalaryRange = <T = unknown>(
  value: T
): string | undefined =>
  value === null ||
  value === undefined ||
  (typeof value === "number" && value >= 15000)
    ? undefined
    : "Enter more than £15k";

export const validMinSalary =
  (maxValue: number | string | null) =>
  (minValue?: number | string | null): string | undefined =>
    minValue === null ||
    minValue === undefined ||
    maxValue === null ||
    +minValue <= +maxValue
      ? undefined
      : "Enter a valid min salary";

export const validMaxSalary =
  (minValue: number | string | null) =>
  (maxValue?: number | string | null): string | undefined =>
    maxValue === null ||
    maxValue === undefined ||
    minValue === null ||
    +maxValue >= +minValue
      ? undefined
      : "Enter a valid max salary";

export const validSalary = (
  minValue?: number | string | null,
  maxValue?: number | string | null
): string | undefined => {
  // Both salaries are empty
  if (
    (minValue === null || minValue === undefined) &&
    (maxValue === null || maxValue === undefined)
  ) {
    return "Enter a salary";
  }

  // Only max salary is provided
  if (minValue === null || minValue === undefined) {
    return "Enter a minimum salary";
  }

  // Only min salary is provided
  if (maxValue === null || maxValue === undefined) {
    return "Enter a maximum salary";
  }

  // Both salaries provided, but max is less than min
  if (+maxValue < +minValue) {
    return "Enter a valid salary range";
  }

  // One or both of the salaries is less than 15k
  if (+minValue < 15000 || +maxValue < 15000) {
    return "Enter a salary that is greater than 15k";
  }

  // One or both of the salaries is more than or equal to 1m
  if (+minValue >= 1000000 || +maxValue >= 1000000) {
    return "Enter a salary that is lower than 1m";
  }

  return undefined;
};

export const validExperience = (
  minExperience?: number | null,
  maxExperience?: number | null
): string | undefined => {
  // Both experience fields are empty
  if (
    (minExperience === null || minExperience === undefined) &&
    (maxExperience === null || maxExperience === undefined)
  ) {
    return "Enter an experience range";
  }

  // Only max experience is provided
  if (minExperience === null || minExperience === undefined) {
    return "Enter a minimum experience level";
  }

  // Only min experience is provided
  if (maxExperience === null || maxExperience === undefined) {
    return "Enter a maximum experience level";
  }

  // Both experience levels provided, but max is less than min
  if (+maxExperience < +minExperience) {
    return "Enter a valid experience range";
  }

  // Minimum or maximum experience is less than 0
  if (minExperience < 0 || maxExperience < 0) {
    return "Enter a value that is greater than 0";
  }

  // Maximum or minimjm experience is greater than 20
  if (minExperience > 20 || maxExperience > 20) {
    return "Enter a value that is less than 20";
  }

  return undefined;
};

export const emptyOrMinLength =
  (min: number) =>
  <T = unknown>(value: T): string | undefined =>
    value === null ||
    value === undefined ||
    (typeof value === "string" && (value === "" || value.length >= min))
      ? undefined
      : `Enter at least ${min} characters`;
