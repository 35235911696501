import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { TH, TR, Table, YesNo } from "./lib/Table";

import { Checkbox } from "@otta/design";
import { PotentialStarterCompaniesQuery } from "@toolbox/schema";

const Check = styled(Checkbox).attrs(p => ({ ...p, label: "" }))`
  margin: 0 0 0 0.5rem;
`;

export const Columns: string[] = [
  "Company name",
  "External ID",
  "HubSpot ID",
  "Company URL",
  "# live jobs",
  "Reason for moving",
  "Free job slots?",
  "Previously Pro?",
];

export function CompanyList({
  selectedIds,
  companies,
  onSelect,
}: {
  selectedIds: Set<string>;
  onSelect: (ids: Set<string>) => void;
  companies: PotentialStarterCompaniesQuery["potentialCoreCompanies"];
}) {
  const allIds = useMemo(
    () => new Set(companies.map(c => c.company.id)),
    [companies]
  );

  return (
    <Table>
      <thead>
        <TR>
          <TH>
            <Check
              name="select-all"
              onChange={checked => {
                if (checked) {
                  onSelect(new Set(allIds));
                } else {
                  onSelect(new Set([]));
                }
              }}
            />
          </TH>
          {Columns.map((column, index) => (
            <TH key={index}>{column}</TH>
          ))}
        </TR>
      </thead>
      <tbody>
        {companies.map(({ reason, company, hasFreeJobSlots }, index) => (
          <TR key={index} selected={selectedIds.has(company.id)}>
            <td>
              <Check
                value={company.id}
                checked={selectedIds.has(company.id)}
                onChange={checked => {
                  const current = new Set(selectedIds);
                  if (checked) {
                    current.add(company.id);
                  } else {
                    current.delete(company.id);
                  }
                  onSelect(current);
                }}
              />
            </td>
            <td>{company.name}</td>
            <td>{company.externalId}</td>
            <td>{company.hubspotId}</td>
            <td>
              {company.websiteUrl ? (
                <a href={company.websiteUrl} target="_blank" rel="noreferrer">
                  {company.websiteUrl}
                </a>
              ) : (
                ""
              )}
            </td>
            <td>{company.numberLiveJobs}</td>
            <td>{reason}</td>
            <td>
              <YesNo value={hasFreeJobSlots} />
            </td>
            <td>
              <YesNo value={company.wasPro} />
            </td>
          </TR>
        ))}
      </tbody>
    </Table>
  );
}
