import styled from "styled-components";
import { useParams } from "react-router-dom";

import { Offices } from "./Offices";
import { PeopleBreakdown } from "./PeopleBreakdown";
import { Teams } from "./Teams";

import { pxToRem } from "@otta/design-tokens";
import { Spacing } from "@otta/design";

const Page = styled.div`
  max-width: ${pxToRem(960)};
  display: flex;
  flex-direction: column;
`;

export function Overview(): React.ReactElement {
  const { companyId } = useParams();

  if (!companyId) {
    return <>No company found</>;
  }

  return (
    <Page>
      <Spacing size={4}>
        <Teams companyId={companyId} />
        <PeopleBreakdown companyId={companyId} />
        <Offices companyId={companyId} />
      </Spacing>
    </Page>
  );
}
