import { Field, IFieldProps } from ".";

import { Textarea } from "@toolbox/components/Input/Textarea";

export function TextareaField(
  props: Omit<IFieldProps, "children">
): React.ReactElement {
  return (
    <Field {...props}>{renderProps => <Textarea {...renderProps} />}</Field>
  );
}
