import styled from "@xstyled/styled-components";

import { Text as DefaultText } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: lg;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  border-radius: ${pxToRem(10)};
  height: ${pxToRem(35)};
  margin-right: 10;
  align-items: center;
  justify-content: flex-start;
`;

const Wrapper = styled.label`
  display: block;
  position: relative;
  padding: sm 0;
  cursor: pointer;
  user-select: none;
  color: black;
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    left: ${pxToRem(6)};
    top: ${pxToRem(6)};
    width: ${pxToRem(5)};
    height: ${pxToRem(5)};
    border-radius: 50%;
    background-color: black;
    border: solid black;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const DefaultCheckmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const Checkmark = styled(DefaultCheckmark)<{ checked: boolean }>`
  left: ${pxToRem(2)};
  height: ${pxToRem(22)};
  width: ${pxToRem(22)};
  border-radius: 50%;
  background-color: ${({ checked }) =>
    checked ? palette.brand.yellow : palette.grayscale.shade200};
  border: 1px solid
    ${({ checked }) =>
      checked ? palette.brand.yellow : palette.grayscale.shade200};
`;

const Text = styled(DefaultText)`
  margin-left: 35;
  margin-top: xxs;
`;

interface IRadioSelectionProps {
  current: string | null;
  onClick: (e: string) => void;
  options: string[];
}

const RadioSelection = ({
  current,
  onClick,
  options,
}: IRadioSelectionProps) => {
  return (
    <CheckBoxContainer data-testid="radio-currency-selection-wrap">
      {options.map(option => (
        <CheckboxWrapper key={option}>
          <Wrapper>
            <Text>{option}</Text>
            <Input
              type="checkbox"
              checked={current == option}
              onChange={() => onClick(option)}
              data-testid={`radio-selection-${option}`}
            />
            <Checkmark checked={current == option} />
          </Wrapper>
        </CheckboxWrapper>
      ))}
    </CheckBoxContainer>
  );
};

export default RadioSelection;
