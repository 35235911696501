import { useMutation } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { FundingRound, MONTH_OPTIONS, ROUND_OPTIONS } from "./FundingRound";

import {
  Button,
  Card,
  InputField,
  Label,
  MoneyField,
  SelectField,
  Spacing,
  Text,
} from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { MultiSelectCreateField } from "@toolbox/components/Field/MultiSelectCreateField";
import {
  CompanyFundingRoundsDocument,
  CompanyInvestorsDocument,
  CreateCompanyFundingRoundDocument,
  CreateInvestorDocument,
  Currency,
  InvestorsDocument,
  RemoveInvestorDocument,
} from "@toolbox/schema";
import { AVAILABLE_CURRENCIES } from "@toolbox/utils/currency";

interface IFundingRoundsProps {
  companyId: string;
}

const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: lg;
`;

const Badge = styled.span`
  font-size: ${modularScale(-1)};
  background-color: green-400;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 5px;
`;

function FundingRoundCreator({
  companyId,
  onCreate,
}: {
  companyId: string;
  onCreate: () => void;
}) {
  const [isCreating, setIsCreating] = useState(false);
  const [currency, setCurrency] = useState(Currency.Gbp);
  const [amount, setAmount] = useState(0);
  const [month, setMonth] = useState(-1);
  const [year, setYear] = useState("");
  const [round, setRound] = useState<string | null>(null);

  const [mutation] = useMutation(CreateCompanyFundingRoundDocument);

  const allFilledIn = useMemo(
    () => amount > 0 && month > 0 && !Number.isNaN(parseInt(year)),
    [amount, month, year]
  );

  const handleCreate = useCallback(async () => {
    if (allFilledIn) {
      await mutation({
        variables: {
          id: companyId,
          input: {
            year: parseInt(year),
            month,
            round,
            funding: { amount, currency },
          },
        },
      });

      setCurrency(Currency.Gbp);
      setAmount(0);
      setMonth(-1);
      setYear("");
      setIsCreating(false);
      onCreate();
    }
  }, [
    allFilledIn,
    amount,
    companyId,
    currency,
    month,
    mutation,
    onCreate,
    year,
    round,
  ]);

  if (!isCreating) {
    return (
      <Button level="secondary" onClick={() => setIsCreating(true)}>
        Add
      </Button>
    );
  }

  return (
    <Card>
      <Spacing>
        <MoneyField
          value={{ amount, currency }}
          label="Amount"
          name="amount"
          onChange={money => {
            setAmount(money.amount ?? amount);
            setCurrency(money.currency ?? currency);
          }}
          options={AVAILABLE_CURRENCIES}
        />
        <DateContainer>
          <div>
            <Spacing size={-5}>
              <Label>Month</Label>
              <SelectField
                value={
                  MONTH_OPTIONS.find(({ value }) => value === month) ?? null
                }
                options={MONTH_OPTIONS}
                onChange={option => {
                  if (option) {
                    setMonth(option.value);
                  }
                }}
              />
            </Spacing>
          </div>

          <div>
            <InputField
              name="year"
              value={year}
              type="number"
              label="Year"
              onChange={e => {
                setYear(e.target.value);
              }}
            />
          </div>
        </DateContainer>
        <div>
          <Text size={-1} bold>
            Round type
          </Text>
          <SelectField
            value={ROUND_OPTIONS.find(({ value }) => value === round)}
            options={ROUND_OPTIONS}
            isClearable
            onChange={option => {
              if (option) {
                setRound(option.value);
              } else {
                setRound(null);
              }
            }}
          />
        </div>
        <Button level="primary" onClick={handleCreate}>
          Create
        </Button>
      </Spacing>
    </Card>
  );
}

export function Funding({
  companyId,
}: IFundingRoundsProps): React.ReactElement {
  const { data, loading, refetch } = useQuery(CompanyFundingRoundsDocument, {
    variables: { id: companyId },
  });

  const handleCreate = useCallback(() => {
    refetch();
  }, [refetch]);

  if (loading) {
    return <Loading />;
  }

  const { integratedWithDealroom, fundingRounds = [] } = data?.company ?? {};

  return (
    <Spacing size={-2}>
      <Text bold size={-1}>
        Funding rounds
        {integratedWithDealroom && <Badge>Dealroom</Badge>}
      </Text>
      {fundingRounds.map(({ id, month, year, funding, round }) => (
        <FundingRound
          key={id}
          id={id}
          funding={funding}
          month={month}
          year={year}
          round={round}
          disabled={integratedWithDealroom}
          refetchAll={refetch}
        />
      ))}
      {!integratedWithDealroom && (
        <FundingRoundCreator companyId={companyId} onCreate={handleCreate} />
      )}
      <MultiSelectCreateField
        label="Investors"
        optionsQuery={InvestorsDocument}
        fieldName="investors"
        parentName="company"
        disabled={integratedWithDealroom}
        createMutation={CreateInvestorDocument}
        parentId={companyId}
        valueQuery={CompanyInvestorsDocument}
        removeMutation={RemoveInvestorDocument}
        allowCreate
      />
    </Spacing>
  );
}
