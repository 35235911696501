import styled from "@xstyled/styled-components";
import { useCallback, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Header } from "./Header";
import { Columns, CompanyList } from "./CompanyList";
import { BulkMove } from "./BulkMove";

import { useQuery } from "@toolbox/apollo";
import {
  PotentialStarterCompaniesDocument,
  PotentialStarterCompaniesQuery,
} from "@toolbox/schema";
import { Loading } from "@otta/shared-components";
import { Tipbox } from "@otta/design";

type Companies = PotentialStarterCompaniesQuery["potentialCoreCompanies"];

const WhitePage = styled.div`
  background-color: white;
  padding-bottom: 4rem;
  position: absolute;
  overflow-y: auto;
  inset: 0 0 0 0;
  bottom: 0;
  right: 0;
`;

const Content = styled.div`
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  flex-direction: column;
  display: flex;
  margin: auto;
  gap: 1.5rem;
`;

/**
 * Handle applying the search filters, entered through the event handler
 * to the list of companies so we can narrow down what we show in the table
 */
function useSearchFilter(
  data?: Companies
): [Companies, React.ChangeEventHandler<HTMLInputElement>] {
  const [search, setSearch] = useState<string | null>(null);
  return [
    useMemo(
      () =>
        (data ?? []).filter(({ company, reason }) => {
          const toFind = search?.toLowerCase() ?? "";
          const nameMatches = company.name.toLowerCase().includes(toFind);
          const reasonMatches = reason.toLowerCase().includes(toFind);
          return !search || nameMatches || reasonMatches;
        }),
      [data, search]
    ),
    useCallback(
      ({ target }) => {
        const value = (target as HTMLInputElement).value.trim();
        setSearch(value !== "" ? value : null);
      },
      [setSearch]
    ),
  ];
}

/**
 * Handle applying the selection filters
 * With the setSelected callback that comes from the table
 */
function useSelectFilter(
  data?: Companies
): [Set<string>, (selected: Set<string>) => void] {
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  return [
    useMemo(
      () =>
        new Set(
          (data ?? [])
            .filter(({ company: { id } }) => selected.has(id))
            .map(c => c.company.id)
        ),
      [data, selected]
    ),
    useCallback(selected => setSelected(selected), [setSelected]),
  ];
}

/**
 * Exporting shown companies as CSV
 * Columns are matching these in the table
 */
function exportCompanies(data?: Companies): void {
  const headers = Columns.join(",");
  const rows = data
    ?.map(({ reason, hasFreeJobSlots, company }) =>
      [
        company.name,
        company.externalId,
        company.hubspotId,
        company.websiteUrl,
        reason,
        hasFreeJobSlots,
        company.wasPro,
      ].join(",")
    )
    .join("\n");

  const exportContent = [headers, rows].join("\n");

  const hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:text/plain;charset=utf-8," + encodeURIComponent(exportContent);
  hiddenElement.download = `export-companies-${new Date().toISOString()}.csv`;
  hiddenElement.click();
}

export function AddToStarterBulk() {
  const { data } = useQuery(PotentialStarterCompaniesDocument);
  const [companies, onSearch] = useSearchFilter(data?.potentialCoreCompanies);
  const [selected, onSelect] = useSelectFilter(data?.potentialCoreCompanies);
  const [bulkMoveOpen, setBulkMoveOpen] = useState(false);

  const chosenCompanies = useMemo(
    () => companies.filter(c => selected.has(c.company.id)),
    [selected, companies]
  );

  const navigate = useNavigate();

  if (!data) {
    return (
      <WhitePage>
        <Loading />
      </WhitePage>
    );
  }

  return (
    <WhitePage>
      <Content>
        <Header
          selectedCount={selected.size}
          shownCount={companies.length}
          totalCount={data.potentialCoreCompanies.length}
          onMove={() => setBulkMoveOpen(true)}
          onSearch={onSearch}
          onAdd={() => navigate("./add")}
          onExport={() => exportCompanies(companies)}
        />
        {companies.length === 0 ? (
          <Tipbox level="information">
            There aren't any companies matching your search
          </Tipbox>
        ) : (
          <>
            <CompanyList
              companies={companies}
              selectedIds={selected}
              onSelect={onSelect}
            />
            <BulkMove
              open={bulkMoveOpen}
              onClose={() => setBulkMoveOpen(false)}
              companies={chosenCompanies}
            />
          </>
        )}
      </Content>
      <Outlet />
    </WhitePage>
  );
}
