import { SubmittedSalaries } from "./SubmittedSalaries";

import { H2 } from "@toolbox/components/Headings";
import { PageWrapper } from "@toolbox/components/PageWrapper";

export default function Salaries(): React.ReactElement {
  return (
    <PageWrapper>
      <H2>Salaries</H2>
      <SubmittedSalaries />
    </PageWrapper>
  );
}
