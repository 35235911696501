import { useMutation } from "@apollo/client";
import { ReactNode } from "react";
import styled from "@xstyled/styled-components";

import { Button } from "@otta/design";
import {
  JobWorkflowStatus,
  JobsProcessedDocument,
  UpdateJobWorkflowStatusDocument,
} from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";

export const ButtonBar = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: gray-100;
`;

interface WorkflowButtonProps {
  jobId: string;
  status: JobWorkflowStatus;
  level: "primary" | "secondary";
  disable?: boolean;
  onComplete(): void;
  children: ReactNode;
}

export function WorkflowButton({
  jobId,
  status,
  level,
  onComplete,
  disable,
  children,
}: WorkflowButtonProps): React.ReactElement | null {
  const [updateMutation, { loading }] = useMutation(
    UpdateJobWorkflowStatusDocument,
    {
      onError: handleMutationError,
      refetchQueries: [JobsProcessedDocument],
    }
  );

  const handleClick = async () => {
    const result = await updateMutation({
      variables: {
        id: jobId,
        workflowStatus: status,
      },
    });

    if (result.data && !result.errors) {
      onComplete();
    }
  };

  return (
    <Button level={level} onClick={handleClick} disabled={loading || disable}>
      {children}
    </Button>
  );
}
