import styled from "@xstyled/styled-components";
import { useCallback, useState } from "react";

import {} from "@otta/design";
import { FieldWrapper as F } from "../FieldWrapper";

import { pxToRem } from "@otta/design-tokens";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { Delete } from "@toolbox/components/Icons/Delete";
import { HamburgerMenu } from "@toolbox/components/Icons/HamburgerMenu";

const FieldWrapper = styled(F)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: sm;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: ${pxToRem(-20)};
  transform: translateY(-50%);
`;

const DeleteWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  cursor: pointer;
`;

interface IReorderableBulletItemProps {
  id: string;
  value: string;
  order: number;
  handleUpdate: (item: {
    id: string;
    value: string;
    order: number;
  }) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
}

export function ReorderableBulletItem({
  id,
  value,
  order,
  handleUpdate,
  handleDelete,
}: IReorderableBulletItemProps): React.ReactElement {
  const [newValue, setNewValue] = useState(value);

  const handleBlur = useCallback(async () => {
    if (newValue !== value) {
      await handleUpdate({ id, value: newValue, order });
    }
  }, [handleUpdate, id, newValue, value, order]);

  return (
    <FieldWrapper>
      <IconWrapper>
        <HamburgerMenu />
      </IconWrapper>
      <TextAreaWrapper>
        <Textarea
          data-testid="individual-reorderable-bullet-item"
          margin={false}
          value={newValue}
          onBlur={handleBlur}
          onChange={e => setNewValue(e.target.value)}
          style={{ overflow: "hidden" }}
        />
      </TextAreaWrapper>

      <DeleteWrapper onClick={() => handleDelete(id)}>
        <Delete />
      </DeleteWrapper>
    </FieldWrapper>
  );
}
