import styled, { css } from "@xstyled/styled-components";
import { OttaIconsId, OTTA_ICONS_CODEPOINTS } from "./otta-icons";

import { modularScale } from "@otta/design-tokens";

export interface IconFontProps {
  icon: OttaIconsId;
  size?: number;
}

/**
 *  ```ts
 *
 * import { Icon } from '@otta/icons'
 *
 * ```
 *
 * This Icon Font is able to be used inline with any text. In order for it to be vertically centered, however, the parent of the `Icon` must have the value of `display: flex`. Additionally, if the icon needs specific sizing (larger/smaller than the text), you can use the `size` prop to do this.
 *
 * Here's an example of proper useage:
 *
 * ```
 *
 * const StyledText = styled(Text)`
 *   display: flex;
 * `
 * <StyledText>
 *   <Icon icon="checkmark-double" />
 *   You've already applied!
 * </StyledText>
 *
 * ```
 *
 * **Note**: Icon fonts do come with some limitations relating to design flexibility, animation, etc... If using this component doesn't allow the flexibility needed for a design, you can import the SVG version directly from the Design Library: `import { CheckmarkDouble } from @otta/design`.
 * The names are all the same, but are in camel case for the SVG icons.
 */

export const Icon = styled.i<IconFontProps>`
  display: inline-flex;
  align-items: center;
  font-family: otta-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${({ size }) =>
    (size || size === 0) &&
    css`
      font-size: ${modularScale(size)};
    `}
  &:before {
    content: "\\${({ icon }) =>
      parseInt(OTTA_ICONS_CODEPOINTS[icon], 10).toString(16)}";
  }
`;
