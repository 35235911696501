import { Text } from "../Typography";

import { palette } from "@otta/design-tokens";

type ErrorTextProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLSpanElement>
>;
export function ErrorText({
  children,
  ...props
}: ErrorTextProps): React.ReactElement {
  return (
    <Text as="span" bold color={palette.brand.red} {...props}>
      {children}
    </Text>
  );
}
