import { useQuery } from "@toolbox/apollo";
import { CurrentUserDocument, Permission } from "@toolbox/schema";

export enum CurrentRoleType {
  Admin = "CurrentAdmin",
  Candidate = "CurrentCandidate",
  CompanyRecruiter = "CurrentCompanyRecruiter",
  ExternalResearcher = "CurrentExternalResearcher",
  InternalOperator = "CurrentInternalOperator",
}

export const useUser = () => {
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });
  const user = data?.me;
  const role = user?.__typename as CurrentRoleType;
  const permissions: Permission[] =
    user?.__typename == CurrentRoleType.InternalOperator
      ? (user?.permissions as Permission[])
      : [];

  return { user, role, permissions, loading };
};
