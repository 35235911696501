import { Form, Formik } from "formik";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import {
  Button,
  Card,
  Heading,
  Input,
  Spacing,
  Tipbox,
  Text,
} from "@otta/design";
import {
  CompanyOfficeInput,
  CompanyOfficesDocument,
  CompanyOfficesQuery,
  CreateCompanyOfficeDocument,
  DeleteCompanyOfficeDocument,
  UpdateCompanyOfficeDocument,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";
import { Delete } from "@toolbox/components/Icons/Delete";

type Office = NonNullable<
  NonNullable<NonNullable<CompanyOfficesQuery["company"]>["offices"]>[0]
>;

function EditForm({ office }: { office: Office }): React.ReactElement {
  const [updateMutation, { error, loading, data }] = useMutation(
    UpdateCompanyOfficeDocument
  );

  const officeId = office.id;
  const [deleteMutation] = useMutation(DeleteCompanyOfficeDocument, {
    variables: { officeId },
  });

  const onSubmit = useCallback(
    (officeId: string, { address, parsedAddress }: CompanyOfficeInput) => {
      updateMutation({
        variables: { officeId, input: { address, parsedAddress } },
      });
    },
    [updateMutation]
  );

  const handleDelete = useCallback(() => {
    deleteMutation();
  }, [deleteMutation]);

  const DeleteWrapper = styled.div`
    width: 12px;
    position: absolute;
    top: ${pxToRem(15)};
    right: ${pxToRem(15)};
    cursor: pointer;
  `;

  return (
    <Card style={{ position: "relative" }}>
      {!office.isHq && (
        <DeleteWrapper onClick={handleDelete}>
          <Delete />
        </DeleteWrapper>
      )}

      <Spacing size={1}>
        {error && <Tipbox level="error">{error.message}</Tipbox>}
        {data && <Tipbox level="positive">Changes saved</Tipbox>}

        <Formik<CompanyOfficeInput>
          onSubmit={values => onSubmit(office.id, values)}
          initialValues={office}
        >
          {({ handleChange, values }) => (
            <Form>
              <Spacing size={1}>
                {values.isHq && (
                  <Text size={2} bold>
                    Headquarters
                  </Text>
                )}
                <label>
                  Address
                  <Input
                    name="address"
                    onChange={handleChange}
                    value={values.address ?? ""}
                  />
                </label>
                <label>
                  Parsed Address
                  <Input
                    name="parsedAddress"
                    onChange={handleChange}
                    value={values.parsedAddress ?? ""}
                  />
                </label>
                <Button type="submit" level="primary" disabled={loading}>
                  Save
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </Spacing>
    </Card>
  );
}

function AddForm({ companyId }: { companyId: string }): React.ReactElement {
  const [run, { error, loading }] = useMutation(CreateCompanyOfficeDocument);

  const onSubmit = useCallback(
    async (
      companyId: string,
      { address, parsedAddress }: CompanyOfficeInput
    ) => {
      await run({
        variables: { companyId, input: { address, parsedAddress } },
      });
    },
    [run]
  );

  return (
    <Card>
      <Spacing size={1}>
        {error && <Tipbox level="error">{error.message}</Tipbox>}
        <Formik<{ address: string; parsedAddress: string }>
          onSubmit={(values, { resetForm }) => {
            onSubmit(companyId, values);
            resetForm();
          }}
          initialValues={{ address: "", parsedAddress: "" }}
        >
          {({ handleChange, values }) => (
            <Form>
              <Spacing size={1}>
                <label>
                  Address
                  <Input
                    name="address"
                    onChange={handleChange}
                    value={values.address ?? ""}
                  />
                </label>
                <label>
                  Parsed Address
                  <Input
                    name="parsedAddress"
                    onChange={handleChange}
                    value={values.parsedAddress ?? ""}
                  />
                </label>
                <Button type="submit" level="primary" disabled={loading}>
                  Add
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </Spacing>
    </Card>
  );
}

export function Offices({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  const { data, loading } = useQuery(CompanyOfficesDocument, {
    variables: { id: companyId },
  });

  if (loading) {
    return <Loading />;
  }

  const sortedOffices = (
    data?.company?.offices ? [...data.company.offices] : []
  )
    .map(office => office as Office)
    .sort(
      (office1, office2) => (office2?.isHq ? 1 : 0) - (office1?.isHq ? 1 : 0)
    );

  return (
    <Spacing size={1}>
      <Heading size={3}>Offices</Heading>
      {sortedOffices.map(office => (
        <EditForm office={office} key={office.id} />
      ))}
      <AddForm companyId={companyId} />
    </Spacing>
  );
}
