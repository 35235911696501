import { useCallback, useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { ApolloQueryResult, FetchMoreQueryOptions } from "@apollo/client";

import { Loading } from "@otta/shared-components";

interface FetchMoreProps {
  fetchMore: (
    e: FetchMoreQueryOptions<{ offset: number }>
  ) => Promise<ApolloQueryResult<unknown>>;
  offset: number;
}

export function FetchMore({
  fetchMore,
  offset,
}: FetchMoreProps): React.ReactElement | null {
  const [loading, setLoading] = useState(false);

  const isStillMounted = useRef(true);

  useEffect(() => {
    isStillMounted.current = true;

    return () => {
      isStillMounted.current = false;
    };
  }, []);

  const handleInView = useCallback(
    async (inView: boolean) => {
      if (!inView || !offset) {
        return;
      }

      if (isStillMounted.current) {
        setLoading(true);
      }

      await fetchMore({
        variables: {
          offset,
        },
      });

      if (isStillMounted.current) {
        setLoading(false);
      }
    },
    [fetchMore, offset]
  );

  if (!offset) {
    return null;
  }

  return (
    <InView onChange={handleInView}>
      {({ ref }) => (
        <div ref={ref} style={{ minHeight: "10px", width: "100%" }}>
          {loading ? <Loading /> : null}
        </div>
      )}
    </InView>
  );
}
