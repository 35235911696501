import styled from "@xstyled/styled-components";

import { GoogleSignIn } from "./GoogleSignIn";
import { LoginForm } from "./LoginForm";

import { Middle, VerticalSpacing, Heading } from "@otta/design";

const Container = styled(Middle)`
  margin-top: 200px;
  padding: 0 lg;
`;

const devMode = import.meta.env.MODE !== "production";

export default function Login(): React.ReactElement {
  return (
    <Container maxWidth={500}>
      <VerticalSpacing>
        <Heading size={5}>Welcome to the Jungle Toolbox 🛠️</Heading>
        <GoogleSignIn />
        {devMode && <LoginForm />}
      </VerticalSpacing>
    </Container>
  );
}
