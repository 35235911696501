import styled from "@xstyled/styled-components";
import { useCallback } from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";

import {
  DeleteCompanyArticleDocument,
  UpdateCompanyArticleDocument,
} from "@toolbox/schema";
import { Card } from "@toolbox/components/Card";
import { FieldWrapper } from "@toolbox/components/Field/FieldWrapper";
import { LinkField } from "@toolbox/components/Field/LinkField";
import { TextareaField } from "@toolbox/components/Field/TextareaField";
import { Delete } from "@toolbox/components/Icons/Delete";

const DeleteWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

interface IArticleProps {
  id: string;
  title: string;
  url: string;
  refetchAll: () => Promise<ApolloQueryResult<unknown>>;
}

export function Article({
  id,
  title,
  url,
  refetchAll,
}: IArticleProps): React.ReactElement {
  const [deleteMutation] = useMutation(DeleteCompanyArticleDocument, {
    variables: { id },
  });

  const handleDelete = useCallback(async () => {
    await deleteMutation();
    await refetchAll();
  }, [deleteMutation, refetchAll]);

  return (
    <Card style={{ position: "relative" }}>
      <DeleteWrapper onClick={handleDelete}>
        <Delete />
      </DeleteWrapper>

      <FieldWrapper>
        <TextareaField
          label="Title"
          value={title}
          mutation={UpdateCompanyArticleDocument}
          fieldName="title"
          id={id}
        />
        <LinkField
          label="URL"
          value={url}
          mutation={UpdateCompanyArticleDocument}
          fieldName="url"
          id={id}
        />
      </FieldWrapper>
    </Card>
  );
}
