import styled from "@xstyled/styled-components";

import { RangeSlider } from "@otta/shared-components";
import { palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

// Has to be this odd size to fit nicely
const EntryLabel = styled.div`
  font-size: ${pxToRem(11)};
`;

type Year = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type Category = 0 | 1 | 2 | 3 | 4;

const categories: Record<Year, string> = {
  0: "Entry-level / Graduate",
  1: "Junior",
  2: "Junior",
  3: "Mid-level",
  4: "Mid-level",
  5: "Senior",
  6: "Senior",
  7: "Senior",
  8: "Senior",
  9: "Expert & Leadership",
  10: "Expert & Leadership",
};

const marks = {
  categoryForm: {
    0: <EntryLabel>Entry (0-1yrs)</EntryLabel>,
    1: <EntryLabel>Junior(1-2yrs)</EntryLabel>,
    2: <EntryLabel>Mid(3-4yrs)</EntryLabel>,
    3: <EntryLabel>Senior(5-8yrs)</EntryLabel>,
    4: <EntryLabel>Expert(9+yrs)</EntryLabel>,
  },
};

const ExperienceSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ExperienceLabel = styled(Text)<{ disabled: boolean }>`
  color: ${({ disabled }) =>
    disabled ? palette.brand.grey : palette.brand.black};
  margin-bottom: 10;
`;

const StyledRangeSlider = styled(RangeSlider)`
  margin-top: sm;
`;

const yearToCategory: Record<Year, Category> = {
  0: 0,
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 3,
  6: 3,
  7: 3,
  8: 3,
  9: 4,
  10: 4,
};
const yearsToCategories = ([lowerBound, upperBound]: [
  Year | null,
  Year | null
]): [Category, Category] | undefined => {
  if (lowerBound === null || upperBound === null) {
    return undefined;
  }

  const upperBoundCategory =
    upperBound > 10 ? yearToCategory[10] : yearToCategory[upperBound];
  return [yearToCategory[lowerBound], upperBoundCategory];
};

const lowerCategoryToYear: Record<Category, Year> = {
  0: 0,
  1: 1,
  2: 3,
  3: 5,
  4: 9,
};
const upperCategoryToYear: Record<Category, Year> = {
  0: 0,
  1: 2,
  2: 4,
  3: 8,
  4: 10,
};

const categoriesToYears = ([lowerBound, upperBound]: [Category, Category]): [
  Year,
  Year
] => [lowerCategoryToYear[lowerBound], upperCategoryToYear[upperBound]];

interface ExperienceSliderProps {
  value: [Year | null, Year | null];
  onChange: (newValue: [Year, Year]) => void;
  onAfterChange?: (newValue: [Year, Year]) => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

export function ExperienceSlider({
  value,
  onChange,
  onAfterChange,
  disabled = false,
  hideLabel = false,
}: ExperienceSliderProps): React.ReactElement {
  const lowerCategory = value[0] !== null ? categories[value[0]] : null;
  const upperCategory = value[1] !== null ? categories[value[1]] : null;
  const categoryLabel =
    lowerCategory === upperCategory
      ? lowerCategory
      : `${lowerCategory} to ${upperCategory}`;

  const showLabel =
    !hideLabel && !(lowerCategory === null || upperCategory === null);
  return (
    <ExperienceSliderContainer data-testid="experience-slider">
      {showLabel && (
        <ExperienceLabel size={-2} disabled={disabled}>
          {categoryLabel}
        </ExperienceLabel>
      )}
      <StyledRangeSlider
        allowCross={true}
        min={0}
        max={4}
        step={1}
        marks={marks.categoryForm}
        value={yearsToCategories(value)}
        disabled={disabled}
        onChange={values => {
          onChange(categoriesToYears(values as [Category, Category]));
        }}
        onAfterChange={values => {
          if (!onAfterChange) {
            return;
          }

          onAfterChange(categoriesToYears(values as [Category, Category]));
        }}
      />
    </ExperienceSliderContainer>
  );
}
