import "rc-slider/assets/index.css";
import Slider, { SliderProps } from "rc-slider";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const StyledSlider = styled(Slider)`
  background-color: transparent !important;
`;

type RangeSliderProps = Omit<
  SliderProps,
  | "handleStyle"
  | "activeDotStyle"
  | "labelStyle"
  | "trackStyle"
  | "railStyle"
  | "range"
>;

export function RangeSlider({
  disabled,
  ...props
}: RangeSliderProps): React.ReactElement {
  return (
    <StyledSlider
      {...props}
      range
      data-testid="rc-slider"
      disabled={disabled}
      handleStyle={[
        {
          borderColor: disabled ? palette.brand.grey : palette.brand.black,
          background: disabled ? palette.brand.grey : palette.brand.yellow,
          opacity: 1,
          boxShadow: "none",
        },
        {
          borderColor: disabled ? palette.brand.grey : palette.brand.black,
          background: disabled ? palette.brand.black : palette.brand.yellow,
          opacity: 1,
          boxShadow: "none",
        },
      ]}
      dotStyle={{
        backgroundColor: palette.grayscale.shade200,
        borderColor: palette.grayscale.shade200,
      }}
      activeDotStyle={{
        backgroundColor: disabled ? palette.brand.grey : palette.brand.black,
        borderColor: disabled ? palette.brand.grey : palette.brand.black,
      }}
      trackStyle={[
        {
          backgroundColor: disabled ? palette.brand.grey : palette.brand.black,
        },
      ]}
      railStyle={{
        backgroundColor: palette.grayscale.shade200,
      }}
    />
  );
}
