import { useMutation } from "@apollo/client";
import { formatDistanceStrict, parseJSON } from "date-fns";
import { useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { ActivateButton } from "./ActivateButton";
import { CompanySelect } from "./CompanySelect";
import { PermissionsModal } from "./PermissionsModal";
import { EnrolIntoJobSlotsButton } from "./EnrolIntoJobSlotsButton";
import { DeleteUserModal } from "./DeleteUserModal";

import { Button, Card, Modal, SelectField, Spacing, Text } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import {
  CompanyWorkflowStatus,
  Permission,
  RoleType,
  UpdateUserCurrentCompanyDocument,
  UpdateUserRoleDocument,
} from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";
import { AdminsOnly } from "@toolbox/components/AdminsOnly";
import { isWelcomeEmail } from "@otta/shared-components";

const ROLE_OPTIONS = [
  {
    value: RoleType.Candidate,
    label: "Candidate",
  },
  {
    value: RoleType.CompanyRecruiter,
    label: "Recruiter",
  },
  {
    value: RoleType.InternalOperator,
    label: "Internal Operator",
  },
  {
    value: RoleType.Admin,
    label: "Admin",
  },
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr auto;
  gap: lg;
  align-items: center;
`;

const Selects = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6;
`;

const Buttons = styled.div`
  display: flex;
  gap: 6;
  align-items: center;
`;

interface CompanySwitcherProps {
  company?: {
    id: string;
    name: string;
  } | null;
  onChange(id: string): Promise<void>;
}

function CompanySwitcher({ company, onChange }: CompanySwitcherProps) {
  const [editing, setEditing] = useState(!company);

  if (editing) {
    return (
      <CompanySelect
        value={company?.id ?? null}
        statuses={[
          CompanyWorkflowStatus.Completed,
          CompanyWorkflowStatus.Changed,
        ]}
        onChange={async e => {
          if (e) {
            await onChange(e);
            setEditing(false);
          }
        }}
      />
    );
  }

  return (
    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <Text onClick={() => setEditing(false)}>
        {company?.name ?? "No company"}
      </Text>
      <Button level="secondary" size="small" onClick={() => setEditing(true)}>
        Edit
      </Button>
    </div>
  );
}

interface UserProps {
  id: string;
  firstName: string;
  lastName: string;
  role: RoleType;
  email: string;
  verifiedEmail?: boolean;
  activated?: boolean;
  insertedAt: string;
  currentCompany?: {
    id: string;
    name: string;
    workflowStatus: CompanyWorkflowStatus;
  } | null;
  permissions: { id: string; name: Permission }[];
}

export function User({
  id,
  firstName,
  lastName,
  role,
  email,
  verifiedEmail,
  activated,
  insertedAt,
  currentCompany,
  permissions,
}: UserProps) {
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [showDeleteUserModal, setDeleteUserModal] = useState(false);
  const [updateRoleMutation, { loading: updateRoleLoading }] = useMutation(
    UpdateUserRoleDocument,
    { onError: handleMutationError }
  );

  const [updateUsersCurrentCompany] = useMutation(
    UpdateUserCurrentCompanyDocument,
    {
      onError: handleMutationError,
    }
  );

  const durationAgoJoined = useMemo(
    () =>
      formatDistanceStrict(parseJSON(insertedAt), new Date(), {
        addSuffix: true,
      }),
    [insertedAt]
  );

  return (
    <>
      <Card style={{ flex: 1, padding: modularScale(-2) }}>
        <Container>
          <div>
            <Spacing size={-4}>
              <Text>
                <Text as="span" size={1} bold>
                  {firstName} {lastName}
                </Text>{" "}
                <Text as="span" size={-1}>
                  | joined
                </Text>{" "}
                <Text as="span" size={-1} bold>
                  {durationAgoJoined}
                </Text>
              </Text>

              <Text>
                {email}{" "}
                {verifiedEmail && (
                  <Text as="span" color={palette.grayscale.shade600}>
                    (Verified)
                  </Text>
                )}
              </Text>
            </Spacing>
          </div>
          <Selects>
            <div style={{ minWidth: 200 }} data-testid="select-role">
              <SelectField
                options={ROLE_OPTIONS}
                isLoading={updateRoleLoading}
                isDisabled={role === RoleType.Admin}
                value={ROLE_OPTIONS.find(o => o.value === role) ?? null}
                onChange={e => {
                  if (!e) {
                    return;
                  }

                  const isVerifiedOttaEmail =
                    isWelcomeEmail(email) && verifiedEmail;

                  if (
                    (e.value === RoleType.Admin ||
                      e.value === RoleType.InternalOperator) &&
                    !isVerifiedOttaEmail
                  ) {
                    alert(`Email must be verfied to change user to ${e.value}`);
                    return;
                  }
                  if (
                    e.value === RoleType.Admin &&
                    !confirm(`Are you sure you want to make ${email} an admin`)
                  ) {
                    return;
                  }

                  if (
                    e.value === RoleType.InternalOperator &&
                    !confirm(
                      `Are you sure you want to make ${email} an internal operator`
                    )
                  ) {
                    return;
                  }

                  updateRoleMutation({
                    variables: { userId: id, role: e.value },
                  });
                }}
              />
            </div>
            {role === RoleType.InternalOperator && (
              <Button
                level="secondary"
                size="small"
                onClick={() => setShowPermissionsModal(true)}
              >
                Permissions
              </Button>
            )}
            {role === RoleType.CompanyRecruiter && (
              <CompanySwitcher
                company={currentCompany}
                onChange={async companyId => {
                  await updateUsersCurrentCompany({
                    variables: {
                      userId: id,
                      companyId,
                    },
                  });
                }}
              />
            )}
          </Selects>

          <Buttons>
            {!activated &&
              currentCompany?.workflowStatus !==
                CompanyWorkflowStatus.Changed &&
              currentCompany?.workflowStatus !==
                CompanyWorkflowStatus.Completed &&
              role === RoleType.CompanyRecruiter && (
                <AdminsOnly>
                  <EnrolIntoJobSlotsButton
                    verified={!!verifiedEmail}
                    userId={id}
                  />
                </AdminsOnly>
              )}
            {role === RoleType.CompanyRecruiter && (
              <ActivateButton
                activated={!!activated}
                verified={!!verifiedEmail}
                userId={id}
              />
            )}
            <Button
              level="secondary"
              size="small"
              onClick={() => setDeleteUserModal(true)}
              name="Delete user"
            >
              Delete user
            </Button>
          </Buttons>
        </Container>
      </Card>

      <Modal open={showPermissionsModal} onOpenChange={setShowPermissionsModal}>
        <PermissionsModal id={id} email={email} permissions={permissions} />
      </Modal>
      <Modal open={showDeleteUserModal} onOpenChange={setDeleteUserModal}>
        <DeleteUserModal role={role} id={id} email={email} />
      </Modal>
    </>
  );
}
