import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createLink } from "apollo-absinthe-upload-link";
import { print } from "graphql";
import * as Sentry from "@sentry/browser";
import { parse as parseCookie } from "cookie";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

import { typePolicies } from "./apollo/typePolicies";

const authLink = setContext((_, { headers }) => {
  const cookies = parseCookie(document.cookie);

  return {
    headers: {
      ...headers,
      "X-CSRF-Token": cookies[import.meta.env.VITE_CSRF_COOKIE],
      "X-XSRF-Token": cookies[import.meta.env.VITE_CSRF_TOKEN_COOKIE],
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  Sentry.withScope(scope => {
    scope.addBreadcrumb({
      type: "http",
      category: "graphql",
      level: "info",
      data: {
        name: operation.operationName,
        query: operation.query.loc?.source?.body ?? print(operation.query),
      },
    });

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        Sentry.captureMessage(error.message, "error");
      });
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }
  });
});

const httpLink = createLink({
  uri: `${import.meta.env.VITE_API_HOST}/graphql`,
  credentials: "include",
});

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject;
  }
}

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({ typePolicies }).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100,
});
