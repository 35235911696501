import * as Sentry from "@sentry/browser";
import { hydrateRoot } from "react-dom/client";
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { Providers } from "./providers";
import { apolloClient } from "./providers/apollo";
import { StatusProvider } from "./providers/status";
import { App } from "./App";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://0d03083d8a0b4169d7bbf5cb7bc07f69@o140309.ingest.sentry.io/4506631364935680",
    environment: import.meta.env.MODE,
  });
}

hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <StrictMode>
    <StatusProvider>
      <ApolloProvider client={apolloClient}>
        <Providers>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Providers>
      </ApolloProvider>
    </StatusProvider>
  </StrictMode>
);
