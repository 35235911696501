// eslint-disable-next-line no-restricted-imports
import { Navigate, useResolvedPath } from "react-router-dom";

import { useStatus } from "./providers/status";

export function Redirect({
  to,
  status,
  replace = true,
}: {
  to: string;
  status?: number;
  replace?: boolean;
}) {
  const location = useResolvedPath(to);

  const { ssr, redirect } = useStatus();

  if (ssr) {
    return redirect(location.pathname, status);
  }

  return <Navigate to={location} replace={replace} />;
}
