import { ThemeProvider } from "@xstyled/styled-components";

import { IAPRefresher } from "./iap";

import { theme as toolboxTheme } from "@toolbox/theme";
import { theme as globalTheme } from "@otta/design";

const theme = {
  ...toolboxTheme,
  ...globalTheme,
};

export function Providers({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <IAPRefresher>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </IAPRefresher>
  );
}
