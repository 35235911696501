import { DocumentNode } from "graphql";
import Select, { ActionMeta } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useCallback } from "react";
import { TypedDocumentNode, useMutation } from "@apollo/client";

import { FieldLabel } from "./FieldLabel";

import { Spacing } from "@otta/design";
import { handleMutationError } from "@toolbox/utils/error";
import { useQuery } from "@toolbox/apollo";

interface ISelectFieldProps<
  P extends string,
  O extends string,
  F extends string
> {
  label: string;
  autoSet?: boolean;
  optionsName: O;
  fieldName: F;
  parentName: P;
  parentId: string;
  createMutation: TypedDocumentNode<unknown, { id: string; value: string }>;
  valueQuery: TypedDocumentNode<
    {
      [key in P]:
        | { [key in F]: { id: string; value: string } | null | undefined }
        | undefined
        | null;
    },
    // This is a very hacky fix - need to revisit later
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
  optionsQuery: TypedDocumentNode<
    { [key in O]: { id: string; value: string }[] },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
  refetchQueries?: () => Array<{
    query: DocumentNode;
    variables?: Record<string, string>;
  }>;
  allowCreate: boolean;
}

export function mapQueryResultToCreatableStructure({
  id,
  value,
}: {
  id: string;
  value: string;
}): { label: string; value: string } {
  return {
    label: value,
    value: id,
  };
}

export function SelectField<
  P extends string,
  O extends string,
  F extends string
>({
  label,
  autoSet,
  optionsQuery,
  optionsName,
  parentName,
  fieldName,
  createMutation,
  parentId,
  valueQuery,
  allowCreate,
  refetchQueries,
}: ISelectFieldProps<P, O, F>): React.ReactElement {
  const {
    data: allOptions,
    loading: allLoading,
    refetch: refetchAll,
  } = useQuery(optionsQuery, { variables: { id: parentId } });

  const {
    data: currentValue,
    loading: currentLoading,
    refetch: refetchValues,
  } = useQuery(valueQuery, { variables: { id: parentId } });

  const [create] = useMutation(createMutation, {
    refetchQueries,
    onError: handleMutationError,
  });

  const handleChange = useCallback(
    async (
      newValue: { label: string; value: string } | null,
      actionMeta: ActionMeta<{ label: string }>
    ) => {
      if (
        newValue &&
        (actionMeta.action === "create-option" ||
          actionMeta.action === "select-option")
      ) {
        await create({ variables: { id: parentId, value: newValue.label } });
      }

      // refetch the full list and the parent-specific items
      await refetchAll();
      await refetchValues();
    },
    [create, parentId, refetchAll, refetchValues]
  );

  const options = (allOptions?.[optionsName] ?? []).map(
    mapQueryResultToCreatableStructure
  );

  const values = currentValue?.[parentName]?.[fieldName];

  const value = values ? mapQueryResultToCreatableStructure(values) : null;

  return (
    <Spacing size={-6}>
      <FieldLabel text={label} autoSet={autoSet && !!value}>
        {allowCreate ? (
          <CreatableSelect
            isClearable
            isLoading={allLoading || currentLoading}
            onChange={handleChange}
            options={options}
            value={value}
            inputId={`${parentName}-${fieldName}`}
          />
        ) : (
          <Select
            isClearable
            isLoading={allLoading || currentLoading}
            onChange={handleChange}
            options={options}
            value={value}
            inputId={`${parentName}-${fieldName}`}
          />
        )}
      </FieldLabel>
    </Spacing>
  );
}
