const customMediaQuery = (minWidth: number): string =>
  `@media (min-width: ${minWidth}px)`;

export const media = {
  custom: customMediaQuery,
  desktop: customMediaQuery(1024),
  tablet: customMediaQuery(768),
  phone: customMediaQuery(280),
};

// adapted from https://stackoverflow.com/a/4819886
export function isTouchDevice(): boolean {
  if (!window || typeof window.matchMedia !== "function") {
    return false;
  }

  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = (q: string) => window.matchMedia(q).matches;

  if ("ontouchstart" in window) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return mq(query);
}
