import styled from "@xstyled/styled-components";
import { useCallback, useState } from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";

import { InputField, SelectField, Spacing, Card, Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { CURRENCY_OPTIONS } from "@toolbox/utils/currency";
import { commasToNumber, numberToCommas } from "@toolbox/utils/money";
import {
  DeleteCompanyFundingRoundDocument,
  UpdateCompanyFundingRoundDocument,
} from "@toolbox/schema";
import { Delete } from "@toolbox/components/Icons/Delete";

const DeleteWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: ${modularScale(-5)};
  right: ${modularScale(-5)};
  cursor: pointer;
`;

const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: lg;
`;

const AmountContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: lg;
`;

export const MONTH_OPTIONS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((label, i) => ({ label, value: i + 1 }));

export const ROUND_OPTIONS: { label: string; value: string | null }[] = [
  "Angel",
  "Convertible",
  "Early VC",
  "Grant",
  "Growth equity VC",
  "Late VC",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
  "Series G",
  "Series H",
  "Series I",
].map(label => ({ label, value: label }));

const currencyOptions = CURRENCY_OPTIONS.map(label => ({
  label,
  value: label,
}));

interface FundingAmount {
  amount: number;
  currency: string;
}

interface IFundingRoundProps {
  id: string;
  month: number;
  year: number;
  round?: string | null;
  funding: FundingAmount;
  disabled?: boolean;
  refetchAll: () => Promise<ApolloQueryResult<unknown>>;
}

export function FundingRound({
  id,
  refetchAll,
  disabled,
  ...props
}: IFundingRoundProps): React.ReactElement {
  const [month, setMonth] = useState(props.month);
  const [year, setYear] = useState(props.year.toString());
  const [round, setRound] = useState(props.round);
  const [funding, setFunding] = useState<{
    amount: number;
    currency: string;
  }>({
    amount: props.funding.amount,
    currency: props.funding.currency,
  });

  const [deleteMutation] = useMutation(DeleteCompanyFundingRoundDocument, {
    variables: { id },
  });
  const [updateMutation] = useMutation(UpdateCompanyFundingRoundDocument);

  const handleDelete = useCallback(async () => {
    await deleteMutation();
    await refetchAll();
  }, [deleteMutation, refetchAll]);

  const handleUpdate = useCallback(
    (values?: {
      funding?: FundingAmount;
      month?: number;
      round?: string | null;
    }) => {
      const actualFunding = values?.funding ?? funding;
      const yearAsNumber = parseInt(year);

      const actualMonth = values?.month ?? month;
      const actualRound =
        values?.round === null ? values.round : values?.round ?? round;

      if (
        actualMonth &&
        !Number.isNaN(yearAsNumber) &&
        actualFunding.amount &&
        actualFunding.currency
      ) {
        updateMutation({
          variables: {
            id,
            input: {
              month: actualMonth,
              year: yearAsNumber,
              round: actualRound,
              funding: {
                amount: actualFunding.amount,
                currency: actualFunding.currency,
              },
            },
          },
        });
      }
    },
    [funding, id, month, updateMutation, year, round]
  );

  return (
    <Card style={{ position: "relative" }} data-testid="funding-round">
      {!disabled && (
        <DeleteWrapper onClick={handleDelete}>
          <Delete />
        </DeleteWrapper>
      )}

      <Spacing size={-5}>
        <div>
          <Text size={-1} bold>
            Amount
          </Text>
          <AmountContainer>
            <SelectField
              isDisabled={disabled}
              value={currencyOptions.find(o => o.value === funding.currency)}
              options={currencyOptions}
              aria-label="currency"
              onChange={option => {
                if (option) {
                  setFunding({
                    ...funding,
                    currency: option.value,
                  });
                  handleUpdate({
                    funding: { amount: funding.amount, currency: option.value },
                  });
                }
              }}
            />
            <InputField
              disabled={disabled}
              name="funding-amount-field"
              type="text"
              value={numberToCommas(funding.amount)}
              onChange={e => {
                setFunding({
                  ...funding,
                  amount: commasToNumber(e.target.value),
                });
              }}
              onBlur={() => handleUpdate({ funding: funding })}
            />
          </AmountContainer>
        </div>
        <DateContainer>
          <div>
            <Text as="label" htmlFor="month-select" size={-1} bold>
              Month
            </Text>
            <SelectField
              inputId="month-select"
              isDisabled={disabled}
              value={MONTH_OPTIONS.find(({ value }) => value === month)}
              options={MONTH_OPTIONS}
              onChange={option => {
                if (option) {
                  setMonth(option.value);
                  handleUpdate({ month: option.value });
                }
              }}
            />
          </div>

          <div>
            <Text size={-1} bold>
              Year
            </Text>
            <InputField
              disabled={disabled}
              name="year"
              value={year}
              type="number"
              onChange={e => {
                setYear(e.target.value);
              }}
              onBlur={() => handleUpdate()}
            />
          </div>
        </DateContainer>
        <div>
          <Text size={-1} bold>
            Round type
          </Text>
          <SelectField
            isDisabled={disabled}
            value={ROUND_OPTIONS.find(({ value }) => value === props.round)}
            options={ROUND_OPTIONS}
            placeholder="Select round..."
            isClearable
            onChange={option => {
              if (option) {
                setRound(option.value);
                handleUpdate({ round: option.value });
              } else {
                setRound(null);
                handleUpdate({ round: null });
              }
            }}
          />
        </div>
      </Spacing>
    </Card>
  );
}
