import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { Button } from "@otta/design";
import { handleMutationError } from "@toolbox/utils/error";
import { ActivateUserDocument } from "@toolbox/schema";

interface IActivateButtonProps {
  userId: string;
  activated: boolean;
  verified: boolean;
}

export function ActivateButton({
  userId,
  activated,
  verified,
}: IActivateButtonProps): React.ReactElement {
  const [mutation] = useMutation(ActivateUserDocument, {
    variables: { userId, activated: !activated },
    onError: handleMutationError,
  });

  const handleClick = useCallback(() => {
    if (window.confirm("Are you sure you want to activate this user?")) {
      mutation();
    }
  }, [mutation]);

  return (
    <Button
      data-testid="activate-button"
      level={activated ? "destructive" : "primary"}
      onClick={handleClick}
      size="small"
      disabled={!verified}
    >
      {activated ? "Deactivate" : "Activate"}
    </Button>
  );
}
