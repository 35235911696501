import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { FieldContainer } from "./FieldContainer";
import { ErrorText } from "./ErrorText";
import { Wrapper, RangeField as RangeWrapper } from "./style";
import { Label } from "./Label";

export interface IMoneyRangeProps {
  values: { minAmount: number | null; maxAmount: number | null };
  prefix: string;
  name: string;
  onChange?: (moneyRange: {
    minAmount: number | null;
    maxAmount: number | null;
  }) => void;
  onBlur?: () => void;
  placeholders?: { minAmount: string; maxAmount: string };
  label?: string;
  disabled?: boolean;
  error?: string;
}
/**
 * This component is used for salary range inputs in our Applications. An optional label can be added to this input by providing a `label` prop.
 *
 * ```ts
 *
 * import { MoneyRange } from '@otta/design'
 *
 * ```
 */
const MoneyRangeWrapper = styled(Wrapper)`
  grid-template-columns: 1fr;
`;
export function MoneyRange({
  values,
  name,
  prefix,
  onChange,
  onBlur,
  placeholders,
  label,
  disabled,
  error,
}: IMoneyRangeProps): React.ReactElement {
  const handleValueChange = useCallback(
    (v: { minAmount: number | null; maxAmount: number | null }) => {
      onChange?.({ ...values, ...v });
    },
    [onChange, values]
  );

  return (
    <FieldContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      <MoneyRangeWrapper>
        <RangeWrapper
          values={values}
          prefix={prefix}
          onChange={handleValueChange}
          onBlur={onBlur}
          placeholders={placeholders}
          disabled={disabled}
        />
      </MoneyRangeWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </FieldContainer>
  );
}
