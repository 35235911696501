import type { DocumentNode } from "graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";

import { FieldLabel } from "./FieldLabel";

import { Spacing } from "@otta/design";
import { handleMutationError } from "@toolbox/utils/error";

interface RenderProps<Element extends HTMLInputElement | HTMLTextAreaElement> {
  onChange: React.ChangeEventHandler<Element>;
  value: string;
  type?: string;
  onBlur: () => void;
}

export interface IFieldProps<
  Element extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
> {
  label: string;
  value?: string | number | null;
  mutation: DocumentNode;
  placeholder?: string;
  fieldName: string;
  id: string;
  type?: string;
  transform?: (x: string | null) => any;
  children: (props: RenderProps<Element>) => React.ReactElement;
  autoSet?: boolean;
  validate?: (value: string | number | null) => boolean;
  disabled?: boolean;
}

export function Field<Element extends HTMLInputElement | HTMLTextAreaElement>({
  id,
  children,
  transform = x => x,
  fieldName,
  value: initialValue,
  mutation,
  type,
  label,
  autoSet,
  validate,
  ...rest
}: IFieldProps<Element>): React.ReactElement {
  const normalizedValue = useMemo(
    () => (initialValue != null ? initialValue.toString() : ""),
    [initialValue]
  );
  const [value, setValue] = useState(normalizedValue);
  const valid = useMemo(
    () => (validate ? validate(value) : true),
    [validate, value]
  );
  useEffect(() => {
    setValue(normalizedValue);
  }, [normalizedValue]);

  const [runMutation] = useMutation(mutation, {
    onError: handleMutationError,
  });

  const handleChange = useCallback<React.ChangeEventHandler<Element>>(e => {
    setValue(e.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    if (value !== normalizedValue && valid) {
      runMutation({
        variables: {
          id,
          input: {
            [fieldName]: transform(value === "" ? null : value),
          },
        },
      });
    }
  }, [fieldName, id, normalizedValue, runMutation, transform, valid, value]);

  return (
    <Spacing size={-6}>
      <FieldLabel text={label} autoSet={autoSet}>
        {children({
          ...rest,
          type,
          onChange: handleChange,
          value: value,
          onBlur: handleBlur,
        })}
      </FieldLabel>
    </Spacing>
  );
}
