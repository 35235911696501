import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import { Loading } from "@otta/shared-components";
import { handleMutationError } from "@toolbox/utils/error";
import { Redirect } from "@toolbox/router";
import { useQuery } from "@toolbox/apollo";
import {
  CreateJobDocument,
  CompanyScraperTypeDocument,
  CompanyScraperType,
} from "@toolbox/schema";
import { Input } from "@toolbox/components/Input";
import { StyledButton } from "@toolbox/components/Button";

interface ICreateJobProps {
  companyId: string;
}

export function CreateJob({ companyId }: ICreateJobProps): React.ReactElement {
  const [title, setTitle] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");

  const { data: companyData, loading } = useQuery(CompanyScraperTypeDocument, {
    variables: { id: companyId },
  });

  const [create, { data }] = useMutation(CreateJobDocument, {
    onError: handleMutationError,
  });

  const handleCreate = useCallback(() => {
    create({
      variables: {
        companyId,
        input: {
          editedTitle: title,
          originalUrl: originalUrl ? originalUrl : null,
        },
      },
    });
  }, [companyId, create, originalUrl, title]);

  if (loading) {
    return <Loading />;
  }

  if (data?.createJob?.id) {
    return <Redirect to="/jobs/pre-approval" />;
  }

  return (
    <div>
      <Input
        value={title}
        placeholder="New job title"
        onChange={e => setTitle(e.target.value)}
      />
      {companyData?.company?.scraperType === CompanyScraperType.Manual && (
        <Input
          value={originalUrl}
          placeholder="Original URL"
          onChange={e => setOriginalUrl(e.target.value)}
        />
      )}
      <StyledButton level="primary" onClick={handleCreate} disabled={!title}>
        Create new job
      </StyledButton>
    </div>
  );
}
